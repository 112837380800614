export default {};

export const eclipseTimeFields = [
  {
    label: 'Date',
    value: 'timecard_date',
    required: true,
    type: 'clock',
  },
  {
    label: 'Regular Hours',
    value: 'rt_hours',
    type: 'hours',
  },
  {
    label: 'Overtime Hours',
    value: 'th_hours',
    type: 'hours',
  },
  {
    label: 'Double Time Hours',
    value: 'dt_hours',
    type: 'hours',
  },
  {
    label: 'Union Code',
    value: 'union_code',
    type: 'union',
  },
  {
    label: 'Work Classifcation',
    value: 'work_class',
    type: 'union',
  },
  {
    label: 'Travel Time',
    value: 'tt_hours',
    type: 'text',
  },
  {
    label: 'Notes',
    value: 'notes',
    type: 'text',
  },
  {
    label: 'Foreman Employee Code',
    value: 'foreman',
    type: 'dropdown - Users',
  },
  {
    label: 'Equipment',
    value: 'equipment',
    type: 'table - Equipment',
  },
  {
    label: 'Material Usage',
    value: 'materialUsage',
    type: 'table - Materials',
  },
  {
    label: 'Material Usage Transaction Type',
    value: 'materialUsageTransactionType',
    type: 'input',
  },
  {
    label: 'Material Usage Document Type',
    value: 'materialUsageDocumentType',
    type: 'input',
  },
  {
    label: 'Material Usage Distribution Type',
    value: 'materialUsageDistributionType',
    type: 'input',
  },
  {
    label: 'Material Transfer',
    value: 'materialTransfer',
    type: 'table - Materials',
  },
  {
    label: 'Material Transfer Transaction Type',
    value: 'materialTransferTransactionType',
    type: 'input',
  },
  {
    label: 'Material Transfer Document Type',
    value: 'materialTransferDocumentType',
    type: 'input',
  },
  {
    label: 'Material Transfer Distribution Type',
    value: 'materialTransferDistributionType',
    type: 'input',
  },
  {
    label: 'Pay Code',
    value: 'pay_code',
    type: 'dropdown - Custom',
  },
  {
    label: 'Work Order Number',
    value: 'wo_number',
    type: 'text',
  },
];
export const ontraccrTimeFields = {
  hours: [
    {
      value: 'regularHours',
      label: 'Regular Hours',
    },
    {
      value: 'overtimeHours',
      label: 'Overtime Hours',
    },
    {
      value: 'doubleOvertimeHours',
      label: 'Double Overtime Hours',
    },
  ],
  clock: [{
    value: 'startTime',
    label: 'Start Date',
  }, {
    value: 'endTime',
    label: 'End Date',
  }],
  text: [{
    value: 'note',
    label: 'Notes',
  }],
  union: [{
    label: 'Union',
    value: 'union',
  }, {
    label: 'Union Local',
    value: 'unionLocal',
  }, {
    label: 'Work Classification',
    value: 'unionClass',
  }],
};

export const getEclipseWorkflowSyncFields = (t) => ([
  {
    text: 'Date',
    key: 'timecard_date',
    types: ['dateTime', 'table - TimeEntry'],
  },
  {
    text: t('Project'),
    key: 'job',
    types: ['dropdown - Projects', 'table - TimeEntry'],
  },
  {
    text: 'Cost Code',
    key: 'cost_code',
    types: ['dropdown - Costcodes', 'table - TimeEntry'],
  },
  {
    text: 'Equipment',
    key: 'equipment',
    types: ['table - Equipment'],
  },
  {
    text: 'Work Order Number',
    key: 'wo_number',
    types: ['text'],
  },
]);
