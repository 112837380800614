import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
} from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getIdMap } from 'ontraccr-common/lib/Common';
import { getContactAddressBooks } from 'ontraccr-common/lib/Contacts';

import CustomModal from '../../../common/modals/CustomModal';
import HoverHelp from '../../../common/HoverHelp';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import colors from '../../../constants/Colors';
import FormSectionPermissions from './FormSectionPermissions';
import ConditionalRenderingRow from '../FormFields/ConditionalRenderingRow';

export default function FormSectionSettingsModal({
  id,
  visible,
  onSettingsUpdated,
  onCloseClicked,
  initialValues = {},
  divisions,
  isBoardCards,
  isExternalForm,
  enablePermissions,
  sections = [],
  isTimeCard,
}) {
  const {
    hasConditionalRendering: initialHasConditionalRendering = false,
    conditionalRenderingFormula: initialConditionalRenderingFormula,
  } = initialValues || {};

  const projects = useSelector((state) => state.projects.projects);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const phases = useSelector((state) => state.costcodes.phases);
  const customers = useSelector((state) => state.customers.customers);
  const users = useSelector((state) => state.users.users);
  const vendors = useSelector((state) => state.vendors.vendors);
  const equipment = useSelector((state) => state.equipment.equipment);
  const formTemplates = useSelector((state) => state.forms.templates);
  const labels = useSelector((state) => state.labels);
  const globalAddressBooks = useSelector((state) => state.contacts.globalAddressBooks);
  const buckets = useSelector((state) => state.buckets.buckets);

  const [
    hasConditionalRendering,
    setHasConditionalRendering,
  ] = useState(initialHasConditionalRendering);
  const [
    conditionalRenderingFormula,
    setConditionalRenderingFormula,
  ] = useState(initialConditionalRenderingFormula);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const contactAddressBooks = useMemo(() => (
    getContactAddressBooks(globalAddressBooks)
  ), [globalAddressBooks]);

  const [form] = Form.useForm();

  const onSubmit = useCallback(() => {
    const values = form.getFieldsValue(true);
    onSettingsUpdated({
      ...values,
      hasConditionalRendering,
      conditionalRenderingFormula,
    });
    onCloseClicked();
  }, [form, onSettingsUpdated, hasConditionalRendering, conditionalRenderingFormula]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setHasConditionalRendering(false);
      setConditionalRenderingFormula();
    }
  }, [visible, form]);

  useEffect(() => {
    if (visible && initialValues) {
      setHasConditionalRendering(initialHasConditionalRendering);
      setConditionalRenderingFormula(initialConditionalRenderingFormula);
    }
  }, [visible, initialValues]);

  return (
    <CustomModal
      title="Section Settings"
      visible={visible}
      onCloseClicked={onCloseClicked}
      maskColor={colors.MODAL_MASK_DARK}
      width={1150}
    >
      <Form
        form={form}
        initialValues={initialValues}
        style={{ paddingLeft: 25, paddingRight: 25 }}
      >
        <Form.Item
          name="defaultCollapsed"
          label={(
            <Row gutter={20}>
              <Col>
                Default Collapsed
              </Col>
              <Col>
                <HoverHelp
                  content={(
                    <div style={{ width: 300 }}>
                      If you check this box,
                      the section will be collapsed by default when
                      a user opens the form
                    </div>
                  )}
                />
              </Col>
            </Row>
          )}
          valuePropName="checked"
        >
          <Checkbox style={{ float: 'left' }} />
        </Form.Item>
        { !isBoardCards && (
          <Form.Item
            name="isDuplicationEnabled"
            label={(
              <Row gutter={20}>
                <Col>
                  Enable Section Duplication
                </Col>
                <Col>
                  <HoverHelp
                    content={(
                      <div style={{ width: 300 }}>
                        If you check this box,
                        the section can be duplicated by another user when filling out the form.
                        Note that calculation fields are not supported and
                        only standard templates can be used.
                      </div>
                    )}
                  />
                </Col>
              </Row>
            )}
            valuePropName="checked"
          >
            <Checkbox style={{ float: 'left' }} />
          </Form.Item>
        )}
        <b>
          Warning: Setting this section to default collapsed will make all fields
          in the section optional
        </b>
        {!isExternalForm && enablePermissions && (
          <>
            <Divider />
            <Form.Item
              name="permissions"
              trigger="onPermissionChanged"
              valuePropName="permissions"
            >
              <FormSectionPermissions
                divisions={divisions}
              />
            </Form.Item>
          </>
        )}
        {!isTimeCard && (
          <ConditionalRenderingRow
            id={id}
            onChange={(e) => setHasConditionalRendering(e?.target?.checked)}
            onFormulaChange={setConditionalRenderingFormula}
            hasConditionalRendering={hasConditionalRendering}
            conditionalRenderingFormula={conditionalRenderingFormula}
            sections={sections}
            customers={customers}
            projects={projects}
            users={users}
            costcodes={costcodes}
            phases={phases}
            projectIdMap={projectIdMap}
            vendors={vendors}
            equipment={equipment}
            formTemplates={formTemplates}
            labels={labels}
            contactAddressBooks={contactAddressBooks}
            divisions={divisions}
            buckets={buckets}
            isSection
          />
        )}
      </Form>
      <OnTraccrButton
        title="Save"
        roundStyle
        style={{ margin: 10 }}
        onClick={onSubmit}
      />
    </CustomModal>
  );
}

FormSectionSettingsModal.propTypes = {
  id: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onSettingsUpdated: PropTypes.func.isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    defaultCollapsed: PropTypes.bool,
    isDuplicationEnabled: PropTypes.bool,
  }),
  divisions: PropTypes.arrayOf(PropTypes.shape({})),
  isBoardCards: PropTypes.bool,
  isExternalForm: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  isTimeCard: PropTypes.bool,
};

FormSectionSettingsModal.defaultProps = {
  id: null,
  initialValues: {},
  divisions: undefined,
  isBoardCards: false,
  isExternalForm: false,
  sections: [],
  isTimeCard: false,
};
