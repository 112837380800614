import React from 'react';
import { Checkbox, Row, Col } from 'antd';

import TitleRow from './TitleRow';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import DisplayText from '../../../common/text/DisplayText';

import CalculationDropdown from './CalculationDropdown';
import OptionalRow from './OptionalRow';
import HoverHelp from '../../../common/HoverHelp';

import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import FieldTriggerFlag from './FieldTriggerFlag';
import ConditionalRenderingRow from './ConditionalRenderingRow';
import { currencyFormatter } from '../../../helpers/helpers';

const configure = ({
  id,
  sectionId,
  setConfigProps,
  configProps = {},
  setCanSubmit,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  customers = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  projects = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
} = {}) => {
  const {
    optional,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
  } = configProps;

  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title: value,
    });
    setCanSubmit(value);
  };

  const setOptional = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      optional: checked,
    });
  };

  const onFormulaChange = (formula) => {
    setConfigProps({
      ...configProps,
      formula,
    });
  };

  const onTagsChange = (tags) => {
    setConfigProps({
      ...configProps,
      tags,
    });
  };

  const onCurrencyChange = (e) => {
    const { target: { checked } = {} } = e;
    setConfigProps({
      ...configProps,
      isCurrency: checked,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setConditionalRenderingFormula = (formula) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: formula,
    });
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  return (
    <div>
      <Row className='form-required-field'>
        Title:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea placeholder='Insert title here'
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow onChange={setOptional} optional={optional} disabled={disableOptional}/>
      <ConditionalRenderingRow
        onChange={setHasConditionalRendering}
        onFormulaChange={setConditionalRenderingFormula}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
        sections={sections}
        id={id}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      <Row style={{ marginTop: 10 }} gutter={10}>
        <Col>
          <Checkbox
            onChange={onCurrencyChange}
            checked={configProps.isCurrency}
          >Is Currency?</Checkbox>
        </Col>
        <Col>
        <HoverHelp
          placement='topRight'
          content={
            <div style={{ width:250}}>
              Check this box if you want the result to have a '$' prefix (e.g. $25.00)
            </div>
          }
        />
        </Col>
      </Row>
      <Row className='form-required-field' style={{ marginTop: 15 }}>
        Formula:
      </Row>
      <CalculationDropdown
        id={id}
        sectionId={sectionId}
        sections={sections}
        onFormulaChange={onFormulaChange}
        onTagsChange={onTagsChange}
        formula={configProps.formula}
        tags={configProps.tags}
      />
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          isExternalForm={isExternalForm}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  );
};

const preview = ({
  id,
  configProps = {},
  responses = {}, // Actively responding
  previewProps = {}, // Completed form
  responding = false,
  sections,
  isExternalForm,
  projectId,
  templateId,
  divisions,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  isDisplay,
  name,
} = {}) => {
  const {
    value: previewValue = '0.00',
  } = previewProps;
  const {
    optional,
    title = 'Title goes here',
    isCurrency,
    fieldTrigger,
  } = configProps;
  const {
    [id]: {
      value = (isCurrency ? '0.00' : '0'),
    } = {},
  } = responses;
  let displayValue = `${responding ? value : previewValue}`;
  if (isCurrency) {
    if (isDisplay) displayValue = displayValue.replace(/\$\s?/g, '');
    displayValue = currencyFormatter(displayValue);
  }

  return (
    <div>
      <TitleRow
        title={title}
        optional={optional}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                isExternalForm={isExternalForm}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                id={id}
                fieldTriggerMap={fieldTriggerMap}
                setFieldTriggerMap={setFieldTriggerMap}
                name={name}
              />
            ) : null
        }
      />
      <Row style={{ marginTop: 15, minHeight: 32 }}>
        <DisplayText title={displayValue} />
      </Row>
    </div>
  );
};

export default {
  configure,
  preview,
  title: 'Calculation',
  description: 'Use this field to calculate values from numbers or other field values',
};
