import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Row, Col, Input } from 'antd';

import { TaskHelpers } from 'ontraccr-common';
import TimeTracking from '../../state/timeTracking';
import OnTraccrMap from '../../common/map/OnTraccrMap';
import getGoogleMapKey from '../../common/keys';
import { getUsers } from '../state/users.actions';
import BreadcrumbContainer from '../../common/breadcrumbContainer/breadcrumbContainer';
import { roundTotalRuntime } from '../../helpers/time';
import DateTimeWithZone from '../../common/text/DateTimeWithZone';

import Permissions from '../../auth/Permissions';
import { getCompanySettings, getPositions } from '../../settings/state/settings.actions';
import MapUsersList from './MapUsersList';
import colors from '../../constants/Colors';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import { search, isNullOrUndefined } from '../../helpers/helpers';
import { getProjects } from '../../projects/state/projects.actions';
import ActiveUsersFilterDrawer from './ActiveUsersFilterDrawer';
import useDivisionUsers from '../../common/hooks/useDivisionUsers';

const CRUMBS = [{
  text: 'Staff',
  icon: 'environment',
}, {
  text: 'User Map',
}];

const calcDuration = (startTime, endTime, roundingInterval, roundingType, roundingSetting) => {
  const latestTime = endTime || new Date();
  let timeDiff = roundTotalRuntime(
    latestTime - startTime,
    roundingInterval,
    roundingType,
    roundingSetting,
  );
  timeDiff /= 1000;
  const hourDiff = Math.floor(timeDiff / 3600);
  timeDiff -= hourDiff * 3600;
  const minuteDiff = Math.floor(timeDiff / 60);
  timeDiff -= minuteDiff * 60;
  return `${hourDiff} Hours ${minuteDiff} Minutes ${Math.round(timeDiff)} Seconds`;
};

function ActiveUsersMap({
  history,
}) {
  const hasReadPerms = Permissions.has('USERS_MAP_READ');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getCompanySettings());
    dispatch(getPositions());
    dispatch(getProjects());
  }, []);

  const {
    positionNames: positions = [],
    selectedDivisions = new Set(),
  } = useSelector((state) => state.settings);
  const projects = useSelector((state) => state.projects.projects);
  const settings = useSelector((state) => (
    state.settings.company.settings
  ));
  const {
    roundingInterval,
    roundingType,
    roundingSetting,
  } = settings ?? {};
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);

  const [selectedUserId, setSelectedUserId] = useState();
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const [searchPhrase, setSearchPhrase] = useState('');
  const [rolesFilter, setRolesFilter] = useState([]);
  const [projectsFilter, setProjectsFilter] = useState([]);

  const positionNames = useMemo(() => positions.map((position) => ({
    label: position.name,
    value: position.name,
  })), [positions]);

  const projectOptions = useMemo(() => {
    const filteredProjects = projects.filter((project) => (
      project.active
      && selectedDivisions.has(project.divisionId)
    ));
    return filteredProjects.map((project) => ({
      label: project.name,
      value: project.id,
      key: project.id,
    }));
  }, [projects, selectedDivisions]);

  const updateSearchPhrase = (e) => {
    setSearchPhrase(e.target.value);
  };

  const usersFromSelectedDivisions = useDivisionUsers();

  useEffect(() => {
    dispatch(TimeTracking.getTimeEntries({
      startTime: DateTime.now().startOf('day').toMillis(),
      endTime: DateTime.now().endOf('day').toMillis(),
      userIds: usersFromSelectedDivisions.map((u) => u.id),
      getRunningTasks: true,
    }));
  }, [usersFromSelectedDivisions]);

  const getActiveUsersFromToday = () => usersFromSelectedDivisions.filter((user) => {
    const tasks = timeEntryUserMap[user?.id] ?? [];
    if (tasks.length === 0) return false;
    const lastTask = tasks[tasks.length - 1];
    const currentDate = DateTime.local();
    const taskDT = TaskHelpers.getTaskDate(lastTask);
    // eslint-disable-next-line max-len
    if (taskDT && taskDT > currentDate) return true;
    return TaskHelpers.taskIsToday(lastTask);
  });

  const displayedUsers = useMemo(() => {
    const activeUsersFromToday = getActiveUsersFromToday();
    const rolesSet = new Set(rolesFilter);
    const projectsSet = new Set(projectsFilter);
    let filteredUsers = [...activeUsersFromToday];
    if (rolesFilter.length) {
      filteredUsers = filteredUsers.filter((user) => rolesSet.has(user.position));
    }
    if (projectsFilter.length) {
      filteredUsers = filteredUsers.filter((user) => {
        const timeEntries = timeEntryUserMap[user?.id] ?? [];
        const lastEntry = timeEntries.length ? timeEntries[timeEntries.length - 1] : {};
        return projectsSet.has(lastEntry?.projectId);
      });
    }
    const relevantUsers = search({
      data: filteredUsers,
      value: searchPhrase,
      getField: (item) => item.name,
    });
    return relevantUsers;
  }, [usersFromSelectedDivisions, searchPhrase, rolesFilter, projectsFilter, timeEntryUserMap]);

  const userMarkers = useMemo(() => {
    const markers = [];
    displayedUsers.forEach((user) => {
      const timeEntries = timeEntryUserMap[user?.id] ?? [];
      const lastEntry = timeEntries.length ? timeEntries[timeEntries.length - 1] : {};
      if (
        isNullOrUndefined(lastEntry.startLatitude)
        || isNullOrUndefined(lastEntry.startLongitude)
        || !TaskHelpers.taskIsToday(lastEntry)
      ) return;
      const todaysTasks = timeEntries.filter(TaskHelpers.taskIsToday);
      if (!todaysTasks.length) return;
      markers.push({
        id: user.id,
        name: user.name,
        clockInTime: lastEntry.startTime,
        clockOutTime: lastEntry.endTime,
        timezone: lastEntry.timezone,
        lat: lastEntry.endLatitude || lastEntry.startLatitude,
        lng: lastEntry.endLongitude || lastEntry.startLongitude,
      });
    });
    return markers;
  }, [displayedUsers, timeEntryUserMap]);

  const markerRender = useCallback((marker) => {
    const clockedInTime = DateTime.fromMillis(marker.clockInTime, { zone: marker.timezone })
      .toLocaleString(DateTime.DATE_MED);
    const clockedOutTime = marker.clockOutTime
      ? DateTime.fromMillis(marker.clockOutTime, { zone: marker.timezone })
        .toLocaleString(DateTime.DATE_MED)
      : null;
    const duration = calcDuration(
      marker.clockInTime,
      marker.clockOutTime,
      roundingInterval,
      roundingType,
      roundingSetting,
    );
    return (
      <div className="user-marker-wrapper">
        <h3>{marker.name}</h3>
        <div className="user-marker-popup">
          <div className="user-marker-times-wrapper">
            <div className="map-clock-in-out-text">
              <span className="map-bolded-text">Clocked In: </span>
              {`${clockedInTime} at`}
              <DateTimeWithZone
                startTime={marker.clockInTime}
                timezone={marker.timezone}
              />
            </div>
            {marker.clockOutTime && (
              <div className="map-clock-in-out-text">
                <span className="map-bolded-text">Clocked Out: </span>
                {`${clockedOutTime} at`}
                <DateTimeWithZone
                  startTime={marker.clockOutTime}
                  timezone={marker.timezone}
                />
              </div>
            )}
            <p>
              <span className="map-bolded-text">Duration: </span>
              {duration}
            </p>
          </div>
          <div className="map-lat-lng-wrapper">
            <p>
              <span className="map-bolded-text">Latitude: </span>
              {marker.lat}
            </p>
            <p>
              <span className="map-bolded-text">Longitude: </span>
              {marker.lng}
            </p>
          </div>
        </div>
      </div>
    );
  }, [roundingInterval, userMarkers]);

  if (!hasReadPerms) {
    history?.replace('/dashboard');
    return <></>;
  }

  return (
    <BreadcrumbContainer crumbs={CRUMBS}>
      <Row
        type="flex"
        justify="start"
        gutter={14}
        style={{
          height: 32,
          marginBottom: 20,
        }}
      >
        <Col span={5}>
          <Input.Search
            className="searchbar"
            placeholder="Search"
            onChange={updateSearchPhrase}
            allowClear
          />
        </Col>

        <Col flex={80}>
          <OnTraccrButton
            title="Filter"
            type={rolesFilter.length || projectsFilter.length ? 'back' : 'primary'}
            onClick={() => setShowFilterDrawer(true)}
          />
        </Col>
      </Row>
      <Row style={{ height: '100%' }} gutter={10}>
        <Col span={6} style={{ height: '100%' }}>
          <MapUsersList
            activeUsers={displayedUsers}
            onUserClick={setSelectedUserId}
          />
        </Col>
        <Col span={18} style={{ height: '100%' }}>
          <OnTraccrMap
            showSearch={false}
            googleMapURL={getGoogleMapKey()}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={(
              <div style={{
                height: '100%',
                width: '100%',
                border: `1px solid ${colors.ONTRACCR_GRAY}`,
                borderRadius: 9,
              }}
              />
            )}
            mapElement={<div style={{ height: '100%', borderRadius: 9, boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)' }} />}
            markers={userMarkers}
            showPin={false}
            selectedMarkerId={selectedUserId}
            setSelectedMarker={setSelectedUserId}
            markerRender={markerRender}
          />
        </Col>
      </Row>
      <ActiveUsersFilterDrawer
        visible={showFilterDrawer}
        setDrawerVisibility={setShowFilterDrawer}
        roles={positionNames}
        projects={projectOptions}
        rolesFilter={rolesFilter}
        projectsFilter={projectsFilter}
        setRolesFilter={setRolesFilter}
        setProjectsFilter={setProjectsFilter}
      />
    </BreadcrumbContainer>
  );
}

ActiveUsersMap.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default ActiveUsersMap;
