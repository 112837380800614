import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useReducer,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Select,
  Tag,
  Tabs,
  Row,
  Col,
  notification,
  Upload,
  Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import Barcode from 'react-barcode';
import qrcodeParser from 'qrcode-parser';
import { PictureOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import DisplayText from '../common/text/DisplayText';
import DivisionSelector from '../common/inputs/DivisionSelector';
import FileReelWithUpload from '../common/files/FileReelWithUpload';
import Notes from '../notes/Notes';
import IntegrationTag from '../common/IntegrationTag';
import LabelSelector from '../labels/LabelSelector';

import BoardLinkView from '../boards/BoardLinkView';

import EquipmentFiles from './EquipmentFiles';
import EquipmentHistoryList from './EquipmentHistoryList';
import EquipmentStatusSelector from './EquipmentStatusSelector';
import EquipmentProfilePicture from './EquipmentProfilePicture';
import EquipmentFormList from './EquipmentFormList';

import { areStringsEqual, getIdMap, sortByCode } from '../helpers/helpers';
import { request } from '../helpers/requests';

import {
  getEquipmentFiles,
  getNotes,
  addNote,
  getEquipmentCustomData,
} from './state/equipment.actions';
import Subtasks from '../subtasks/Subtasks';
import FilteredUserSelection from '../common/inputs/FilteredUserSelector';
import FormCurrencyInput from '../common/inputs/FormCurrencyInput';
import CustomFields from '../common/customFields/CustomFields';
import Colors from '../constants/Colors';
import qrCodeFormatOptions from '../constants/QRCodeFormatOptions';
import { getQRCodes } from '../qrCodes/qrCodes.actions';

import { formatProjectLabelFromCompanySettings } from '../projects/projectHelpers';
import { downloadFile, loadFromSigned } from '../files/fileHelpers';
import { getBase64ImageFromURL } from '../projects/ProjectScheduleOfValues/PDF/sov.pdf.helpers';

const { Option } = Select;
const { TabPane } = Tabs;

const validateKey = ({ id, equipment }) => async (_, value) => {
  if (equipment.some((eq) => (
    areStringsEqual(eq.name, value)
    && id !== eq.id
  ))) {
    return Promise.reject(new Error(`Equipment name ${value} already exists`));
  }

  return Promise.resolve();
};

const validateDivision = (formRef) => () => {
  formRef.current.validateFields(['name']);
  return Promise.resolve();
};

const validateCode = ({ id, equipment = [] }) => (_, newCode) => {
  if (equipment.some((eq) => newCode && eq.code === newCode && id !== eq.id)) {
    return Promise.reject(new Error(`Equipment with ID ${newCode} already exists`));
  }
  return Promise.resolve();
};

const validateQRCode = ({
  id,
  qrCodes,
  isCodeValid,
}) => (_, newCode) => {
  if (!isCodeValid) {
    return Promise.reject(new Error('Invalid barcode provided'));
  }

  if (qrCodes.some((qr) => qr.value === newCode && (!id || id !== qr.equipmentId))) {
    return Promise.reject(new Error(`Code with value ${newCode} already exists`));
  }
  return Promise.resolve();
};

const qrCodeFormatMap = getIdMap(qrCodeFormatOptions, 'value');

const tabFillStyle = {
  position: 'absolute',
  left: 24,
  right: 24,
  top: 72,
  bottom: 0,
  width: 'auto',
};

const costFormatter = (value) => (value ? `$${value}` : value);

export default function EquipmentAddView({
  formProps,
  equipment,
  formRef,
  isAdd,
  isProjectSpecific,
  errors,
  customFields,
  setCustomFields,
  actionButtonEnabled,
}) {
  const { t } = useTranslation();
  const qrRef = useRef();
  const barcodeRef = useRef();

  const {
    id,
    name,
    divisionIds,
    code,
    editing = false,
    statusId,
    labels = [],
    eclipseId,
    key = 'editEquip',
    userId,
    hourlyCost,
    hourlyBillingRate,
    dailyCost,
    dailyBillingRate,
    equipmentTypeId = null,
    qrCode,
    costcodeId,
    hasImage,
  } = formProps ?? {};

  const isDisplay = !(isAdd || editing);

  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects.projects);
  const equipmentProjects = useSelector((state) => state.equipment.equipmentProjects);
  const equipmentFiles = useSelector((state) => state.equipment.equipmentFiles);
  const qrCodes = useSelector((state) => state.qrCodes);
  const costcodes = useSelector((state) => state.costcodes.costcodes);

  const {
    customData,
    customDataFiles,
  } = useSelector((state) => state.equipment);
  const notes = useSelector((state) => state.equipment.notes);
  const cardLinks = useSelector((state) => state.boards.cardLinks);
  const users = useSelector((state) => state.users.users);
  const divisions = useSelector((state) => state.settings.divisions);
  const equipmentTypes = useSelector((state) => state.equipment.equipmentTypes);
  const { settings = {} } = useSelector((state) => state.settings.company);

  const [qrCodeValue, setQRCodeValue] = useState(qrCode?.value ?? '');
  const [qrCodeFormat, setQRCodeFormat] = useState(qrCode?.format ?? 'qrCode');
  const [selectedDivisions, setSelectedDivisions] = useState(divisionIds ?? []);
  const [shouldUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isCodeValid, setIsCodeValid] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [cachedImage, setCachedImage] = useState();

  const ourLinks = useMemo(() => {
    if (!id || editing) return [];
    return cardLinks.filter((cardLink) => (
      cardLink.equipmentId === id
    ));
  }, [id, editing, cardLinks]);

  const {
    [id]: ourFiles,
  } = equipmentFiles;

  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const projectOptions = useMemo(() => (
    projects
      .filter((project) => selectedDivisions.includes(project.divisionId))
      .map((project) => ({
        value: project.id,
        label: formatProjectLabelFromCompanySettings({
          name: project.name,
          number: project.number,
          settings,
        }),
      }))
  ), [selectedDivisions, settings]);

  const costcodeName = useMemo(() => {
    const {
      [costcodeId]: {
        name: ccName,
      } = {},
    } = costcodeMap;
    return ccName;
  }, [costcodeId, costcodeMap]);

  const relevantCostcodes = useMemo(() => {
    if (!selectedDivisions?.length) return [];

    const selectedDivisionsSet = new Set(selectedDivisions);

    return costcodes.filter((cc) => (
      cc.active
      && !cc.projectId
      && selectedDivisionsSet.has(cc.divisionId)
      && cc.category === 'Equipment'
    )).sort(sortByCode('code'));
  }, [costcodes, selectedDivisions]);

  const { ourProjects, ourProjectIds } = useMemo(() => {
    const {
      [id]: eqp = [],
    } = equipmentProjects;
    return {
      ourProjects: eqp.map((projectId) => projectMap[projectId])
        .filter((proj) => proj),
      ourProjectIds: eqp,
    };
  }, [equipmentProjects, projectMap, id]);

  const relevantUsers = useMemo(() => {
    const relevantSets = Array.from(selectedDivisions).map((divisionId) => (
      divisions[divisionId]?.users ?? new Set()
    ));

    if (!relevantSets.length) return [];

    const divisionUserIds = relevantSets.reduce((acc, set) => {
      set.forEach((userId) => acc.add(userId));
      return acc;
    }, new Set());
    return users.filter((user) => divisionUserIds.has(user.id) && user.active);
  }, [users, selectedDivisions, divisions]);

  useEffect(() => {
    // Clear selected projects and assigned user when division changes
    if (formRef?.current) {
      const values = formRef.current.getFieldsValue();
      const {
        projectIds: formProjectIds = [],
        userId,
      } = values;
      const newProjectIds = formProjectIds.map((projectId) => projectMap[projectId])
        .filter((project) => project && selectedDivisions.includes(project.divisionId))
        .map((project) => project.id);

      let newUserId = null;

      // If the user is still relevant, keep them
      if (relevantUsers.some((user) => user.id === userId)) {
        newUserId = userId;
      }

      formRef.current.setFieldsValue({
        ...values,
        projectIds: newProjectIds,
        userId: newUserId,
      });
    }
  }, [selectedDivisions, formRef, projectMap, relevantUsers]);

  useEffect(() => {
    const loadImage = async () => {
      if (cachedImage?.uid === id) {
        formRef.current.setFieldsValue({
          image: cachedImage,
        });
        return;
      }
      setImageLoaded(false);
      actionButtonEnabled(false);
      const { data: signedURL } = await request({
        call: axios.get(`/equipment/${id}/image`),
        hideSuccessToast: true,
      });
      if (signedURL) {
        const newImage = await loadFromSigned({
          signedURL,
          name,
          id,
        });
        setCachedImage(newImage);
        formRef.current.setFieldsValue({
          image: newImage,
        });
      }
      actionButtonEnabled(true);
      setImageLoaded(true);
    };
    if (id && hasImage) {
      loadImage();
    } else {
      setImageLoaded(true);
    }
  }, [id, name, hasImage, editing, formRef, cachedImage]);
  useEffect(() => {
    if (id) dispatch(getEquipmentFiles(id));
    dispatch(getEquipmentCustomData(id));
    dispatch(getQRCodes());
  }, [id]);

  useEffect(() => {
    forceUpdate();
  }, [customData]);

  useEffect(() => {
    setSelectedDivisions(divisionIds ?? []);
  }, [divisionIds]);

  useEffect(() => {
    setQRCodeValue(qrCode?.value ?? '');
    setQRCodeFormat(qrCode?.format ?? 'qrCode');
  }, [qrCode]);

  useEffect(() => {
    const formVals = formRef.current.getFieldsValue() ?? {};
    formRef.current.setFieldsValue({
      ...formVals,
      files: ourFiles ?? [],
    });
  }, [formRef, ourFiles]);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const equipmentTypeMap = useMemo(() => getIdMap(equipmentTypes), [equipmentTypes]);

  const onValuesChange = useCallback((_, allValues) => {
    const {
      equipmentTypeId: newEquipmentTypeId,
    } = allValues;

    const defaultEquipmentType = equipmentTypes.find((type) => type.isDefault);
    const equipmentType = equipmentTypeMap[newEquipmentTypeId] ?? defaultEquipmentType;
    setCustomFields(equipmentType?.customFields?.sections);
  }, [equipmentTypeMap]);

  useEffect(() => {
    onValuesChange(null, { equipmentTypeId });
  }, [isAdd, equipmentTypeId]);

  const onDownloadQR = useCallback(async () => {
    const isQR = qrCodeFormat === 'qrCode';
    const ref = isQR
      ? qrRef?.current
      : barcodeRef?.current?.renderElementRef?.current;
    const data = ref?.outerHTML;
    const buffer = Buffer.from(data);
    const dataURL = await getBase64ImageFromURL(`data:image/svg+xml;base64,${buffer.toString('base64')}`);
    downloadFile({
      fileURL: dataURL,
      saveName: `${name}_${isQR ? 'qrcode' : 'barcode'}.png`,
    });
  }, [qrRef, barcodeRef, name]);

  const onQRFileChange = useCallback(async (file) => {
    if (!file) return;

    try {
      const result = await qrcodeParser(file);
      setQRCodeValue(result);
    } catch (err) {
      notification.error({
        message: 'Invalid QR Code',
        getContainer: null,
        placement: 'topRight',
        bottom: 50,
      });
    }
  }, []);

  useEffect(() => {
    formRef.current.setFieldsValue({
      qrCodeValue,
      qrCodeFormat,
    });
  }, [qrCodeValue, qrCodeFormat, formRef]);

  useEffect(() => {
    formRef.current.validateFields(['qrCodeValue']);
  }, [isCodeValid, formRef]);

  const onQRCodeFormatChange = (value) => {
    setQRCodeFormat(value);
    if (value === 'qrCode') {
      setIsCodeValid(true);
    }
  };

  const body = (
    <Form
      className={!isDisplay && 'user-add-form'}
      layout="vertical"
      ref={formRef}
      initialValues={{
        name,
        divisionIds: divisionIds ?? [],
        code,
        projectIds: ourProjectIds,
        files: ourFiles ?? [],
        labels,
        statusId,
        userId,
        hourlyCost,
        hourlyBillingRate,
        dailyCost,
        dailyBillingRate,
        equipmentTypeId,
        qrCodeValue,
        qrCodeFormat,
        costcodeId,
      }}
      key={key}
      onValuesChange={onValuesChange}
    >
      <Row justify="space-between">
        <Col style={{ width: '40%' }}>
          <Form.Item
            label="ID"
            name="code"
            style={{ marginBottom: 20 }}
            rules={[
              { validator: validateCode({ id, equipment }) },
            ]}
          >
            {!isDisplay ? (
              <OnTraccrTextInput
                style={{ width: '100%' }}
              />
            ) : <DisplayText title={code} />}
          </Form.Item>

          <Form.Item
            name="name"
            label="Name"
            style={{ marginBottom: 20 }}
            rules={[
              { required: isAdd, message: 'Please enter a name' },
              { validator: validateKey({ id, equipment }) },
            ]}
          >
            {!isDisplay ? (
              <OnTraccrTextInput
                style={{ width: '100%' }}
              />
            ) : <DisplayText title={name} />}
          </Form.Item>

          <Form.Item
            name="divisionIds"
            key="divisionIds"
            label="Divisions"
            style={{ marginBottom: 20, width: '100%' }}
            valuePropName="divisionIds"
            rules={[
              { required: !isDisplay, message: 'Please select a division' },
              { validator: validateDivision(formRef) },
            ]}
          >
            <DivisionSelector
              displayMode={(!editing && !isAdd) || isProjectSpecific}
              mode="multiple"
              onChange={setSelectedDivisions}
              divisions={selectedDivisions}
            />
          </Form.Item>

          <Form.Item
            name="equipmentTypeId"
            label="Type"
            style={{ marginBottom: 20 }}
          >
            {
              isDisplay ? (
                <DisplayText title={equipmentTypeMap[equipmentTypeId]?.name ?? 'None'} />
              ) : (
                <Select showSearch>
                  <Option key="-1" value={null}>None</Option>
                  {equipmentTypes
                    .filter((type) => !type.isDefault)
                    .map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                </Select>
              )
            }
          </Form.Item>

          {!isProjectSpecific && (
            <Form.Item
              name="projectIds"
              key="projectIds"
              label={t('Project', { count: 2 })}
              style={{ marginBottom: 20, width: '100%' }}
            >
              {
                !isDisplay ? (
                  <Select
                    mode="multiple"
                    optionFilterProp="label"
                    showSearch
                    options={projectOptions}
                  />
                ) : ourProjects.map((project) => (
                  <Tag
                    key={project.id}
                    style={{
                      maxWidth: 325,
                      margin: 3,
                      overflow: 'hidden',
                    }}
                  >
                    {
                      formatProjectLabelFromCompanySettings({
                        name: project.name,
                        number: project.number,
                        settings,
                      })
                    }
                  </Tag>
                ))
              }
            </Form.Item>
          )}
          <Form.Item
            name="statusId"
            key="statusId"
            label="Status"
            style={{ marginBottom: 20, width: '100%' }}
            rules={[
              { required: !isDisplay, message: 'Please select a status' },
            ]}
          >
            <EquipmentStatusSelector isDisplay={isDisplay} />
          </Form.Item>

          {isDisplay && (
            <Form.Item
              key="integrations"
              label="Integrations"
              style={{ marginBottom: 0 }}
            >
              <IntegrationTag id={eclipseId} title="Eclipse" />
            </Form.Item>
          )}
        </Col>
        <Col style={{ width: '50%' }}>
          {!isProjectSpecific && (
            imageLoaded
              ? (
                <Form.Item
                  name="image"
                  key="image"
                  label={isDisplay ? '' : 'Picture'}
                  style={{ marginBottom: 20, width: '100%' }}
                >
                  <EquipmentProfilePicture isDisplay={isDisplay} />
                </Form.Item>
              )
              : <Spin />
          )}
          <Form.Item
            name="qrCodeValue"
            key="qrCodeValue"
            label={qrCodeFormat === 'qrCode' ? 'QR Code Value' : 'Barcode Value'}
            rules={[
              { validator: validateQRCode({ id, qrCodes, isCodeValid }) },
            ]}
          >
            {!isDisplay ? (
              <OnTraccrTextInput
                onChange={(e) => setQRCodeValue(e.target.value)}
                style={{ width: '100%' }}
              />
            ) : <DisplayText title={qrCodeValue} />}
          </Form.Item>
          <Form.Item
            name="qrCodeFormat"
            key="qrCodeFormat"
            label="Unique Code Format"
          >
            {!isDisplay ? (
              <Select
                onChange={onQRCodeFormatChange}
                options={qrCodeFormatOptions}
                optionFilterProp="label"
                disabled={isDisplay}
              />
            ) : (
              <DisplayText title={qrCodeFormatMap[qrCodeFormat]?.label} />
            )}
          </Form.Item>
          {(!isDisplay || qrCodeValue) && qrCodeFormat === 'qrCode' && (
            <Row>
              <Col style={{ marginRight: 15 }}>
                <QRCode value={qrCodeValue} size={100} ref={qrRef} />
              </Col>
              {isDisplay
                && (
                  <Col style={{ marginRight: 15 }}>
                    <BorderlessButton
                      onClick={onDownloadQR}
                      iconNode={<DownloadOutlined />}
                    />
                  </Col>
                )}
              {!isDisplay && (
                <Col>
                  <Upload
                    listType="picture-card"
                    customRequest={({ file }) => onQRFileChange(file)}
                    showUploadList={false}
                  >
                    <div>
                      <PictureOutlined style={{ fontSize: 30, color: Colors.ONTRACCR_RED }} />
                    </div>
                  </Upload>
                </Col>
              )}
            </Row>
          )}
          {qrCodeFormat !== 'qrCode' && (
            <Row>
              <Col>
                <Barcode
                  value={qrCodeValue}
                  format={qrCodeFormat}
                  valid={setIsCodeValid}
                  ref={barcodeRef}
                />
              </Col>
              {isDisplay
                && (
                  <Col style={{ marginRight: 15 }}>
                    <BorderlessButton
                      onClick={onDownloadQR}
                      iconNode={<DownloadOutlined />}
                    />
                  </Col>
                )}
            </Row>
          )}
        </Col>
      </Row>
      <Form.Item
        name="labels"
        key="labels"
        label="Labels"
        style={{ marginBottom: 20, width: '100%' }}
      >
        <LabelSelector
          type="equipment"
          isDisplay={isDisplay}
        />
      </Form.Item>
      <Form.Item
        name="userId"
        key="userId"
        label="Assigned User"
        style={{ marginBottom: 20 }}
      >
        {isDisplay
          ? (
            <DisplayText title={userMap[userId]?.name} />
          )
          : (
            <FilteredUserSelection
              users={relevantUsers}
              mode="single"
            />
          )}
      </Form.Item>
      <Form.Item
        name="costcodeId"
        label="Cost Code"
        key="costcodeId"
        style={{ marginBottom: 20 }}
      >
        {isDisplay ? (
          <DisplayText title={costcodeName} />
        ) : (
          <Select
            placeholder="Select a Cost Code"
            allowClear
            showSearch
            optionFilterProp="label"
            options={relevantCostcodes.map((cc) => ({ label: `${cc.code} - ${cc.name}`, value: cc.id }))}
          />
        )}
      </Form.Item>
      <Row gutter={20} justify="space-between" align="middle">
        <Col span={12}>
          <FormCurrencyInput
            name="hourlyCost"
            label="Hourly Cost"
            isNotDisplay={!isDisplay}
            min={0}
            value={costFormatter(hourlyCost)}
          />
        </Col>
        <Col span={12}>
          <FormCurrencyInput
            name="hourlyBillingRate"
            label="Hourly Billing Rate"
            isNotDisplay={!isDisplay}
            min={0}
            value={costFormatter(hourlyBillingRate)}
          />
        </Col>
        <Col span={12}>
          <FormCurrencyInput
            name="dailyCost"
            label="Daily Cost"
            isNotDisplay={!isDisplay}
            min={0}
            value={costFormatter(dailyCost)}
          />
        </Col>
        <Col span={12}>
          <FormCurrencyInput
            name="dailyBillingRate"
            label="Daily Billing Rate"
            isNotDisplay={!isDisplay}
            min={0}
            value={costFormatter(dailyBillingRate)}
          />
        </Col>
      </Row>
      {
        !isDisplay && (
          <Form.Item
            name="files"
            key="files"
            label="Files"
            labelCol={{
              style: {
                paddingBottom: 0,
                marginTop: 10,
              },
            }}
            style={{ width: '100%' }}
            valuePropName="files"
          >
            <FileReelWithUpload isDisplay={false} style={{ marginTop: 0 }} />
          </Form.Item>
        )
      }
      {!!customFields?.length && (
        <Form.Item
          name="customData"
          style={{ marginTop: 10 }}
        >
          <CustomFields
            key={`${id}${shouldUpdate}`} // Need to force re-render
            initialValues={customData}
            customFields={customFields}
            divisions={selectedDivisions}
            errors={errors}
            fileMap={customDataFiles}
            isDisplay={isDisplay}
          />
        </Form.Item>
      )}
    </Form>
  );
  if (!isDisplay) return body;
  return (
    <Tabs className="user-add-form">
      <TabPane tab="Details" key="details">
        {body}
      </TabPane>
      <TabPane tab="Notes" key="notes">
        <Notes
          id={id}
          notes={notes}
          addNote={addNote}
          getNotes={getNotes}
          headerStyle={{ paddingLeft: 24 }}
        />
      </TabPane>
      <TabPane tab="Forms" key="forms">
        <EquipmentFormList equipmentId={id} />
      </TabPane>
      <TabPane tab="Files" key="files">
        <EquipmentFiles equipmentId={id} />
      </TabPane>
      <TabPane
        tab="Tasks"
        key="tasks"
        style={tabFillStyle}
      >
        <Subtasks
          // eslint-disable-next-line react/jsx-props-no-spreading
          {
          ...id
            ? { query: { equipmentId: id } }
            : {}
          }
        />
      </TabPane>
      <TabPane tab="Timeline" key="timeline" style={{ ...tabFillStyle, top: 46 }}>
        <EquipmentHistoryList
          id={id}
          style={{
            height: '100%',
            padding: 5,
            paddingTop: 26,
          }}
        />
      </TabPane>
      {ourLinks.length > 0 && (
        <TabPane tab="Cards" key="cards">
          <BoardLinkView links={ourLinks} linkType="equipmentId" linkId={id} />
        </TabPane>
      )}
    </Tabs>
  );
}

EquipmentAddView.propTypes = {
  formProps: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    divisionIds: PropTypes.arrayOf(PropTypes.string),
    code: PropTypes.string,
    editing: PropTypes.bool,
    statusId: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.string,
    eclipseId: PropTypes.string,
    key: PropTypes.string,
    userId: PropTypes.string,
    hourlyCost: PropTypes.number,
    hourlyBillingRate: PropTypes.number,
    dailyCost: PropTypes.number,
    dailyBillingRate: PropTypes.number,
    equipmentTypeId: PropTypes.string,
    qrCode: PropTypes.shape({
      value: PropTypes.string,
      format: PropTypes.string,
    }),
  }),
  equipment: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    divisionIds: PropTypes.arrayOf(PropTypes.string),
    code: PropTypes.string,
    editing: PropTypes.bool,
    statusId: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.string,
    eclipseId: PropTypes.string,
    key: PropTypes.string,
    userId: PropTypes.string,
    hourlyCost: PropTypes.number,
    hourlyBillingRate: PropTypes.number,
    dailyCost: PropTypes.number,
    dailyBillingRate: PropTypes.number,
    equipmentTypeId: PropTypes.string,
    qrCode: PropTypes.shape({
      value: PropTypes.string,
      format: PropTypes.string,
    }),
  })),
  formRef: PropTypes.shape({
    current: PropTypes.shape({
      getFieldsValue: PropTypes.func,
      setFieldsValue: PropTypes.func,
      validateFields: PropTypes.func,
    }),
  }).isRequired,
  isAdd: PropTypes.bool,
  isProjectSpecific: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  customFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCustomFields: PropTypes.func.isRequired,
  actionButtonEnabled: PropTypes.func.isRequired,
};

EquipmentAddView.defaultProps = {
  formProps: null,
  equipment: [],
  isAdd: false,
  isProjectSpecific: false,
};
