import React from 'react';
import { Row, message } from 'antd';

import { CopyOutlined } from '@ant-design/icons';
import TitleRow from './TitleRow';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import BorderlessButton from '../../../common/buttons/BorderlessButton';

import FieldTriggerFlag from './FieldTriggerFlag';
import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import ConditionalRenderingRow from './ConditionalRenderingRow';
import OptionalRow from './OptionalRow';
import CheckboxRow from './CheckboxRow';
import CharacterLimitRow from './CharacterLimitRow';
import OnTraccrNumberInput from '../../../common/inputs/OnTraccrNumberInput';

const formatHyperlink = (link) => {
  if (!link || typeof link !== 'string') return link;
  return link?.match(/^(?:.{0,5}:)?\/\//)
    ? link
    : `https://${link}`;
};

const preview = ({
  setPreviewProps,
  configProps = {},
  previewProps = {},
  id,
  responses = {},
  setResponses,
  responding = false,
  isDisplay,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  name,
} = {}) => {
  const {
    optional,
    title = 'Title goes here',
    fieldTrigger,
    isHyperlink,
    isNumerical,
    isCharacterLimit,
    characterLimit,
  } = configProps;
  const {
    value,
  } = previewProps;
  const {
    [id]: {
      value: response = '',
    } = {},
  } = responding ? responses : {};
  const onTextChange = (e) => {
    let newValue;
    if (isNumerical) {
      newValue = e;
    } else {
      const targetValue = e?.target?.value;
      newValue = targetValue ? targetValue.replace(/\r/g, '') : targetValue;
    }

    if (responding) {
      const newResp = {
        [id]: {
          ...(responses[id]),
          value: newValue,
        },
      };
      setResponses({
        ...responses,
        ...newResp,
      });
    } else {
      if (!setPreviewProps) return;
      setPreviewProps({
        ...previewProps,
        value: newValue,
      });
    }
  };

  const onCopyClicked = async () => {
    await navigator.clipboard.writeText(value);
    message.success('Copied text');
  };

  const filterDisplay = (() => {
    if (isDisplay) {
      return (
        <BorderlessButton
          iconNode={<CopyOutlined />}
          style={{
            width: 'auto',
            height: 20,
            padding: 0,
          }}
          onClick={onCopyClicked}
        />
      );
    }
    if (fieldTrigger) {
      return (
        <FieldTriggerFlag
          sections={sections}
          isExternalForm={isExternalForm}
          templateId={templateId}
          projectId={projectId}
          divisions={divisions}
          configProps={configProps}
          responding={responding}
          id={id}
          fieldTriggerMap={fieldTriggerMap}
          setFieldTriggerMap={setFieldTriggerMap}
          name={name}
        />
      );
    }

    return null;
  });

  const ourValue = responding ? response : value;

  const formattedLink = (<a href={formatHyperlink(ourValue)} target="_blank" rel="noopener noreferrer">{ourValue}</a>);

  let input;
  if (isDisplay && isHyperlink && ourValue) {
    input = formattedLink;
  } else if (isNumerical) {
    input = (
      <OnTraccrNumberInput
        formatter={(val) => val.replace(/\D/g, '')}
        readOnly={isDisplay}
        value={ourValue}
        onChange={onTextChange}
        autoSize
      />
    );
  } else {
    input = (
      <div style={{
        position: 'relative', width: '100%', display: 'flex', alignItems: 'center',
      }}
      >
        <OnTraccrTextInput
          maxLength={isCharacterLimit ? (characterLimit || 1) : undefined}
          readOnly={isDisplay}
          textarea
          value={ourValue}
          onChange={onTextChange}
          autoSize
          style={isCharacterLimit ? { paddingRight: '70px' } : {}}
        />
        {isCharacterLimit && (
          <div
            style={{
              position: 'absolute',
              bottom: '5px',
              right: '10px',
              fontSize: '12px',
            }}
          >
            {ourValue ? ourValue.length : 0} / {characterLimit || 1}
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <TitleRow
        title={title}
        optional={optional}
        filter={filterDisplay()}
      />
      <Row style={{ marginTop: 15 }}>
        {input}
      </Row>
    </div>
  );
};

const configure = ({
  id,
  setConfigProps,
  configProps = {},
  setCanSubmit,
  sections = [],
  isExternalForm,
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  disableOptional,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  contactAddressBooks = {},
  labels = [],
  buckets = [],
} = {}) => {
  const {
    optional,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
    isHyperlink,
    isNumerical,
    isCharacterLimit,
  } = configProps;

  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      title: value,
    });
    setCanSubmit(value);
  };

  const setConditionalRenderingFormula = (newVal) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: newVal,
    });
  };

  const updateCheckbox = (key) => (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      [key]: checked,
    });
  };

  return (
    <div>
      <Row className="form-required-field">
        Title:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      {!isNumerical && (
      <CharacterLimitRow
        checked={isCharacterLimit}
        title="Add Character Limit"
        onChange={updateCheckbox('isCharacterLimit')}
        hoverText="Text will be limited to the number of characters specified"
        configProps={configProps}
        setConfigProps={setConfigProps}
      />
      )}
      {!isCharacterLimit && !isHyperlink && (
      <CheckboxRow
        title="Is Numerical Input"
        checked={isNumerical}
        onChange={updateCheckbox('isNumerical')}
        hoverText="Text will be rendered as a number input in the form and PDF"
      />
      )}
      {!isNumerical && (
      <CheckboxRow
        title="Is Hyperlink"
        checked={isHyperlink}
        onChange={updateCheckbox('isHyperlink')}
        hoverText="Text will be rendered as a clickable link in the form and PDF"
      />
      )}
      <OptionalRow onChange={updateCheckbox('optional')} optional={optional} disabled={disableOptional} />
      <ConditionalRenderingRow
        id={id}
        sections={sections}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
        onChange={updateCheckbox('hasConditionalRendering')}
        onFormulaChange={setConditionalRenderingFormula}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
      />
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={updateCheckbox('fieldTrigger')}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          isExternalForm={isExternalForm}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  );
};

export default {
  configure,
  preview,
  title: 'Text input',
  description: 'User can enter text',
};
