import { AdditionalItem, AdditionalLabel } from './AssignmentDropdownItems';
import { generateRandomId } from '../../helpers/helpers';

export default {};
export const getType = (field = {}) => {
  const {
    configProps: {
      dataType,
    } = {},
    selectedType: mainFieldType,
  } = field;
  // Attributes and calculations turn to text
  let type = mainFieldType === 'attribute' || mainFieldType === 'calculation' ? 'text' : mainFieldType;
  if (type === 'table' || type === 'dropdown') {
    type += ` - ${dataType}`;
  }
  return type;
};

export const getSourceFieldMap = (
  sourceSections = [],
  parentLogicalLoopNode = null,
  doesFormDropdownExist = false,
) => {
  // Map fields by type
  const fieldMap = {};
  sourceSections.forEach((section) => {
    const { fields: sectionFields = [], name: sectionName } = section;
    sectionFields.forEach((field) => {
      const {
        configProps: {
          title: fieldTitle,
          subDataType = [],
        } = {},
        id: fieldId,
      } = field;
      const type = getType(field);
      if (!(type in fieldMap)) {
        fieldMap[type] = [];
      }

      fieldMap[type].push({
        ...field,
        title: `${sectionName} - ${fieldTitle}`,
        fieldTitle,
      });

      if (parentLogicalLoopNode?.data?.field === fieldId) {
        let sourceType = type;
        let displayWarning = false;

        if (
          parentLogicalLoopNode?.data.mapLabelToEquipment
          && type === 'dropdown - Labels'
          && subDataType?.includes('equipment')
        ) {
          sourceType = 'dropdown - Equipment';
          displayWarning = true;
        }

        if (!(sourceType in fieldMap)) {
          fieldMap[sourceType] = [];
        }

        fieldMap[sourceType].push({
          ...field,
          id: `logicalLoop.${parentLogicalLoopNode.id}`,
          title: `${sectionName} - ${fieldTitle}`,
          fieldTitle,
          helpText: 'This option will use the respective values in the loop',
          displayWarning,
        });
      }
    });
  });

  if (doesFormDropdownExist) {
    fieldMap['dropdown - CompletedForms'] = [{
      id: 'parentCompletedFormsDropdown',
      title: 'Parent Form',
      helpText: 'This option will load the parent form into the dropdown',
    }];
  }

  fieldMap['form - PO Value'] = [{
    id: 'poValue',
    title: 'PO Value',
    helpText: 'This option will load the native PO Value. Note that it will ignore all materials during the sync.',
  }];

  return fieldMap;
};

export const updateData = (id, newData) => (dataMap = {}) => {
  const {
    [id]: oldData = {},
  } = dataMap;

  return {
    ...dataMap,
    [id]: {
      ...oldData,
      ...newData,
    },
  };
};

const USER_HELP = `The new form will be assigned to the user's selected in the configured form field`;
const userDropdownTitle = (title) => `Users selected in ${title} field`;
const UserDropdownLabel = ({ title }) => (
  <AdditionalLabel
    title={title}
    help={USER_HELP}
  />
);
const UserItem = ({ title }) => (
  <AdditionalItem
    title={userDropdownTitle(title)}
    help={USER_HELP}
  />
);

export const locateWorkflowParentElement = (id, elementType, elements = []) => {
  const targetToSourceMap = {};
  const elementMap = {};
  const relevantElements = [];
  let logicalLoopElement;

  elements.forEach((element) => {
    elementMap[element.id] = element;

    if (element.type === 'customEdge') {
      if (!targetToSourceMap[element.target]) {
        targetToSourceMap[element.target] = [];
      }
      targetToSourceMap[element.target].push(element.source);
    }

    if (element.id === id) {
      relevantElements.push(element);
    }
  });

  while (relevantElements.length && !logicalLoopElement) {
    const elementToCheck = relevantElements.pop();

    // eslint-disable-next-line no-continue
    if (!elementToCheck) continue;

    if (elementToCheck.type === elementType) {
      logicalLoopElement = elementToCheck;
    } else if (elementMap[targetToSourceMap[elementToCheck.id]]) {
      relevantElements.push(elementMap[targetToSourceMap[elementToCheck.id]]);
    }
  }

  return logicalLoopElement;
};

export const getUserFields = (sections = []) => {
  const userDropdowns = [];
  sections.forEach(({ fields = [] }) => {
    fields.forEach((field) => {
      const {
        id: fieldId,
        selectedType,
        configProps: {
          dataType,
          title,
        } = {},
      } = field;
      if (selectedType === 'dropdown' && dataType === 'Users') {
        userDropdowns.push({
          id: fieldId,
          label: <UserDropdownLabel title={title}/>,
          children: <UserItem title={title}/>
        });
      }
    });
  });
  return userDropdowns;
};

/**
 * Creates a new status object to add during form template creation
 * @param {string} name - status name
 * @returns {object} newly created status
 */
export const createNewStatus = (name) => {
  const newStatusId = generateRandomId();
  return {
    id: newStatusId,
    statusId: newStatusId,
    isNew: true,
    status: name,
  };
};
