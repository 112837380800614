import React from 'react';
import {
  Popover,
  Select,
  Tag,
  TimePicker,
  Tooltip,
} from 'antd';
import { CheckCircleOutlined, DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import Colors from '../../constants/Colors';

export default {};

export const defaultRoundingValues = [5, 10, 15, 30, 60];
export const defaultRoundingTypes = [
  {
    value: 'overallTime',
    label: 'Overall Time',
  },
  {
    value: 'taskTime',
    label: 'Task Time',
  },
];
export const defaultRoundingSettings = [
  { value: 'automatic', label: 'Automatic' },
  { value: 'roundUp', label: 'Round Up' },
  { value: 'roundDown', label: 'Round Down' },
];

export const getClockRows = (t) => [{
  key: 'reqProject',
  title: `Require ${t('Project')} Selection`,
  helpText: `Users must select a ${t('Project').toLowerCase()} in order to clock in`,
}, {
  key: 'reqCostcode',
  title: 'Require Cost Code Selection',
  helpText: 'Users must select a cost code in order to clock in',
}, {
  key: 'enableManualEntry',
  title: 'Enable Manual Entry',
  helpText: 'Users can opt to enter a time entry manually via the Manual Entry form instead of using the real-time clock',
}, {
  key: 'enableHourBasedTracking',
  title: 'Enable Hour Based Time Tracking',
  helpText: 'Allow users to enter a number of hours, instead of a start and end time',
}, {
  key: 'enableServiceJobs',
  title: 'Enable Service Jobs',
  helpText: `With service jobs, users have the option to quickly clock in by typing in a location and description for the task instead of clocking in to a specific ${t('Project').toLowerCase()} or cost code.`,
}, {
  key: 'enableLocalSelection',
  title: 'Enable Union Local Selection',
  helpText: 'Allow users to select which union local they will clock in as.',
}, {
  key: 'enableClassSelection',
  title: 'Enable Work Classification Selection',
  helpText: 'Allow users to select which work classification they will clock in as.',
}, {
  key: 'enableFacialCapture',
  title: 'Enable Facial Capture',
  helpText: 'Users must submit a photo when clocking in and out',
}, {
  key: 'enableAutoSubmission',
  title: 'Enable Automatic Submission',
  helpText: 'Mobile users\' daily time card entries will be submitted for approval immediately',
}, {
  key: 'enableClocking',
  title: 'Enable Clock In/Clock Out',
  helpText: 'Allows users to enable/disable clocking out. Warning: Unchecking this box will clock out any active users',
}, {
  key: 'enableBreaking',
  title: 'Enable Take Break/End Break',
  helpText: 'Allows users to manually take and end their breaks. Warning: Unchecking this box will end all active breaks',
}, {
  key: 'useEndDateOvernight',
  title: 'Use End Time For Overnight Tasks Date',
  helpText: 'Sets the date of entries based on the end time instead of the start time.',
}, {
  key: 'minuteIncrement',
  title: 'Set Minute Increment for Manual Entry',
  helpText: 'Set the minute increment for hour based manual time entry',
  divider: false,
}];

export const getGPSRows = (t) => [{
  key: 'enableGeofence',
  title: 'Enable Clock-In Geofencing',
  helpText: `Users must be within the ${t('Project').toLowerCase()}'s configured geofence
    region in order to clock in. Geofences can be configured when creating
    or editing a ${t('Project').toLowerCase()}`,
}, {
  key: 'enableClockOutGeofence',
  title: 'Enable Clock-Out Geofencing',
  helpText: `Users must be within the ${t('Project').toLowerCase()}'s configured geofence
    region in order to clock out. Geofences can be configured when creating
    or editing a ${t('Project').toLowerCase()}`,
}, {
  key: 'enableLocationStamps',
  title: 'Enable Location Stamps',
  helpText: `Captures a GPS location snapshot whenever a user clocks
    in and attaches it to their time card within the desktop app`,
  childKeys: ['passiveGeofence'],
}, {
  key: 'passiveGeofence',
  title: 'Enable Passive Clock-In Geofencing Mode',
  helpText: `Allows users to clock in when outside of the ${t('Project').toLowerCase()}'s geofence region.
    Requires Location Stamps to be enabled`,
  parentKeys: ['enableLocationStamps'],
  divider: false,
}];

export const payrollRows = [{
  key: 'payPeriod',
  title: 'Pay Period',
  helpText: `Recurring length of time over which employee time is recorded
  and paid`,
}, {
  key: 'payPeriodDates',
  title: 'Pay Period Dates',
  helpText: 'Recent closing date for payroll',
}];

export const semiMonthlyPayrollRows = [{
  key: 'payPeriod',
  title: 'Pay Period',
  helpText: `Recurring length of time over which employee time is recorded
  and paid`,
}, {
  key: 'semiMonthlyPayPeriodDates',
  title: 'Pay Period Dates',
  helpText: 'Semi-Monthly Payroll Days',
}];

export const defaultPayrollPeriods = ['Weekly', 'Biweekly', 'Semi-Monthly', 'Monthly'];

export const overtimeRows = [{
  key: 'weeklyOvertime',
  title: 'Weekly Overtime',
  helpText: 'Calculate weekly overtime',
  maxValue: 168,
}, {
  key: 'dailyOvertime',
  title: 'Daily Overtime',
  helpText: 'Calculate daily overtime',
  maxValue: 24,
}, {
  key: 'dailyDouble',
  title: 'Daily Double Time',
  helpText: 'Calculate daily double-pay overtime',
  maxValue: 24,
}, {
  key: 'saturdayPay',
  title: 'Saturday Pay Rate',
  helpText: `Configure Saturday pay rules. If other overtime rules have
  been configured, they will be applied on top of this setting`,
}, {
  key: 'saturdayOvertime',
  title: 'Saturday Overtime',
  helpText: `Configure if Saturday hours should be counted as regular 
  overtime or double-pay overtime`,
}, {
  key: 'sundayPay',
  title: 'Sunday Pay Rate',
  helpText: `Configure Sunday pay rules. If other overtime rules have
  been configured, they will be applied on top of this setting`,
}, {
  key: 'sundayOvertime',
  title: 'Sunday Overtime',
  helpText: `Configure if Sunday hours should be counted as regular 
  overtime or double-pay overtime`,
// }, {
//   key: 'seventhPay',
//   title: 'Seventh Day Overtime',
//   helpText: 'Enable overtime pay on the 7th day',
// }, {
//   key: 'seventhDoublePay',
//   title: 'Seventh Day Double Time',
//   helpText: 'Configure 7th day double-pay rules',
//   divider: false,
//   maxValue: 24,
}, {
  key: 'otEligibilitySelectionEnabled',
  title: 'Overtime Eligibility Selection',
  helpText: `If the clock exceeds the daily OT limit when clocking out,
   the user will be asked whether the time card is eligible for OT.
   If yes, the time remains untouched.
   If no, the time is automatically rounded down to the daily OT limit`,
  divider: false,
}];

export const getTimeCardRows = (t) => [{
  key: 'enableDigitalSignature',
  title: 'Enable Digital Signature',
  helpText: 'Users must digitally sign their time card submissions',
}, {
  key: 'enableAssignedSupervisorApproval',
  title: 'Enable Assigned Supervisor Approval',
  helpText: `If there are no ${t('Project').toLowerCase()} approvers, timecards will be submitted to team supervisors`,
}, {
  key: 'showOriginalTimes',
  title: 'Show Original Clock In/Clock Out Times',
  helpText: 'Enable this setting to show the original clock in/clock out times. Disable this setting to show the modified clock in/clock out times after automatic break, time rounding, and time distributing.',
}, {
  key: 'lockPayPeriodSubmissions',
  title: 'Lock Pay Period Submissions',
  helpText: 'With this enabled, non-admin users can submit time cards during the current pay period only',
}, {
  key: 'submissionDisclaimer',
  title: 'Time Card Submission Disclaimer',
  helpText: `Disclaimer that's acknowledged by users when they submit
  their time cards`,
  divider: false,
}];

export const autoBreakMinTimeOptions = [1, 2, 3, 4, 5, 6, 7, 8];
export const autoBreakDurationOptions = [15, 30, 45, 60];
export const autoBreakModeOptions = ['Subtract', 'Add'];
export const autoBreakPlacementOptions = ['Middle', 'End'];

export const autoBreakRows = [{
  key: 'autoBreakMode',
  title: 'Mode',
  helpText: 'If the automatic break should be in addtion to the time clocked, or subtracted from the shift in which the threshold was met.',
}, {
  key: 'autoBreakPlacement',
  title: 'Placement',
  helpText: 'Whether the break should be subtracted from the point the threshold was reached, or from the end of the shift',
}, {
  key: 'autoBreakMinTime',
  title: 'Minimum Time Threshold',
  helpText: 'The minimum amount of hours the user needs to clock in order to be eligible for the automatic break.',
}, {
  key: 'autoBreakDuration',
  title: 'Break Duration',
  helpText: 'The length of the automatic break that is added to the time card.',
}, {
  key: 'disableAutoBreakEditPrompt',
  title: 'Disable Auto Break Edit Prompt',
  helpText: 'Disable the auto break edit prompt that occurs when editing an entry which has an automatic break for the day',
  divider: false,
}];

function TimePickerInput({ value, onChange }) {
  return (
    <TimePicker
      use12Hours
      format="hh:mm a"
      onChange={onChange}
      value={value ? moment(`2000-01-01 ${value}`) : null}
      showNow={false}
    />
  );
}

TimePickerInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TimePickerInput.defaultProps = {
  value: null,
};

export const getTimeColumns = ({
  onEdit,
  onDelete,
  errors = {},
} = {}) => {
  const onTimesChanged = (record, key) => (momentInstance) => {
    onEdit({
      ...record,
      [key]: momentInstance?.format('HH:mm') ?? null,
    });
  };

  const {
    conflictErrorMap = {},
    badRowErrorMap = {},
  } = errors ?? {};

  const getTimeField = ({
    field,
    value,
    record,
    children,
  }) => {
    if (!onEdit) {
      return value
        ? DateTime.fromISO(value).toLocaleString(DateTime.TIME_SIMPLE)
        : '';
    }
    if (badRowErrorMap[record.id]?.[field]) {
      return (
        <Tooltip title={badRowErrorMap[record.id][field]}>
          <div>
            {children}
          </div>
        </Tooltip>
      );
    }

    return children;
  };

  const baseColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: onEdit ? 150 : null,
      render: (type, record) => {
        if (!onEdit) {
          return (
            <Tag color="blue">
              {type === 'clockin' ? 'Clock In' : 'Clock Out'}
            </Tag>
          );
        }

        return (
          <Select
            style={{ width: '100%' }}
            value={type}
            onChange={(value) => onEdit({ ...record, type: value })}
          >
            <Select.Option value="clockin">Clock In</Select.Option>
            <Select.Option value="clockout">Clock Out</Select.Option>
          </Select>
        );
      },
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (startTime, record) => (
        getTimeField({
          field: 'startTime',
          value: startTime,
          record,
          children: (
            <TimePickerInput
              value={startTime}
              onChange={onTimesChanged(record, 'startTime')}
            />
          ),
        })
      ),
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (endTime, record) => (
        getTimeField({
          field: 'endTime',
          value: endTime,
          record,
          children: (
            <TimePickerInput
              value={endTime}
              onChange={onTimesChanged(record, 'endTime')}
            />
          ),
        })
      ),
    },
    {
      title: 'Rounded Time',
      dataIndex: 'roundedTime',
      key: 'roundedTime',
      render: (roundedTime, record) => (
        getTimeField({
          field: 'roundedTime',
          value: roundedTime,
          record,
          children: (
            <TimePickerInput
              value={roundedTime}
              onChange={onTimesChanged(record, 'roundedTime')}
            />
          ),
        })
      ),
    },
  ];

  if (onEdit) {
    baseColumns.unshift({
      title: '',
      dataIndex: 'validation',
      key: 'validation',
      width: 15,
      render: (_, record) => {
        const rowErrors = [];

        if (conflictErrorMap[record.id]) {
          rowErrors.push(...conflictErrorMap[record.id]);
        }

        if (badRowErrorMap[record.id]) {
          rowErrors.push(...Object.values(badRowErrorMap[record.id]));
        }

        if (rowErrors.length) {
          const errorContainer = (
            <ul>
              {rowErrors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          );

          return (
            <Popover content={errorContainer}>
              <div>
                <WarningOutlined style={{ color: Colors.ONTRACCR_RED }} />
              </div>
            </Popover>
          );
        }

        return (
          <Tooltip title="No errors">
            <CheckCircleOutlined style={{ color: 'green' }} />
          </Tooltip>
        );
      },
    });
  }

  if (onDelete) {
    baseColumns.push({
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      width: 15,
      render: (_, record) => (
        <BorderlessButton
          title=""
          iconNode={<DeleteOutlined style={{ color: Colors.ONTRACCR_RED }} />}
          onClick={() => onDelete(record)}
        />
      ),
    });
  }

  return baseColumns;
};
