import React from 'react';
import { Row, Col, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import HoverHelp from '../../../common/HoverHelp';
import OnTraccrNumberInput from '../../../common/inputs/OnTraccrNumberInput';

function CharacterLimitRow({
  checked,
  title,
  onChange,
  hoverText,
  disabled,
  configProps,
  setConfigProps,
}) {
  return (
    <Row style={{ marginTop: 10, display: 'flex', alignItems: 'center' }} gutter={10}>
      <Col>
        <Checkbox
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        >
          {title}
        </Checkbox>
      </Col>
      {!!hoverText && (
      <Col>
        <HoverHelp
          placement="topRight"
          content={(
            <div style={{ width: 250 }}>
              {hoverText}
            </div>
          )}
        />
      </Col>
      )}
      {configProps?.isCharacterLimit && (
      <Col>
        <OnTraccrNumberInput
          defaultValue={1}
          value={configProps?.isCharacterLimit ? configProps.characterLimit : 1}
          onChange={(e) => {
            setConfigProps({
              ...configProps,
              characterLimit: e,
            });
          }}
          min={1}
        />
      </Col>
      )}
    </Row>
  );
}

CharacterLimitRow.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hoverText: PropTypes.node,
  disabled: PropTypes.bool,
  configProps: PropTypes.shape({
    characterLimit: PropTypes.number,
    isCharacterLimit: PropTypes.bool,
  }),
  setConfigProps: PropTypes.func,
};

CharacterLimitRow.defaultProps = {
  checked: false,
  hoverText: null,
  disabled: false,
  configProps: {},
  setConfigProps: () => null,
};

export default CharacterLimitRow;
