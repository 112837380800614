export default {};

export const defaultHeaders = [
  {
    key: 'title', title: 'Title', type: 'text', required: true,
  },
  {
    key: 'divisionId', title: 'Division', type: 'select', dataType: 'divisions', required: true,
  },
  {
    key: 'startTime', title: 'Start Time', type: 'date', required: true,
  },
  {
    key: 'endTime', title: 'End Time', type: 'date', required: true,
  },
  {
    key: 'projectId', title: 'Project', type: 'select', dataType: 'projects',
  },
  {
    key: 'phaseId', title: 'Phase', type: 'select', dataType: 'phases',
  },
  {
    key: 'costcodeId', title: 'Cost Code', type: 'select', dataType: 'costcodes',
  },
  {
    key: 'teams', title: 'Teams', type: 'select', dataType: 'teams', multiple: true,
  },
  { key: 'color', title: 'Color', type: 'color' },
  {
    key: 'users', title: 'Users', type: 'select', dataType: 'users', multiple: true,
  },
  { key: 'description', title: 'Description', type: 'text' },
  {
    key: 'expectedNumberOfUsers', title: 'Expected Users', type: 'number',
  },

];
