import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

export default function AssemblyMaterialSelector({
  value,
  record,
  onSelect: onMaterialSelect,
  options,
  selectedMaterialSet,
}) {
  const onSelect = useCallback((selectedId) => {
    onMaterialSelect(record.id, { materialId: selectedId });
  }, [onMaterialSelect, record]);

  const materialOptions = useMemo(() => (
    options.map((material) => ({
      label: material.name,
      value: material.id,
    })).filter((material) => !selectedMaterialSet.has(material.value) || material.value === value)
  ), [options]);

  return (
    <Select
      key={record.id}
      value={value}
      onSelect={onSelect}
      showSearch
      optionFilterProp="label"
      options={materialOptions}
      placeholder="Select a Material"
      style={{ paddingLeft: 20 }}
    />
  );
}

AssemblyMaterialSelector.propTypes = {
  value: PropTypes.string,
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectedMaterialSet: PropTypes.instanceOf(Set),
};

AssemblyMaterialSelector.defaultProps = {
  value: undefined,
  selectedMaterialSet: new Set(),
};
