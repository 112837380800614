import moment from 'moment';
import { DateTime } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';

import { toTitleCase } from './helpers';

const runtimeFromTask = function runtimeFromTask({
  startTime,
  endTime = moment(),
}) {
  const ourEnd = endTime || moment();
  return moment(ourEnd.diff(startTime)).utc().format('HH:mm:ss');
};

const getBreakDuration = function getBreakDuration(ourMoment) {
  const hours = ourMoment.get('hour');
  const mins = ourMoment.get('minute');
  return (hours * 60 * 60 + mins * 60) * 1000;
};

const dateIsToday = (date) => {
  if (!date || !moment.isMoment(date)) return false;
  const now = moment();
  return date.dayOfYear() === now.dayOfYear() && date.year() === now.year();
};

const msToHours = (ms) => parseFloat(ms / (1000 * 60 * 60));

const timeIsBetween = (task, start, end) => {
  const { endTime, timezone } = task;
  const taskMoment = moment(endTime).tz(timezone ?? DateTime.local().zoneName);
  return (
    taskMoment.year() > start.year()
    || (taskMoment.year() === start.year() && taskMoment.dayOfYear() >= start.dayOfYear())
  ) && (
    taskMoment.year() < end.year()
    || (taskMoment.year() === end.year() && taskMoment.dayOfYear() <= end.dayOfYear())
  );
};

const getLuxonRuntimeFromTask = (task, { format = 'hh:mm:ss', rawMs = false } = {}) => {
  const {
    startTime,
    startTimeStamp,
    endTime,
    endTimeStamp,
  } = task;

  const start = (
    moment.isMoment(startTime)
      ? startTimeStamp
      : startTime
  ) || DateTime.local().toMillis();
  const end = (moment.isMoment(endTime) ? endTimeStamp : endTime) || DateTime.local().toMillis();

  const startLuxon = DateTime.fromMillis(start);
  const endLuxon = DateTime.fromMillis(end);
  const runtime = endLuxon.diff(startLuxon);

  return rawMs ? runtime : runtime.toFormat(format);
};
const getTaskTimeMoment = (key, task, defaultValue = moment()) => {
  const {
    [`${key}Time`]: time,
    [`${key}TimeStamp`]: timeStamp,
  } = task;
  if (moment.isMoment(time)) return time;
  if (DateTime.isDateTime(time)) return moment(time.toMillis());
  if (Number.isInteger(time)) return moment(time);
  if (timeStamp
      && Number.isInteger(timeStamp)) return moment(timeStamp);
  return defaultValue;
};

const getTaskStartTimeMoment = (task, defaultValue = moment()) => getTaskTimeMoment('start', task, defaultValue);

const getTaskEndTimeMoment = (task, defaultValue = moment()) => getTaskTimeMoment('end', task, defaultValue);

const roundTotalRuntime = (runtime, roundingInterval, roundingType, roundingSetting = 'automatic') => {
  if (!runtime) return 0;
  if (!roundingInterval || roundingType === 'taskTime' || !roundingSetting) return runtime;
  return TaskHelpers.getRoundedRuntime(
    runtime,
    { roundingInterval, roundingSetting },
  );
};

const getRangeFormat = (allDay) => (allDay ? 'MMM Do YY' : 'MMM Do YY hh:mm a');
const formatDay = (startTime) => (startTime
  ? DateTime.fromMillis(startTime).toLocaleString(DateTime.DATE_MED)
  : null);
const formatTime = (t, format) => DateTime.fromMillis(t).toLocaleString(format);
const formatRange = ([t1, t2], format) => (t1 && t2
  ? `${formatTime(t1, format)} - ${formatTime(t2, format)}`
  : null);

const getAllDayValues = (startDT, endDT) => {
  if (!startDT || !endDT) return { isAllDay: false, isMultiDay: false };
  const endOfEndDay = endDT.endOf('day');
  return {
    isAllDay: endDT.equals(endOfEndDay),
    isMultiDay: !endDT.hasSame(startDT, 'day'),
  };
};

const nth = (n) => ['st', 'nd', 'rd'][((n + 90) % 100 - 10) % 10 - 1] || 'th';
const weekText = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
const getWeekNum = (startDT) => {
  let targetWeekNum = 0;
  let monthStart = startDT.startOf('month');
  while (monthStart.day !== startDT.day) {
    if (monthStart.weekday === startDT.weekday) targetWeekNum += 1;
    monthStart = monthStart.plus({ day: 1 });
  }
  return targetWeekNum;
};
const getRepeatText = ({ mode, datetime, isTemplate, startDate, endDate }) => {
  const fromStr = startDate ? ` from ${startDate.toLocaleString(DateTime.DATE_MED)}` : '';
  const untilStr = endDate ? ` until ${endDate.toLocaleString(DateTime.DATE_MED)}` : '';
  const dateStr = `${fromStr}${untilStr}`;
  switch (mode) {
    case null: return 'Doesn\'t repeat';
    case 'daily': return `Daily${dateStr}`;
    case 'weekly':
    case 'biweekly': {
      const templateStr = 'the same day of the week (eg: Monday)';
      const weekdayStr = datetime.weekdayLong;
      return `${toTitleCase(mode)} on ${isTemplate ? templateStr : weekdayStr}${dateStr}`;
    }
    case 'monthlyNumber': {
      const templateStr = 'the same day of the month (eg: the 15th)';
      const monthlyNStr = `the ${datetime.day}${nth(datetime.day)} day`;
      return `Monthly on ${isTemplate ? templateStr : monthlyNStr}${dateStr}`;
    }
    case 'monthlyDay': {
      const templateStr = 'the same number week and day of the week (eg: 1st Monday)';
      const weekNum = getWeekNum(datetime);
      const weekDayStr = `the ${weekText[weekNum]} ${datetime.weekdayLong}`;
      return `Monthly on ${isTemplate ? templateStr : weekDayStr}${dateStr}`;
    }
    case 'weekdays': return 'Every Weekday';
    case 'annually': {
      const templateStr = 'the same date';
      const annualStr = `${datetime.monthLong} ${datetime.day}${nth(datetime.day)}`;
      return `Annually on ${isTemplate ? templateStr : annualStr}${dateStr}`;
    }
    default:
      return null;
  }
};

export {
  runtimeFromTask,
  getBreakDuration,
  dateIsToday,
  msToHours,
  timeIsBetween,
  getLuxonRuntimeFromTask,
  getTaskStartTimeMoment,
  getTaskEndTimeMoment,
  roundTotalRuntime,
  getRangeFormat,
  formatDay,
  formatTime,
  formatRange,
  getAllDayValues,
  getRepeatText,
  nth,
};
