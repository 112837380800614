import React, { useState } from 'react';
import {
  Row,
  Checkbox,
  Col,
  notification,
  Select
} from 'antd';

import CheckRows from '../CheckRows';
import {
  overtimeRows,
} from './timeTrackingConstants'; 
import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import DisplayText from '../../common/text/DisplayText';

const afterHoursInput = ({ disabled, onChange, defaultValue, maxValue }) => (
  <Row gutter={4} align='middle' style={{marginTop:10, }}>
    <Col>
      <DisplayText title='after' style={{marginBottom: 0, opacity: disabled ? 0.4 : 1 }}/>
    </Col>
    <Col>
      <OnTraccrNumberInput
        style={{ width: 65 }}
        disabled={disabled}
        onChange={(value) => onChange(value)}
        onPressEnter={(e) => onChange(e.target.value)}
        defaultValue={defaultValue}
        min={0}
        max={maxValue}
      />
    </Col>
    <Col>
      <DisplayText title='hours' style={{marginBottom: 0, opacity: disabled ? 0.4 : 1 }}/>
    </Col>
  </Row>
);
const wageInput = ({ disabled, onChange, defaultValue }) => (
  <Row gutter={4} align='middle' style={{marginTop:10}}>
    <Col>
      <DisplayText title='wage x' style={{marginBottom: 0, opacity: disabled ? 0.4 : 1 }}/>
    </Col>
    <Col>
      <OnTraccrNumberInput
        style={{ width: 65 }}
        disabled={disabled}
        onChange={(value) => onChange(value)}
        onPressEnter={(e) => onChange(e.target.value)}
        defaultValue={defaultValue}
        min={0}
        max={99}
        step={0.25}
      />
    </Col>
  </Row>
);

const options = [
  { label: 'Overtime', value: 'overtime' },
  { label: 'Double Time', value: 'double' },
];

const overtimeInput = ({ disabled, onChange, defaultValue = 'overtime' }) => (
  <Row gutter={4} align="middle" style={{ marginTop: 10 }}>
    <Col>
      <Select
        value={defaultValue}
        options={options}
        disabled={disabled}
        onChange={onChange}
        style={{ width: 125 }}
      />
    </Col>
  </Row>
);

const secondRowInputs = [
  afterHoursInput, // Weekly Overtime
  afterHoursInput, // Daily Overtime
  afterHoursInput, // Daily Double Time
  wageInput, // Saturday Pay Rate
  overtimeInput, // Saturday Overtime
  wageInput, // Sunday Pay Rate
  overtimeInput, // Sunday Overtime
  () => null, // Overtime Eligibility Selection
];
const timers = {};

export default ({ settings, saveCompanySettings }) => {
  const [settingsEnabled,setSettingsEnabled] = useState({});
  const [warningShown, setWarningShown] = useState(false);
  if (!warningShown) {
    notification.warning({
      key: 'warning',
      message: 'Caution',
      description:
        `Changing overtime settings recalculates overtime retroactively, affecting all exisiting timecards`,
      duration: 8,
    });
    setWarningShown(true);
  }
  return (
    <CheckRows
      data={overtimeRows}
      getView={(index) => {
        const row = overtimeRows[index];
        const setting = settings[row.key];
        const enabled = (
          (setting && setting > 0)
          || (setting && (setting === 'overtime' || setting === 'double'))
          || settingsEnabled[row.key]
        )
        const disableOTEligibility = row.key === 'otEligibilitySelectionEnabled' && !settings.dailyOvertime;
        return (
          <Row justify='end' style={{ marginRight:56 }}>
            <Col>
              <Row justify='end'>
                <Checkbox
                  checked={enabled && !disableOTEligibility}
                  disabled={
                    disableOTEligibility
                  }
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked && (row.key === 'saturdayOvertime' || row.key === 'sundayOvertime')) {
                      return saveCompanySettings({
                        [row.key]: 'overtime',
                      });
                    }
                    if(row.key === 'seventhPay' || row.key === 'otEligibilitySelectionEnabled') {
                      return saveCompanySettings({
                        [row.key]: checked,
                      });
                    }
                    if(!checked) {
                      saveCompanySettings({
                        [row.key]:null,
                      });
                      if(row.key === 'dailyOvertime') {
                        saveCompanySettings({
                          otEligibilitySelectionEnabled:false,
                        });
                      }
                    } else {
                      setSettingsEnabled({
                        [row.key]:true,
                      });
                      if (row.key !== 'saturdayOvertime' && row.key !== 'sundayOvertime') {
                        timers[row.key] = setTimeout(() => {
                          setSettingsEnabled({
                            [row.key]:false,
                          });
                        }, 5000);
                      }
                    }
                  }}
                />
              </Row>

            {secondRowInputs[index]({
              maxValue:row.maxValue,
              disabled: !enabled,
              onChange: (value) => {
                if(row.key in timers) {
                  clearTimeout(timers[row.key]);
                  timers[row.key] = undefined;
                }
                timers[row.key] = setTimeout(() => {
                  const obj = {
                    [row.key]: value,
                  };
                  saveCompanySettings(obj);
                },1500);
              },
              defaultValue:setting,
            })}
            </Col>
          </Row>
        )
      }}
    />
  );
}