import React, {
  useCallback, useState, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Select,
} from 'antd';
import {
  PlusOutlined,
  LockOutlined,
  UnlockOutlined,
  SortAscendingOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  FilterFilled,
  FilterOutlined,
} from '@ant-design/icons';

import { PropTypes } from 'prop-types';

import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import BoardSearchDropdown from './BoardSearchDropdown';
import BoardSettings from './BoardSettings';
import BoardLockModal from './BoardLockModal';
import BoardReorderModal from './BoardReorderModal';

import { VIEW_TYPES, ganttViewTypes, ganttZoomScales } from './boards.constants';
import BoardFilterDrawer from './BoardFilterDrawer';

import {
  switchViewType,
  toggleBoardLock,
  openCardDrawer,
  switchGanttViewType,
  switchGanttZoomScale,
} from './state/boards.actions';
import { toggleMenuCollapse } from '../main/state/main.actions';

import { convertUpperCamelCaseToText } from '../helpers/helpers';
import Debouncer from '../helpers/Debouncer';
import Permissions from '../auth/Permissions';
import useToggle from '../common/hooks/useToggle';
import useFilteredBoardList from '../common/hooks/useFilteredBoardList';

const fsDebouncer = new Debouncer();
export default function BoardHeader({
  selectedBoard,
  useCardNumberAsCardTitle,
  onBoardSelect,
  largeBoardMode,
  canCreate,
}) {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.boards.filters);
  const sortType = useSelector((state) => state.boards.sortType.type);
  const viewType = useSelector((state) => state.boards.viewType);
  const isFullScreen = useSelector((state) => state.main.menuCollapsed);

  const ganttViewType = useSelector((state) => state.boards.ganttViewType);
  const ganttZoomScale = useSelector((state) => state.boards.ganttZoomScale);

  const hasWritePerms = Permissions.has('BOARDS_WRITE');
  const FullScreenIcon = isFullScreen ? FullscreenExitOutlined : FullscreenOutlined;
  const {
    id,
    title,
    isLocked,
    isPublic,
    creatorId,
    cardTypeId,
    pin: hasPin,
    disableCrossColumnSorting,
    showCardNumber,
    showListIndex,
    cardNumberAnchor,
    shouldSendStatusChangeEmail,
    shouldSendCardUpdateEmail,
    shouldSendAssignedUsersChangeEmail,
    availableOffline,
    timelineDisplayFields,
    divisions = [],
  } = selectedBoard || {};

  const viewTypeOptions = Object.entries(VIEW_TYPES)
    .map(([key, value]) => {
      const label = convertUpperCamelCaseToText(key);
      return {
        label,
        value,
      };
    });

  const [showModal, setShowModal] = useState(false);
  const [showReorder, setShowReorder] = useState(false);

  const {
    toggle: toggleFilterDrawer,
    isToggled: showFilterDrawer,
  } = useToggle();

  const toggleLockedStatus = useCallback(async (pin) => {
    if (await dispatch(toggleBoardLock(id, !isLocked, pin))) {
      setShowModal(false);
    }
  }, [dispatch, id, isLocked]);

  const closeModal = useCallback(() => setShowModal(false), []);
  const openModal = useCallback(() => {
    if (hasPin) {
      setShowModal(true);
    } else {
      toggleLockedStatus();
    }
  }, [toggleLockedStatus, hasPin]);

  const openReorder = useCallback(() => setShowReorder(true), []);
  const hideReorder = useCallback(() => setShowReorder(false), []);

  const onViewTypeChanged = useCallback((value) => {
    dispatch(switchViewType(value));
  }, [dispatch]);

  const onGanttViewTypeChanged = useCallback((value) => {
    dispatch(switchGanttViewType(value));
  }, [dispatch]);

  const onGanttZoomScaleChanged = useCallback((value) => {
    dispatch(switchGanttZoomScale(value));
  }, [dispatch]);

  const onToggleFullscreen = useCallback(async () => {
    await fsDebouncer.debounce(() => {
      dispatch(toggleMenuCollapse());
    }, 1000);
  }, [dispatch]);

  const onAddCardClicked = useCallback(() => {
    dispatch(openCardDrawer({}));
  }, [dispatch]);

  const boardList = useFilteredBoardList({ asOptions: true });

  useEffect(() => {
    if (largeBoardMode && viewType !== 'list') {
      dispatch(switchViewType('list'));
    }
  }, [dispatch, largeBoardMode, viewType]);

  useEffect(() => {
    if (largeBoardMode && isFullScreen) {
      dispatch(toggleMenuCollapse());
    }
  }, [dispatch, largeBoardMode, isFullScreen]);

  let rightSpan = 17;
  if (isFullScreen) {
    rightSpan = 12;
  } else if (hasWritePerms && largeBoardMode) {
    rightSpan = 15;
  }

  return (
    <>
      <Row gutter={14} justify="start">
        <Col span={5}>
          <BoardSearchDropdown onBoardSelect={onBoardSelect} />
        </Col>
        {canCreate && (
        <Col span={2}>
          <OnTraccrButton
            title="Add Card"
            icon={<PlusOutlined />}
            onClick={onAddCardClicked}
          />
        </Col>
        )}
        {
          isFullScreen
          && (
          <Col span={5}>
            <Select
              options={boardList}
              optionFilterProp="label"
              showSearch
              allowClear={false}
              onSelect={onBoardSelect}
              style={{ width: '100%' }}
              value={id}
            />
          </Col>
          )
        }
        <Col span={rightSpan}>
          <Row justify="end" gutter={12} align="middle">
            <Col>
              <OnTraccrButton
                title=""
                onClick={toggleFilterDrawer}
                icon={(
                  filters
                    ? <FilterFilled style={{ marginLeft: 0 }} />
                    : <FilterOutlined style={{ marginLeft: 0 }} />
                )}
              />
            </Col>
            {
              viewType === 'timeline' && (
                <>
                  <Col>
                    <Select
                      defaultValue={ganttViewType}
                      options={ganttViewTypes}
                      style={{
                        width: '6em',
                      }}
                      onChange={onGanttViewTypeChanged}
                    />
                  </Col>

                  <Col>
                    <Select
                      defaultValue={ganttZoomScale}
                      options={ganttZoomScales}
                      style={{
                        width: '5.5em',
                      }}
                      onChange={onGanttZoomScaleChanged}
                    />
                  </Col>
                </>
              )
            }

            <Col>
              <Select
                defaultValue={viewType}
                options={viewTypeOptions}
                style={{
                  width: '10em',
                }}
                onChange={onViewTypeChanged}
                disabled={largeBoardMode}
              />
            </Col>
            <Col>
              <BorderlessButton
                style={{ width: 30, backgroundColor: 'transparent', padding: 0 }}
                iconNode={<FullScreenIcon style={{ fontSize: 30, marginLeft: 0 }} />}
                onClick={onToggleFullscreen}
                disabled={largeBoardMode}
              />
            </Col>
            {id && hasWritePerms
              ? (
                <>
                  <Col>
                    <OnTraccrButton
                      title={isLocked ? 'Unlock' : 'Lock'}
                      icon={isLocked ? <UnlockOutlined /> : <LockOutlined />}
                      onClick={openModal}
                    />
                  </Col>
                  <Col>
                    <OnTraccrButton
                      title="Sort"
                      icon={<SortAscendingOutlined />}
                      type={sortType === 'default' ? 'cancel' : 'back'}
                      onClick={openReorder}
                      disabled={largeBoardMode}
                    />
                  </Col>
                  <Col>
                    <BoardSettings
                      boardId={id}
                      title={title}
                      isPublic={!!isPublic}
                      creatorId={creatorId}
                      hasPin={hasPin}
                      cardTypeId={cardTypeId}
                      disableCrossColumnSorting={!!disableCrossColumnSorting}
                      useCardNumberAsCardTitle={!!useCardNumberAsCardTitle}
                      showCardNumber={!!showCardNumber}
                      showListIndex={!!showListIndex}
                      cardNumberAnchor={cardNumberAnchor}
                      shouldSendStatusChangeEmail={!!shouldSendStatusChangeEmail}
                      shouldSendCardUpdateEmail={!!shouldSendCardUpdateEmail}
                      shouldSendAssignedUsersChangeEmail={!!shouldSendAssignedUsersChangeEmail}
                      availableOffline={!!availableOffline}
                      timelineDisplayFields={timelineDisplayFields}
                      divisions={divisions}
                    />
                  </Col>
                </>
              ) : null}
          </Row>
        </Col>
      </Row>
      <BoardLockModal
        visible={showModal}
        onClose={closeModal}
        onSubmit={toggleLockedStatus}
        isLocked={isLocked}
        title={title}
      />
      <BoardReorderModal
        visible={showReorder}
        onClose={hideReorder}
        cardTypeId={cardTypeId}
        isSorted={sortType !== 'default'}
        useCardNumberAsCardTitle={!!useCardNumberAsCardTitle}
      />
      <BoardFilterDrawer
        onClose={toggleFilterDrawer}
        visible={showFilterDrawer}
        selectedBoard={selectedBoard}
      />
    </>
  );
}

BoardHeader.propTypes = {
  selectedBoard: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  useCardNumberAsCardTitle: PropTypes.number,
  onBoardSelect: PropTypes.func.isRequired,
  largeBoardMode: PropTypes.bool,
  canCreate: PropTypes.bool,
};

BoardHeader.defaultProps = {
  useCardNumberAsCardTitle: false,
  largeBoardMode: false,
  canCreate: false,
};
