import React, { useCallback } from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import CompanySettingsCard from './CompanySettingsCard';
import CompanyEditRow from './CompanyEditRow';
import RolePermissonRow from './RolePermissionRow';
import {
  defaultRoles,
  getPermissionRows,
} from './permissionConstants';
import SettingsCardHeader from './SettingsCardHeader';

import Editable from '../common/editable/Editable';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

const getCheckRows = (t, roleName, onCheckChanged, settings) => {
  const {
    autoBreakEnabled = false,
  } = settings;

  const permissionRows = getPermissionRows(t);

  return permissionRows.filter(({ key }) => (
    key !== 'AUTOMATIC_BREAKS' || autoBreakEnabled
  )).map((permission) => (
    <RolePermissonRow
      key={permission?.key}
      roleName={roleName}
      permission={permission}
      onCheckChanged={onCheckChanged}
    />
  ));
};

export default function RolePermissionCard({
  roleName,
  settings = {},
  onCheckChanged,
  onNameChanged,
  resetPermissions,
  onDelete,
}) {
  const { t } = useTranslation();

  const InputNode = useCallback(({
    setReturnValue,
    setFocus,
    setHover,
    defaultValue,
  }) => (
    <OnTraccrTextInput
      style={{ textAlign: 'right', width: 300 }}
      defaultValue={defaultValue}
      placeholder="Enter details"
      /*
        onBlur needs a timeout due to a race condition.
        If user clicks checkmark, onBlur will fire, as will onClick for
        the check. These will race, causing the input to not be saved
        if onBlur executes first. We delay by 100ms to allow
        onClick check to execute first.
      */
      onBlur={() => setTimeout(() => setFocus(false), 100)}
      onMouseLeave={() => setHover(false)}
      onChange={(e) => {
        const {
          target: {
            value,
          },
        } = e;
        if (value.length === 0) return;
        setReturnValue({ name: 'roleName', value: value.trim() });
      }}
      onClick={() => setFocus(true)}
      onPressEnter={(e) => {
        const {
          target: {
            value,
          },
        } = e;
        setFocus(false);
        if (value.length === 0) return;
        setReturnValue({ name: 'roleName', value: value.trim() });
        onNameChanged('name', value.trim());
      }}
    />
  ), []);

  return (
    <Row
      style={{
        width: '100%',
        height: '100%',
        padding: 20,
        overflow: 'scroll',
        maxHeight: '87vh',
      }}
    >
      <SettingsCardHeader
        title={roleName}
        subtitle=": Settings and Permissions"
      >
        {defaultRoles.has(roleName) ? (
          <OnTraccrButton
            title="Reset to Default"
            type="cancel"
            onClick={resetPermissions}
          />
        ) : (
          <OnTraccrButton
            title="Delete"
            type="primary"
            onClick={onDelete}
          />
        )}
      </SettingsCardHeader>
      <Row style={{ width: '100%', height: '100%' }}>
        <CompanySettingsCard title="">
          <CompanyEditRow title="Role Title">
            <Editable
              key={`roleName-${roleName}`}
              name="roleName"
              defaultValue={roleName}
              style={{ marginBottom: 0 }}
              onChange={(_, value) => {
                if (value.length === 0) return;
                onNameChanged('name', value);
              }}
              editable={!defaultRoles.has(roleName)}
              inputNode={InputNode}
            />
          </CompanyEditRow>
          {getCheckRows(t, roleName, onCheckChanged, settings)}
        </CompanySettingsCard>
      </Row>
      <Row style={{ height: 20, width: '100%' }} />
    </Row>
  );
}
