import { TaskHelpers } from 'ontraccr-common';
import {
  hoursToMs,
} from './otHelpers';

export default ({
  day = {},
  dailyOvertime,
  dailyDouble,
  saturdayOvertime,
  sundayOvertime,
  enableManualOT,
} = {}) => {
  if (
    (
      !dailyOvertime
      && !dailyDouble
      && (!saturdayOvertime || !day.isSaturday)
      && (!sundayOvertime || !day.isSunday)
    ) || enableManualOT
  ) {
    return day;
  }
  const dailyOTMS = hoursToMs(dailyOvertime || dailyDouble || 0);
  const doubleOTMS = hoursToMs(dailyDouble || 0);
  const {
    tasks = [],
  } = day;
  const currentDay = {
    tasks: [],
    regularTime: 0,
    overtime: 0,
    doubleOT: 0,
    isSaturday: day.isSaturday,
    isSunday: day.isSunday,
  };
  tasks.forEach((task) => {
    const runtimes = TaskHelpers.getRuntimes(task, false);
    const ourTask = {
      ...task,
      ...runtimes,
    };

    if (task.type === 'break') {
      currentDay.tasks.push(ourTask);
      return;
    }

    let weekendOT;
    if ((day.isSaturday && saturdayOvertime === 'double') || (day.isSunday && sundayOvertime === 'double')) {
      ourTask.doubleOT += ourTask.regularTime;
      ourTask.regularTime = 0;
      ourTask.doubleOT += ourTask.overtime;
      ourTask.overtime = 0;
      weekendOT = true;
    }
    if ((day.isSaturday && saturdayOvertime === 'overtime') || (day.isSunday && sundayOvertime === 'overtime')) {
      ourTask.overtime += ourTask.regularTime;
      ourTask.regularTime = 0;
      ourTask.overtime += ourTask.doubleOT;
      ourTask.doubleOT = 0;
      weekendOT = true;
    }

    currentDay.overtime += ourTask.overtime;
    currentDay.doubleOT += ourTask.doubleOT;

    if (weekendOT) {
      currentDay.tasks.push(ourTask);
      return;
    }

    const roomLeftInRegHours = Math.min(runtimes.regularTime, dailyOTMS - currentDay.regularTime);
    ourTask.regularTime = roomLeftInRegHours;
    currentDay.regularTime += roomLeftInRegHours;
    runtimes.regularTime -= roomLeftInRegHours;
    if (runtimes.regularTime === 0) {
      currentDay.tasks.push(ourTask);
      return;
    }

    if (dailyOvertime) {
      const upperLimit = dailyDouble ? doubleOTMS : Number.MAX_SAFE_INTEGER;
      const OTRoom = Math.min(runtimes.regularTime, upperLimit - dailyOTMS - currentDay.overtime);

      ourTask.overtime += OTRoom;
      currentDay.overtime += OTRoom;
      runtimes.regularTime -= OTRoom;

      if (runtimes.regularTime === 0) {
        currentDay.tasks.push(ourTask);
        return;
      }
    }

    ourTask.doubleOT += runtimes.regularTime;
    currentDay.doubleOT += runtimes.regularTime;
    currentDay.tasks.push(ourTask);
  });
  return currentDay;
};
