import React from 'react';
import PropTypes from 'prop-types';

import CheckRows from '../../CheckRows';

import { SETTINGS_TEXT } from './quickbooks.constants';

function QuickBooksSyncTypes({
  type,
  disabled,
  selectedIntegration,
  onIntegrationUpdate,
  divider,
}) {
  const showDivider = divider && type !== 'pull';
  let subRows = [
    {
      key: `${type}Materials`,
      helpText: SETTINGS_TEXT[`${type}Materials`],
      divider: showDivider,
      style: { marginLeft: 20 },
    },
  ];
  if (type === 'pull') {
    subRows = subRows.concat([{
      key: `${type}Invoices`,
      helpText: SETTINGS_TEXT[`${type}Invoices`],
      divider: showDivider,
      style: { marginLeft: 20 },
    }, {
      key: 'ignoreInvoiceNoProject',
      helpText: SETTINGS_TEXT.ignoreInvoiceNoProject,
      divider: showDivider,
      style: { marginLeft: 40 },
    }, {
      key: 'ignoreInvoiceNoProjectMatch',
      helpText: SETTINGS_TEXT.ignoreInvoiceNoProjectMatch,
      divider,
      style: { marginLeft: 40 },
    }]);
  }
  return (
    <CheckRows
      isChecked={({ key }) => selectedIntegration?.[key]}
      onChange={({ key }) => onIntegrationUpdate({
        [key]: !selectedIntegration?.[key],
      })}
      checkBoxDisabled={(row) => (
        disabled
        || (
          (row?.key === 'ignoreInvoiceNoProject' || row?.key === 'ignoreInvoiceNoProjectMatch')
          && type === 'pull'
          && !selectedIntegration?.pullInvoices
        )
      )}
      data={subRows}
    />
  );
}

QuickBooksSyncTypes.propTypes = {
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultDivisionId: PropTypes.string,
    defaultTaxCode: PropTypes.string,
    defaultPurchaseAccount: PropTypes.string,
    pullEnabled: PropTypes.bool,
    pushEnabled: PropTypes.bool,
    divisions: PropTypes.arrayOf(PropTypes.string),
    pullInvoices: PropTypes.bool,
  }),
  onIntegrationUpdate: PropTypes.func.isRequired,
  divider: PropTypes.bool,
};

QuickBooksSyncTypes.defaultProps = {
  selectedIntegration: null,
  divider: true,
};

export default QuickBooksSyncTypes;
