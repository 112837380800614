import React, { useCallback } from 'react';
import {
  Row,
  Col,
} from 'antd';

import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import DisplayText from '../../common/text/DisplayText';
import CompanyEditRow from '../CompanyEditRow';

import Debouncer from '../../helpers/Debouncer';

const debouncer = new Debouncer();
export default ({
  settings,
  saveCompanySettings,
}) => {
  const {
    manualOTModifier = 1.5
  } = settings;

  const onChange = useCallback((isEnter) => async (e) => {
    const val = isEnter ? e.target.value : e;
    await debouncer.debounce(() => {
      saveCompanySettings({
        manualOTModifier: val,
      })
    }, 1000);
  },[saveCompanySettings]);
  return (
    <CompanyEditRow
      title='Overtime Pay Rate'
      divider={false}
    >
      <Row gutter={4} align='middle' style={{ marginTop:10 }}>
        <Col>
          <DisplayText title='wage x' style={{ marginBottom: 0 }}/>
        </Col>
        <Col>
          <OnTraccrNumberInput
            style={{ width: 65 }}
            formatter={(value) => Number.isNaN(Number(value)) ? 0 : value}
            onChange={onChange(false)}
            onPressEnter={onChange(true)}
            defaultValue={manualOTModifier}
            min={0}
            max={99}
            step={0.25}
          />
        </Col>
      </Row>
    </CompanyEditRow>
  );
}