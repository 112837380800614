import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import UserAssignmentSelector from './UserAssignmentSelector';

import config from '../../../config';

// eslint-disable-next-line no-useless-escape
const emailRegex = /^[^@\s]+@[^@\s\.]+(?:\.[^@\.\s]+)+$/;

const tokenSeparators = [',', '\n'];

const FORM_AUTHOR_KEY = 'formAuthor';
const AUTHOR_HELP = 'The form will be sent to the form\'s author';

const COMPANY_OWNER_KEY = 'companyOwner';
const OWNER_HELP = 'The form will be sent to the company\'s owner';

export default function EmailRecipientSelector({
  sections,
  initialSelected,
  divisionId,
  onChange,
  additionalOptions,
  text = 'Select Recipient(s):',
  mode = 'tags',
  showFormAuthor,
  authorHelpText = AUTHOR_HELP,
  showCompanyOwner,
  ownerHelpText = OWNER_HELP,
}) {
  const [selected, setSelected] = useState(initialSelected || []);
  const [filtered, setFiltered] = useState(false);

  const divisions = useSelector((state) => state.settings.divisions);
  const users = useSelector((state) => state.users.users);

  const userIds = useMemo(() => {
    const idSet = new Set();
    users.forEach((user) => {
      if (user.active) {
        idSet.add(user.id);
      }
    });
    return idSet;
  }, [users]);

  const additionalIds = useMemo(() => new Set(
    additionalOptions.map((addOpt) => addOpt.id),
  ), [additionalOptions]);

  const validFields = useMemo(() => {
    const userFieldIds = new Set();
    sections.forEach(({ fields = [] }) => {
      fields.forEach((field) => {
        const {
          id: fieldId,
          selectedType,
          configProps: {
            dataType,
          } = {},
        } = field;
        if (selectedType === 'dropdown' && dataType === 'Users') {
          userFieldIds.add(fieldId);
        }
      });
    });
    return userFieldIds;
  }, [sections]);

  const validate = (selectedValues) => selectedValues.filter((emailOrId) => (
    emailOrId === FORM_AUTHOR_KEY
    || emailOrId === COMPANY_OWNER_KEY
    || userIds.has(emailOrId)
    || additionalIds.has(emailOrId)
    || emailRegex.test(emailOrId)
    || validFields.has(emailOrId)
  ));

  const onRecipientChange = useCallback((newSelected) => {
    const filteredSelected = validate(newSelected);

    setSelected(filteredSelected);
    onChange(filteredSelected);
  }, [onChange]);

  useEffect(() => {
    if (initialSelected && divisionId && !filtered) {
      let ourSelected = initialSelected;
      if (config.showDivisions) {
        const {
          [divisionId]: {
            users: divisionUsers = new Set(),
          } = {},
        } = divisions;
        ourSelected = initialSelected
          .filter((userId) => userId.length !== 36 || divisionUsers.has(userId));
      }
      onChange(ourSelected);
      setFiltered(true);
    }
  }, [initialSelected, divisionId, divisions, filtered]);

  return (
    <UserAssignmentSelector
      divisions={[divisionId]}
      onChange={onRecipientChange}
      selected={selected}
      mode={mode}
      showPositions={false}
      type="email"
      text={text}
      tokenSeparators={tokenSeparators}
      additionalOptions={additionalOptions}
      sections={sections}
      showFields
      showFormAuthor={showFormAuthor}
      formAuthorHelperText={authorHelpText}
      showCompanyOwner={showCompanyOwner}
      companyOwnerHelpText={ownerHelpText}
    />
  );
}

EmailRecipientSelector.propTypes = {
  additionalOptions: PropTypes.array,
  authorHelpText: PropTypes.string,
  divisionId: PropTypes.string.isRequired,
  initialSelected: PropTypes.array,
  mode: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  sections: PropTypes.array,
  showFormAuthor: PropTypes.bool,
  text: PropTypes.string,
  showCompanyOwner: PropTypes.bool,
  ownerHelpText: PropTypes.string,
};

EmailRecipientSelector.defaultProps = {
  additionalOptions: [],
  authorHelpText: AUTHOR_HELP,
  initialSelected: [],
  mode: 'tags',
  sections: [],
  showFormAuthor: false,
  text: 'Select Recipient(s):',
  showCompanyOwner: false,
  ownerHelpText: OWNER_HELP,
};
