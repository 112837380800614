import axios from 'axios';
import { message } from 'antd';
import { getTrueFileType } from '../files/fileHelpers';

// Need to create a new axios instance,
// because our default attaches a Bearer Token to all requests
const fileUploader = axios.create();

/**
 * Updates version in session storage & triggers custom event
 *  NOTE: updates to storage does not trigger an event on same document/page
 *        therefore we need to create custom event which we can listen to
 *        more details here - https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
 * @param {object} data
 */
const updateVersionInSessionStorage = (data) => {
  const {
    headers: {
      'web-version': availableVersion,
    } = {},
  } = data || {};
  if (!availableVersion) return;
  sessionStorage.setItem('latest-available-version', availableVersion);
  window.dispatchEvent(new Event('sessionStorageVersionUpdate'));
};

// eslint-disable-next-line
export const request = async function request({
  call,
  successMsg = 'Request succeeded',
  errMsg = 'Request failed',
  defaultData,
  hideSuccessToast = false,
  suppressError = false,
}) {
  try {
    const data = call instanceof Promise ? await call : await call();
    if (!hideSuccessToast) message.success(successMsg);
    updateVersionInSessionStorage(data);
    return data || { data: defaultData };
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      console.log('Response from server:', error.response.data);
      console.log(error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('No response', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    const {
      response: {
        data = {},
      } = {},
    } = error;
    const ourError = typeof errMsg === 'function' ? errMsg(data) : errMsg;
    if (!suppressError) message.error(ourError);
    return { err: ourError, data: defaultData };
  }
};

export const archive = ({
  id, active, type, dontStrip, // We strip the trailing s (e.g projects -> project)
}) => request({
  call: axios.put(`/${type}/${id}/archive`, { active }),
  errMsg: `Could not ${active ? 'reactivate' : 'archive'} ${type.substring(0, type.length - (dontStrip ? 0 : 1))}`,
  successMsg: `${type[0].toUpperCase() + type.substring(1, type.length - (dontStrip ? 0 : 1))} successfully ${active ? 'reactivated' : 'archived'}`,
});

export const parseError = (defaultMsg) => (err) => {
  const {
    message: errorMessage = 'Bad Request',
  } = err;
  return errorMessage !== 'Bad Request' ? errorMessage : defaultMsg;
};

const serializedUpload = async ({
  files,
  urls,
  currentIndex = 0,
}) => {
  // uploadFiles runs into 503 'Slow Down' errors from S3
  // if we have a lot of files to upload.
  if (currentIndex >= files.length) return [];
  const {
    url,
    type,
    id,
    name,
    timestamp,
    isAutosaved,
  } = urls[currentIndex];
  if (!isAutosaved) {
    await fileUploader.put(url, files[currentIndex], {
      headers: {
        'Content-Type': type,
      },
    });
  }
  const nextRes = await new Promise((resolve) => {
    setTimeout(async () => {
      resolve(await serializedUpload({
        files,
        urls,
        currentIndex: currentIndex + 1,
      }));
    }, 100);
  });
  return [{
    id,
    name,
    path: 'Attachments',
    timestamp,
    isAutosaved,
  }].concat(nextRes);
};

export const uploadFiles = async ({
  files,
  isDraft,
  formId,
  draftId,
  isExternalForm,
  companyId,
}) => {
  if (files.length === 0) return [];
  const path = isExternalForm ? '/external/file/upload/url' : '/field_data/file/upload/url';
  const {
    data: urls = [],
  } = await axios.post(path, {
    files: files.map(({
      type,
      name,
      timestamp,
      autoSaveId,
    }) => {
      let realType = (type ?? '').toLowerCase();
      if (type === 'application/octet-stream' && name.endsWith('pdf')) {
        realType = 'application/pdf'; // As of Version 2.6.0 (Fixed 2.6.7), our forms return type 'application/octet-stream', but backend needs application/pdf
      }
      if (realType === 'image') {
        realType = getTrueFileType({ name, type });
      }
      return {
        type: realType,
        name,
        timestamp,
        autoSaveId,
      };
    }),
    isDraft,
    formId,
    draftId,
    companyId,
  });
  return serializedUpload({ files, urls });
};
