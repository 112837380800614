import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import { Tree, Dropdown } from 'antd';
import { TagManager, Trie } from 'ontraccr-common';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

import { getIdMap } from '../../../helpers/helpers';

import { getFormulaError } from './formFieldsHelpers';

const FieldMenu = function FieldMenu({ fields = [], onSelect, calculationColumn, ourId }) {
  const defaultExpandedKeys = [];
  const treeData = fields.map((field) => {
    const { name, id, configProps = {} } = field;
    const { dataType } = configProps;
    const isTableCalculation = calculationColumn && field.id === ourId;
    const treeNode = { title: name, key: id };
    if (isTableCalculation) defaultExpandedKeys.push(treeNode.key);
    if (field.selectedType !== 'table') return treeNode;
    const {
      configProps: {
        columns = [],
      } = {},
    } = field;
    treeNode.selectable = false;
    const filteredColumns = isTableCalculation
      // Only allow one level deep
      ? columns.filter(({ isCalculation }) => !isCalculation)
      : columns;
    const children = filteredColumns.map((col) => ({
      title: isTableCalculation ? col.name : `Sum of ${col.name} values`,
      key: `${id}/${col.key}`,
    }));
    if (dataType === 'TimeEntry' && filteredColumns.find((c) => c.key === 'time')) {
      children.push({
        title: isTableCalculation ? 'Time (without break)' : 'Sum of Time values (without break)',
        key: `${id}/time-nobreak`,
      });
    }
    treeNode.children = children;
    return treeNode;
  });
  return (
    <Tree
      className="form-calculation-menu"
      treeData={treeData}
      selectedKeys={[]}
      defaultExpandedKeys={defaultExpandedKeys}
      onSelect={onSelect}
    />
  );
};

const getColSuffix = (colKey, isTableCalculation) => (isTableCalculation
  ? ` - ${colKey}`
  : ` - Sum of ${colKey} values`);
const getFullField = (field, colKey, isTableCalculation) => {
  const suffix = colKey ? getColSuffix(colKey, isTableCalculation) : '';
  const fieldName = `${field.name}${suffix}`;
  return {
    ...field,
    name: fieldName,
    colKey,
    isTableCalculation,
  };
};
const tagParser = (startIndex, field = {}) => {
  const {
    colKey,
    name,
    id,
    sectionId,
  } = field;
  return {
    fieldId: id,
    sectionId,
    startIndex,
    endIndex: startIndex + name.length,
    colKey,
  };
};

const tagsChanged = (t1 = [], t2 = []) => t1.length !== t2.length || t1.some((tag1, index) => {
  const { startIndex: t1Start, endIndex: t1End, fieldId: t1FId, sectionId: t1SId } = tag1;
  const { startIndex: t2Start, endIndex: t2End, fieldId: t2FId, sectionId: t2SId } = t2[index];
  return (
    t1Start !== t2Start
    || t1End !== t2End
    || t1FId !== t2FId
    || t1SId !== t2SId
  );
});

const filterTags = (tags = [], formula = '') => (
  tags.filter((tag) => (
    tag.startIndex < formula.length
    && tag.endIndex <= formula.length
  ))
);

export default function CalculationDropdown({
  id,
  sectionId,
  onFormulaChange,
  onTagsChange,
  formula,
  tags,
  sections = [],
  calculationColumn,
  hideExplanation,
}) {
  const [parsedText, setParsedText] = useState();
  const [fields, setFields] = useState([]);
  const [textRef, setTextRef] = useState();

  const flatFields = useMemo(() => (
    sections.map(({ fields: sectionFields = [], name, id: sId }) => (
      // Include same table for calculation columns
      sectionFields.filter((field) => field.id !== id || calculationColumn).map((field = {}) => {
        const { configProps: { title } = {} } = field;
        return { ...field, name: `${name} - ${title}`, sectionId: sId };
      })
    )).flat()
  ), [sections, id, calculationColumn]);
  const fieldMap = useMemo(() => getIdMap(flatFields), [flatFields]);

  const tagManager = useMemo(() => {
    const fieldTrie = Trie.constructTrie(flatFields, 'name');
    return new TagManager({
      tagPrefix: '#',
      trie: fieldTrie,
      data: flatFields,
      basicView: ({ children }) => children,
      tagView: ({ children, key }) => <span key={key} className='tag-view'>{children}</span>,
      showAllOptions: true,
    });
  }, [id, flatFields]);

  const onTextChange = useCallback((e) => {
    const {
      target: {
        value: newValue,
      } = {},
    } = e;
    onFormulaChange(newValue);
  }, [tagManager, onFormulaChange]);

  const onSelect = useCallback(([fieldId]) => {
    let realId = fieldId;
    let colKey;
    if (fieldId.includes('/')) {
      [realId, colKey] = fieldId.split('/');
    }
    const field = fieldMap[realId];
    if (!field) return;
    const isTableCalculation = calculationColumn && field.id === id;
    const fullField = getFullField(field, colKey, isTableCalculation);
    const index = formula.lastIndexOf('#');
    const newText = formula.slice(0, index) + `#${fullField.name}`;
    if (tagManager) {
      tagManager.addTag(index, fullField);
    }
    setTimeout(() => {
      textRef.focus();
      // Need this line for the cursor to move to the correct location on Safari.
      textRef.resizableTextArea.textArea.selectionStart = textRef.resizableTextArea.textArea.selectionEnd = newText.length;
    }, 50);

    onFormulaChange(newText);
    setFields([]);
  }, [
    formula,
    tagManager,
    textRef,
    fieldMap,
    onFormulaChange,
    calculationColumn,
  ]);

  const fieldsOverlay = useMemo(() => (
    <FieldMenu fields={fields} onSelect={onSelect} calculationColumn={calculationColumn} ourId={id} />
  ), [fields, onSelect, calculationColumn, id]);

  useEffect(() => {
    const {
      text: newParsedText,
      searchResults = [],
    } = tagManager.parseText(formula);
    const newTags = tagManager.getTaskMapPayload(tagParser);
    const filteredTags = filterTags(newTags, formula);
    if (tagsChanged(tags, filteredTags)) onTagsChange(filteredTags);
    setParsedText(newParsedText);
    setFields(searchResults);
  }, [tags, formula, fieldMap]);

  useEffect(() => {
    if (!tags || tags.length === 0) return;
    tags.forEach((tag) => {
      const { fieldId, startIndex, colKey } = tag;
      const field = fieldMap[fieldId];
      if (!field) return;
      const isTableCalculation = calculationColumn && field.id === id;
      tagManager.addTag(startIndex, getFullField(field, colKey, isTableCalculation));
    });
    const newTags = tagManager.getTaskMapPayload(tagParser);
    const filteredTags = filterTags(newTags, formula);
    if (tagsChanged(tags, filteredTags)) onTagsChange(filteredTags);
  }, [
    tags,
    fieldMap,
    calculationColumn,
    id,
  ]);

  const errorMessage = useMemo(() => (
    getFormulaError({
      configProps: { formula, tags },
      sectionId,
      field: { id },
      sections,
      calculationColumn,
    })
  ), [
    id,
    sectionId,
    formula,
    tags,
    sections,
    calculationColumn,
  ]);

  return (
    <div className='form-calculation-container'>
      <div className='form-calculation-text'>
        {parsedText}
      </div>
      <Dropdown
        placement='topCenter'
        visible={fields.length > 0}
        overlay={fieldsOverlay}
        overlayClassName='form-calculation-field-picker'
      >
        <OnTraccrTextInput
          className='form-calculation-text-field'
          textarea
          autoSize
          onRef={setTextRef}
          onChange={onTextChange}
          style={{ color: 'transparent', width: '100%' }}
          value={formula}
        />
      </Dropdown>
      <div id='form-calculation-error'>
        {errorMessage}
      </div>
      {!hideExplanation && (
        <div className='form-calculation-subtext'>
          Add a formula (e.g. 2 + 3).
          <br />
          <br />
          Use <b>#</b> to insert fields into the equation
        </div>
      )}
    </div>
  );
}
