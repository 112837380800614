import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import { Divider, Row, Tabs } from 'antd';
import PropTypes from 'prop-types';

import { RightOutlined } from '@ant-design/icons';

import WorkflowApproverNode from './WorkflowApproverNode';
import WorkflowEditNode from './WorkflowEditNode';
import WorkflowEmailNode from './WorkflowEmailNode';
import WorkflowPushNode from './WorkflowPushNode';
import WorkflowPDFNode from './WorkflowPDFNode';
import WorkflowFormTriggerNode from './WorkflowFormTriggerNode';
import WorkflowDispatchNode from './WorkflowDispatchNode';
import WorkflowStatusNode from './WorkflowStatusNode';
import WorkflowQuickBooksInvoice from './WorkflowQuickBooksSync';
import WorkflowPaymentNode from './WorkflowPaymentNode';
import WorkflowUpdateContractNode from './WorkflowUpdateContractNode';
import WorkflowMaterialDebitStep from './WorkflowMaterialDebitStep';
import WorkflowLogicalYesNoNode from './WorkflowLogicalYesNoNode';
import WorkflowLogicalLoopNode from './WorkflowLogicalLoopNode';
import WorkflowCreateTimeNode from './WorkflowCreateTimeNode';
import WorkflowCreateProfileNode from './WorkflowCreateProfileNode';
import WorkflowFormUpdateNode from './WorkflowFormUpdateNode';
import WorkflowCreateBoardCardNode from './WorkflowCreateBoardCardNode';
import WorkflowCardUpdateNode from './WorkflowCardUpdateNode';
import WorkflowUpdateCostNode from './WorkflowUpdateCostNode';
import WorkflowExternalSignatureNode from './WorkflowExternalSignatureNode';
import WorkflowLogicalConditionNode from './WorkflowLogicalConditionNode';
import WorkflowCreateTaskNode from './WorkflowCreateTaskNode';
import WorkflowEclipseSync from './WorkflowEclipseSync';

import { getType } from './workflowHelpers';
import NuxPopover from '../../nux/NuxPopover';

import {
  FORMS_WORKFLOW_STEP_PREFIX,
  FORMS_WORKFLOW_STEP_1,
  FORMS_WORKFLOW_STEP_1_TEXT,
  FORMS_WORKFLOW_STEP_2,
} from '../../nux/nux.constants';

import BorderlessButton from '../../common/buttons/BorderlessButton';

const DIM = 30;
const WIDTH = 300;

const title = 'Add New Step';
const { TabPane } = Tabs;

export default function WorkflowAddSlider({
  sections = [],
  typeId,
  divisionId,
}) {
  const activeNuxAction = useSelector((state) => state.nux.activeNuxAction);
  const formTypes = useSelector((state) => state.forms.types);
  const {
    connectedToQuickbooks,
    connectedToStripe,
    connectedToDocusign,
    connectedToEclipse,
  } = useSelector((state) => state.settings.company);
  const eclipseLinks = useSelector((state) => state.eclipse.links);

  const [open, setOpen] = useState(true);
  const [textStyle, setTextStyle] = useState({ opacity: 0 });

  const [iconStyle, setIconStyle] = useSpring(() => ({ }));
  const [containerStyle, setContainerStyle] = useSpring(() => ({ width: WIDTH, right: 0 }));

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    setIconStyle({
      transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
    });
    setContainerStyle({
      width: open ? WIDTH : DIM,
      right: 0,
    });
    setTimeout(
      () => setTextStyle({ opacity: open ? 0 : 1 }),
      open ? 0 : 500,
    );
  }, [open, setIconStyle, setContainerStyle]);

  useEffect(() => {
    if (activeNuxAction === FORMS_WORKFLOW_STEP_2) {
      setOpen(false);
    }
  }, [activeNuxAction]);

  const formTypeName = useMemo(() => {
    const formType = formTypes.find((type) => type.id === typeId);
    return formType?.name;
  }, [typeId]);

  const {
    canAddDispatch,
    canAddCreateProfile,
    canAddQuickBooks,
    canAddUpdateContract,
    canAddMaterialDebit,
    canAddCreateTime,
    canAddLogicalYesNo,
    canAddLogicalLoop,
    canAddFormUpdate,
    canAddCardUpdate,
    canAddUpdateCost,
    canAddLogicalCondition,
    canAddCreateTask,
    canAddEclipse,
  } = useMemo(() => {
    let hasTextField = false;
    let hasDateField = false;
    let hasCustomerSelect = false;
    let hasProjectSelect = false;
    let hasVendorSelect = false;
    let hasMultiShift = false;
    let hasMaterialTable = false;
    let hasTimeEntryTable = false;
    let hasYesNoField = false;
    let hasDropdownField = false;
    let hasFormField = false;
    let hasCardSelect = false;
    let hasEquipmentTable = false;
    let hasSubContractDropdown = false;
    let hasTableField = false;
    let hasAttributeField = false;

    let hasCostcodeTable = false;
    sections.forEach(({ fields = [] }) => {
      fields.forEach((field) => {
        const type = getType(field);

        if (field.selectedType === 'dropdown') {
          hasDropdownField = true;
        }

        if (type === 'text') {
          hasTextField = true;
        } else if (type === 'dateRange') {
          hasDateField = true;
        } else if (type === 'dropdown - Customers') {
          hasCustomerSelect = true;
        } else if (type === 'dropdown - Projects') {
          hasProjectSelect = true;
        } else if (type === 'dropdown - Vendors') {
          hasVendorSelect = true;
        } else if (type === 'dropdown - Cards') {
          hasCardSelect = true;
        } else if (type === 'table - Shifts') {
          hasMultiShift = true;
        } else if (type === 'table - Materials') {
          hasMaterialTable = true;
        } else if (type === 'table - TimeEntry') {
          hasTimeEntryTable = true;
        } else if (type === 'table - Equipment') {
          hasEquipmentTable = true;
        } else if (type === 'table - CostCodes') {
          hasCostcodeTable = true;
        } else if (type === 'yes-no') {
          hasYesNoField = true;
        } else if (type === 'dropdown - CompletedForms') {
          hasFormField = true;
        } else if (type === 'dropdown - PayableSubContracts') {
          hasSubContractDropdown = true;
        } else if (type?.includes('table')) {
          hasTableField = true;
        } else if (type === 'attribute') {
          hasAttributeField = true;
        }
      });
    });

    const hasUpdateCostTables = hasCostcodeTable || hasMaterialTable || hasEquipmentTable || hasTimeEntryTable; // eslint-disable-line max-len

    return {
      canAddDispatch: (hasTextField && hasDateField) || hasMultiShift,
      canAddCreateProfile: hasTextField,
      canAddQuickBooks: formTypeName === 'Invoice'
        ? hasTextField && hasCustomerSelect
        : hasMaterialTable && hasVendorSelect,
      canAddUpdateContract: hasTextField && hasProjectSelect,
      canAddMaterialDebit: hasMaterialTable,
      canAddLogicalYesNo: hasYesNoField,
      canAddCreateTime: hasTimeEntryTable,
      canAddLogicalLoop: hasDropdownField,
      canAddFormUpdate: hasFormField,
      canAddCardUpdate: hasCardSelect,
      canAddUpdateCost: hasProjectSelect
        && (hasUpdateCostTables || hasSubContractDropdown),
      canAddLogicalCondition: (
        hasDropdownField
        || hasTextField
        || hasYesNoField
        || hasTableField
        || hasAttributeField
      ),
      canAddCreateTask: hasTextField,
      canAddEclipse: hasEquipmentTable,
    };
  }, [sections, formTypeName]);

  const hasEclipseLink = useMemo(() => (
    eclipseLinks?.some((eclipseDiv) => eclipseDiv.divisionId === divisionId)
  ), [eclipseLinks, divisionId]);

  const showNux = activeNuxAction && activeNuxAction.startsWith(FORMS_WORKFLOW_STEP_PREFIX);
  const isNuxStep1 = activeNuxAction === FORMS_WORKFLOW_STEP_1;

  return (
    <animated.div style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      zIndex: showNux && !isNuxStep1 ? 1 : 1000,
      ...containerStyle,
      pointerEvents: isNuxStep1 ? 'none' : 'auto',
    }}
    >
      <div className="workflow-add-step-button">
        <animated.div style={iconStyle}>
          <BorderlessButton
            style={{ backgroundColor: 'transparent', width: DIM, height: DIM }}
            iconNode={<RightOutlined style={{ marginLeft: -8 }} />}
            onClick={toggleOpen}
          />
        </animated.div>
      </div>
      <div
        className="workflow-add-text"
        style={{
          transition: `opacity ${open ? 0 : 0.33}s ease-in-out`,
          ...textStyle,
        }}
      >
        {title}
      </div>
      <div
        className="workflow-drawer"
        style={{
          boxShadow: open ? '0px 1px 4px rgba(0, 0, 0, 0.15)' : 'none',
          padding: 10,
        }}
      >
        <Row style={{ height: 20 }}>
          {title}
        </Row>
        <Divider style={{ margin: '10px 0px 0px 0px' }} />
        <Tabs defaultActiveKey="Steps" className="workflow-drawer-tabs">
          <TabPane key="Steps" tab="Steps">
            <NuxPopover
              placement="left"
              visible={isNuxStep1}
              text={FORMS_WORKFLOW_STEP_1_TEXT}
              nextAction={FORMS_WORKFLOW_STEP_2}
            >
              <div className="workflow-drawer-scroll">
                <Row style={{ width: '100%' }} justify="center">
                  {WorkflowApproverNode()({ draggable: true })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowEditNode()({ draggable: true })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowEmailNode()({ draggable: true })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowPushNode()({ draggable: true })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowPDFNode()({ draggable: true })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowFormTriggerNode()({ draggable: true })}
                </Row>
                <Row style={{ width: '100%', marginTop: 20 }} justify="center">
                  {WorkflowCreateBoardCardNode()({ draggable: true, disabled: !canAddCreateProfile } /* need one text field for title*/ )}
                </Row>
                <Row style={{ width: '100%', marginTop: 20 }} justify="center">
                  {WorkflowCreateProfileNode()({ draggable: true, disabled: !canAddCreateProfile })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowDispatchNode()({ draggable: true, disabled: !canAddDispatch })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {
                    WorkflowUpdateContractNode()(
                      { draggable: true, disabled: !canAddUpdateContract },
                    )
                  }
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowStatusNode()({ draggable: true })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowMaterialDebitStep()({ draggable: true, disabled: !canAddMaterialDebit })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowCreateTimeNode()({ draggable: true, disabled: !canAddCreateTime })}
                </Row>
                {connectedToQuickbooks ? (
                  <Row className="form-workflow-node" justify="center">
                    {
                      WorkflowQuickBooksInvoice(
                        { formTypeName },
                      )({ draggable: true, disabled: !canAddQuickBooks })
                    }
                  </Row>
                ) : null}
                {connectedToStripe ? (
                  <Row className="form-workflow-node" justify="center">
                    {WorkflowPaymentNode({ isInvoice: formTypeName === 'Invoice' })({ draggable: true })}
                  </Row>
                ) : null}
                <Row className="form-workflow-node" justify="center">
                  {WorkflowFormUpdateNode()({ draggable: true, disabled: !canAddFormUpdate })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowCardUpdateNode()({ draggable: true, disabled: !canAddCardUpdate })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {WorkflowUpdateCostNode()({ draggable: true, disabled: !canAddUpdateCost })}
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {
                    WorkflowExternalSignatureNode()({
                      draggable: true,
                      disabled: !connectedToDocusign,
                    })
                  }
                </Row>
                <Row className="form-workflow-node" justify="center">
                  {
                    WorkflowCreateTaskNode()({
                      draggable: true,
                      disabled: !canAddCreateTask,
                    })
                  }
                </Row>
                {
                  connectedToEclipse && (
                    <Row className="form-workflow-node" justify="center">
                      {
                        WorkflowEclipseSync()({
                          draggable: true,
                          disabled: !hasEclipseLink || !canAddEclipse,
                        })
                      }
                    </Row>
                  )
                }
              </div>
            </NuxPopover>
          </TabPane>
          <TabPane key="Logic" tab="Logic">
            <div className="workflow-drawer-scroll">
              <Row style={{ width: '100%' }} justify="center">
                {WorkflowLogicalYesNoNode()({ draggable: true, disabled: !canAddLogicalYesNo })}
              </Row>
              <Row className="form-workflow-node" justify="center">
                {WorkflowLogicalLoopNode()({ draggable: true, disabled: !canAddLogicalLoop })}
              </Row>
              <Row className="form-workflow-node" justify="center">
                {WorkflowLogicalConditionNode()({ draggable: true, disabled: !canAddLogicalCondition })}
              </Row>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </animated.div>
  );
}

WorkflowAddSlider.propTypes = {
  sections: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  typeId: PropTypes.number.isRequired,
  divisionId: PropTypes.string,
};

WorkflowAddSlider.defaultProps = {
  sections: [],
  divisionId: null,
};
