import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import { saveCompanySettings } from '../state/settings.actions';
import CompanyEditRow from '../CompanyEditRow';
import UserAssignmentSelector from '../../forms/FormWorkflows/selectors/UserAssignmentSelector';

// eslint-disable-next-line prefer-regex-literals
const emailRegex = new RegExp(/^[^@\s]+@[^@\s\.]+(?:\.[^@\.\s]+)+$/);
const tokenSeparators = [',', '\n'];

export default function ErrorRecipientSelector({
  recipientsKey,
  integration,
  selectorStyle,
}) {
  const dispatch = useDispatch();
  const {
    settings: {
      [recipientsKey]: errorRecipients,
    } = {},
  } = useSelector((state) => state.settings.company || {});
  const users = useSelector((state) => state.users.users);
  const positionNames = useSelector((state) => state.settings.positionNames);

  const userIds = useMemo(() => {
    const idSet = new Set();
    users?.forEach((user) => {
      if (user.active) {
        idSet.add(user.id);
      }
    });
    return idSet;
  }, [users]);

  const positionIds = useMemo(() => {
    const idSet = new Set();
    positionNames?.forEach((position) => {
      idSet.add(position.id);
    });
    return idSet;
  }, [positionNames]);

  const validate = (selectedValues) => selectedValues.filter((emailOrId) => (
    userIds.has(emailOrId)
    || positionIds.has(emailOrId)
    || emailRegex.test(emailOrId)
  ));

  const updateRecipients = useCallback((newSelected) => {
    const filteredSelected = validate(newSelected);

    dispatch(saveCompanySettings({ [recipientsKey]: filteredSelected }));
  }, [validate, saveCompanySettings, recipientsKey]);

  return (
    <>
      <CompanyEditRow
        title="Error report recipients"
        helpText={`Set who should be notified in the event time entries fail to sync to ${integration}. If empty, it will be sent to the company owner.`}
        divider={false}
        textColumnMax={null}
        style={{ paddingRight: 15 }}
      />
      <div style={selectorStyle}>
        <UserAssignmentSelector
          onChange={updateRecipients}
          selected={errorRecipients}
          mode="tags"
          showPositions
          type="email"
          text="Select Recipient(s):"
          tokenSeparators={tokenSeparators}
        />
      </div>
      <Divider style={{ margin: 0 }} />
    </>
  );
}

ErrorRecipientSelector.propTypes = {
  integration: PropTypes.string.isRequired,
  recipientsKey: PropTypes.string.isRequired,
  selectorStyle: PropTypes.shape({}),
};

ErrorRecipientSelector.defaultProps = {
  selectorStyle: {},
};
