import axios from 'axios';
import { request, parseError } from '../../helpers/requests';
import { formatPayloadCustomData } from '../../equipment/state/equipment.service';
import { parseAndUploadFiles } from '../../files/fileHelpers';

const BucketsService = {
  getTemplates: () => request({
    call: axios.get('/bucketTemplates'),
    errMsg: parseError('Failed to get bucket templates'),
    hideSuccessToast: true,
  }),
  createTemplate: (payload) => request({
    call: axios.post('/bucketTemplates', payload),
    errMsg: parseError('Failed to create bucket template'),
  }),
  updateTemplate: (id, payload) => request({
    call: axios.put(`/bucketTemplates/${id}`, payload),
    errMsg: parseError('Failed to update bucket template'),
  }),
  deleteTemplate: (id) => request({
    call: axios.delete(`/bucketTemplates/${id}`),
    errMsg: parseError('Failed to delete bucket template'),
  }),
  getBuckets: (query) => request({
    call: axios.get('/buckets', { params: query }),
    errMsg: parseError('Failed to get buckets'),
    hideSuccessToast: true,
  }),
  createBucket: (payload) => request({
    call: async () => {
      const realPayload = { ...payload };
      const { files = [], customData } = payload;
      const {
        customData: formattedCustomData,
        files: formattedFiles,
      } = await formatPayloadCustomData(customData);
      realPayload.customData = formattedCustomData;
      const parsedFiles = await parseAndUploadFiles(files);
      realPayload.files = parsedFiles.concat(formattedFiles);
      return axios.post('/buckets', realPayload);
    },
    errMsg: parseError('Failed to create bucket'),
  }),
  updateBucket: (id, payload) => request({
    call: async () => {
      const realPayload = { ...payload };
      const { files = [], customData } = payload;
      const {
        customData: formattedCustomData,
        files: formattedFiles,
      } = await formatPayloadCustomData(customData);
      realPayload.customData = formattedCustomData;
      const parsedFiles = await parseAndUploadFiles(files);
      realPayload.files = parsedFiles.concat(formattedFiles);
      return axios.put(`/buckets/${id}`, realPayload);
    },
    errMsg: parseError('Failed to update bucket'),
  }),
  deleteBucket: (id) => request({
    call: axios.delete(`/buckets/${id}`),
    errMsg: parseError('Failed to delete bucket'),
  }),
  getBucketNotes: (id) => request({
    call: axios.get(`/buckets/${id}/notes`),
    errMsg: parseError('Failed to get bucket notes'),
    hideSuccessToast: true,
  }),
  addBucketNote: (id, note) => request({
    call: axios.post(`/buckets/${id}/note`, { note }),
    errMsg: parseError('Failed to add bucket note'),
  }),
  getBucketCustomData: (id) => request({
    call: axios.get(`/buckets/${id}/data`, { params: { getSignedUrls: false } }),
    errMsg: 'Failed to get bucket custom data',
    hideSuccessToast: true,
  }),
};

export default BucketsService;
