import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import { useDispatch } from 'react-redux';
import ShiftUpload from './ShiftUpload';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import { buildMassUploadData } from './ShiftUpload.helpers';
import { uploadShifts } from '../state/schedule.actions';

export default function ShiftUploadDrawer({
  visible,
  onClose,
}) {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [uploadFile, setUploadFile] = useState();
  const [massUploadData, setMassUploadData] = useState();

  const handleClose = () => {
    setCurrentStep(0);
    setUploadFile();
    setMassUploadData();
    onClose();
  };

  const handleSubmit = async () => {
    const payload = buildMassUploadData(massUploadData);
    const valid = await dispatch(uploadShifts(payload));
    if (valid) handleClose();
  };

  const onNext = () => {
    switch (currentStep) {
      case 0:
        if (!uploadFile) return;
        setCurrentStep(currentStep + 1);
        break;
      case 1:
        setCurrentStep(currentStep + 1);
        break;
      case 2:
        handleSubmit();
        break;
      default:
        break;
    }
  };

  const canSubmit = useMemo(() => {
    switch (currentStep) {
      case 0:
        return !!uploadFile;
      case 1:
        return true;
      default:
        return true;
    }
  }, [currentStep, uploadFile]);

  const nextTitle = currentStep === 2 ? 'Submit' : 'Next';

  return (
    <Drawer
      width={1000}
      visible={visible}
      onClose={handleClose}
      title="Upload Shifts"
      maskClosable={false}
    >
      <ShiftUpload
        uploadFile={uploadFile}
        setUploadFile={setUploadFile}
        currentStep={currentStep}
        setMassUploadData={setMassUploadData}
      />
      <DrawerSubmitFooter
        actionTitle="Close"
        submitTitle={nextTitle}
        closeTitle="Back"
        onSubmit={canSubmit ? onNext : null}
        onClose={currentStep > 0 ? () => setCurrentStep(currentStep - 1) : null}
        onAction={handleClose}
        canSubmit={canSubmit}
      />

    </Drawer>
  );
}

ShiftUploadDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

ShiftUploadDrawer.defaultProps = {
  visible: false,
  onClose: () => {},
};
