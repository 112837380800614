import moment from 'moment';
import { Trie } from 'ontraccr-common';
import {
  CLOCKIN,
  START_BREAK,
  END_BREAK,
  SWITCH_TASK,
  CLOCKOUT,
} from 'ontraccr-common/lib/timeTracking/state/actionTypes';

import {
  GET_ALL_USERS,
  GET_USERS_BY_ID,
  CREATE_USER,
  UPDATE_USER_BY_ID,
  DELETE_USER_BY_ID,
  ARCHIVE_USER,
  MASS_ARCHIVE_USERS,
  GET_QUICKBOOKS_EMPLOYEES,
  GET_USER_TEAMS,
  GET_DIVISIONS,
  TRANSFER_DIVISIONS,
  GET_USER_LABELS,
  GET_USER_FILES,
  GET_USER_NOTES,
  ADD_USER_NOTE,
  DELETE_LABEL,
  GET_USER_SETTINGS,
  UPDATE_USER_SETTINGS,
  GET_USER_CUSTOM_FIELD_TEMPLATE,
  UPDATE_USER_CUSTOM_FIELD_TEMPLATE,
  GET_USER_CUSTOM_DATA,
  CREATE_PROJECT,
} from '../../state/actionTypes';

import sortByString, { getIdMap } from '../../helpers/helpers';

const initialState = {
  users: [],
  lastUpdated: moment(),
  quickBooksUsers: [],
  userTrie: {},
  userTeams: {},
  userDivisions: {}, // { userId: [list of divisions] }
  userToLabel: {}, // { userId: [list of labels] }
  labelToUser: {}, // { labelTitle: [list of userIds] }
  userFiles: {},
  notes: {},
  settings: {},
  customFieldTemplate: {},
  customData: [],
  customDataFiles: {},
};

const getClockedInState = (user) => {
  if (user.clockInTime && user.clockOutTime) return 'Clocked Out';
  if (user.clockInTime) return 'Clocked In';
  return 'Inactive';
};

export default function usersActions(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_USERS: {
      const trie = {};
      const users = action.payload.users.map((fullUser) => {
        const {
          tasks, // remove tasks
          ...user
        } = fullUser;
        if (user.active) Trie.addToTrie(trie, user);
        return user;
      }).sort(sortByString('name'));
      return {
        ...state,
        users,
        userTrie: trie,
      };
    }
    case GET_USERS_BY_ID:
      return {
        ...state,
        selectedUser: action.payload.selectedUser,
      };
    case CREATE_USER: {
      const {
        payload: {
          user = {},
        } = {},
      } = action;

      const {
        id: userId,
        divisions = [],
        labels = [],
      } = user;
      const {
        users = [],
        userDivisions = {},
        userToLabel: stateUserToLabel = {},
        labelToUser: stateLabelToUser = {},
      } = state;

      const newUserToLabel = { ...stateUserToLabel };
      const newLabelToUser = { ...stateLabelToUser };
      newUserToLabel[user.id] = labels.map((l) => l.labelId);
      labels.forEach((label) => {
        const { labelId } = label;
        if (!(labelId in newLabelToUser)) {
          newLabelToUser[labelId] = [user.id];
        } else {
          newLabelToUser[labelId].push(user.id);
        }
      });

      const newUsers = users.slice();
      newUsers.push(user);

      const newDivs = { ...userDivisions };
      newDivs[userId] = divisions;

      return {
        ...state,
        users: newUsers,
        userDivisions: newDivs,
        userToLabel: newUserToLabel,
        labelToUser: newLabelToUser,
      };
    }
    case DELETE_USER_BY_ID: {
      const {
        payload: {
          userId,
        } = {},
      } = action;
      const {
        users = [],
      } = state;
      const newUsers = [];
      const trie = {};
      users.forEach((user) => {
        if (user.id === userId) return;
        newUsers.push(user);
        if (user.active) Trie.addToTrie(trie, user);
      });
      return {
        ...state,
        users: newUsers,
        userTrie: trie,
      };
    }
    case UPDATE_USER_BY_ID: {
      const {
        payload: {
          user,
        },
      } = action;
      const {
        users: stateUsers = [],
        userDivisions: stateDivisions = {},
        userToLabel: stateUserToLabel = {},
        labelToUser: stateLabelToUser = {},
      } = state;
      const users = JSON.parse(JSON.stringify(stateUsers));
      const newDivs = { ...stateDivisions };

      const copiedUser = { ...user };
      const { addedLabels, removedLabels } = copiedUser;
      delete copiedUser.addedLabels;
      delete copiedUser.removedLabels;

      const newUserToLabel = { ...stateUserToLabel };
      const newLabelToUser = { ...stateLabelToUser };
      const {
        [user.id]: oldLabels = [],
      } = newUserToLabel;
      const oldUserLabelSet = new Set(oldLabels);
      addedLabels.forEach((label) => {
        const { labelId } = label;
        if (!(labelId in newLabelToUser)) {
          newLabelToUser[labelId] = [user.id];
        } else {
          newLabelToUser[labelId].push(user.id);
        }

        if (!oldUserLabelSet.has(labelId)) {
          const {
            [user.id]: oldUserLabelArray = [],
          } = newUserToLabel;
          newUserToLabel[user.id] = oldUserLabelArray.concat([labelId]);
          oldUserLabelSet.add(labelId);
        }
      });

      removedLabels.forEach(({ id: labelId }) => {
        newLabelToUser[labelId] = (newLabelToUser[labelId] || [])
          .filter((userId) => userId !== user.id);
        if (newLabelToUser[labelId].length === 0) {
          delete newLabelToUser[labelId];
        }
        const {
          [user.id]: oldUserLabels = [],
        } = newUserToLabel;
        newUserToLabel[user.id] = oldUserLabels.filter((userLabel) => userLabel !== labelId);
      });

      const myUsers = users.map((oldUser) => {
        if (oldUser.id !== copiedUser.id) return oldUser;
        const myUser = { ...oldUser };
        let {
          id,
          teams: existingTeams = [],
          projects: existingProjects = [],
        } = myUser;
        let {
          [id]: existingDivisions = [],
        } = newDivs;
        Object.keys(copiedUser).forEach((key) => {
          if (key === 'removedProjects') {
            const projectMap = getIdMap(copiedUser.removedProjects);
            existingProjects = existingProjects.filter(({ id }) => !(id in projectMap));
          } else if (key === 'addedProjects') {
            existingProjects = existingProjects.concat(copiedUser.addedProjects);
          } else if (key === 'removedTeams') {
            const teamMap = getIdMap(copiedUser.removedTeams);
            existingTeams = existingTeams.filter(({ id }) => !(id in teamMap));
          } else if (key === 'addedTeams') {
            existingTeams = existingTeams.concat(copiedUser.addedTeams);
          } else if (key === 'addedDivisions') {
            existingDivisions = existingDivisions.concat(copiedUser.addedDivisions);
          } else if (key === 'removedDivisions') {
            const removeSet = new Set(copiedUser.removedDivisions);
            existingDivisions = existingDivisions.filter((divId) => !removeSet.has(divId));
          } else {
            myUser[key] = copiedUser[key];
          }
        });
        myUser.teams = existingTeams;
        myUser.projects = existingProjects;
        myUser.status = getClockedInState(myUser);

        newDivs[id] = [...existingDivisions];

        return myUser;
      });

      return {
        ...state,
        users: myUsers,
        lastUpdated: moment(),
        userDivisions: newDivs,
        userToLabel: newUserToLabel,
        labelToUser: newLabelToUser,
      };
    }

    case ARCHIVE_USER: {
      const {
        payload: {
          userId,
          active,
        },
      } = action;
      const trie = {};
      const users = state.users.map((user) => {
        if (user.id !== userId) {
          if (user.active) Trie.addToTrie(trie, user);
          return user;
        }
        const newUser = { ...user };
        newUser.active = active ? 1 : 0;
        if (active) Trie.addToTrie(trie, newUser);
        return newUser;
      });
      return {
        ...state,
        users,
        userTrie: trie,
      };
    }

    case MASS_ARCHIVE_USERS: {
      const {
        userIds = [],
      } = action.payload;
      const userIdSet = new Set(userIds);
      const trie = {};
      return {
        ...state,
        users: state.users.map((user) => {
          if (!userIdSet.has(user.id)) {
            if (user.active) Trie.addToTrie(trie, user);
            return user;
          }
          return {
            ...user,
            active: 0,
          };
        }),
        userTrie: trie,
      };
    }

    case END_BREAK:
    case START_BREAK:
    case CLOCKIN: {
      const {
        userId,
        type,
      } = action.payload;
      const users = state.users.map((user) => {
        if (user.id !== userId) return user;
        const newUser = user;
        newUser.status = type === 'break' && action.type === START_BREAK ? 'On Break' : 'Clocked In';
        return newUser;
      });
      const newState = {
        ...state,
        users,
      };

      return newState;
    }

    case CLOCKOUT: {
      const {
        userId,
      } = action.payload;

      const users = state.users.map((user) => {
        if (user.id !== userId) return user;
        const newUser = user;
        newUser.status = 'Clocked Out';
        return newUser;
      });

      return {
        ...state,
        users,
      };
    }

    case SWITCH_TASK: {
      const {
        userId,
      } = action.payload;

      const users = state.users.map((user) => {
        if (user.id !== userId) return user;
        const newUser = user;
        newUser.status = 'Clocked In';
        return newUser;
      });

      const newState = {
        ...state,
        users,
      };
      return newState;
    }
    case GET_QUICKBOOKS_EMPLOYEES: {
      const {
        data: users = [],
      } = action.payload;
      return {
        ...state,
        quickBooksUsers: users,
      };
    }
    case GET_USER_TEAMS: {
      const {
        payload: {
          userTeams = {},
        } = {},
      } = action;
      return {
        ...state,
        userTeams,
      };
    }
    case GET_DIVISIONS: {
      const {
        payload: {
          userDivisions = {},
        } = {},
      } = action;

      return {
        ...state,
        userDivisions,
      };
    }
    case TRANSFER_DIVISIONS: {
      const {
        payload: {
          type,
          divisionId,
          oldDivisionId,
          keepOldDivision,
          ids = [],
        },
      } = action;
      const {
        userDivisions: stateUserDivs,
      } = state;
      const newUserDivs = { ...stateUserDivs };
      if (type !== 'users') return state;

      ids.forEach((userId) => {
        let {
          [userId]: userDivs = [],
        } = newUserDivs;
        userDivs = new Set(userDivs);
        userDivs.add(divisionId);
        if (!keepOldDivision) {
          userDivs.delete(oldDivisionId);
        }
        newUserDivs[userId] = Array.from(userDivs);
      });
      return {
        ...state,
        userDivisions: newUserDivs,
      };
    }
    case GET_USER_LABELS: {
      const {
        payload: {
          userLabels = [],
        } = {},
      } = action;
      const userToLabel = {};
      const labelToUser = {};
      userLabels.forEach((label) => {
        const { userId, labelId } = label;
        if (!(userId in userToLabel)) {
          userToLabel[userId] = [];
        }
        userToLabel[userId].push(labelId);

        if (!(labelId in labelToUser)) {
          labelToUser[labelId] = [];
        }
        labelToUser[labelId].push(userId);
      });
      return {
        ...state,
        userToLabel,
        labelToUser,
      };
    }
    case GET_USER_FILES: {
      const {
        payload: {
          userId,
          files = [],
        } = {},
      } = action;
      const { userFiles: oldUserFiles = {} } = state;
      return {
        ...state,
        userFiles: {
          ...oldUserFiles,
          [userId]: files,
        },
      };
    }
    case GET_USER_NOTES: {
      const {
        payload: {
          id,
          data: notes = [],
        } = {},
      } = action;
      const { notes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      existingNotes[id] = notes;
      existingNotes[id].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        notes: existingNotes,
      };
    }
    case ADD_USER_NOTE: {
      const {
        payload = {},
      } = action;
      const { userId } = payload;
      const { notes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      const {
        [userId]: existingUserNotes = [],
      } = existingNotes;
      existingNotes[userId] = existingUserNotes.concat([payload]);
      existingNotes[userId].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        notes: existingNotes,
      };
    }
    case DELETE_LABEL: {
      const {
        payload: {
          labelId,
        } = {},
      } = action;
      const {
        userToLabel: stateUserToLabel = {},
        labelToUser: stateLabelToUser = {},
      } = state;

      const newUserToLabel = { ...stateUserToLabel };
      const newLabelToUser = { ...stateLabelToUser };
      delete newLabelToUser[labelId];

      Object.keys(newUserToLabel).forEach((userId) => {
        newUserToLabel[userId] = newUserToLabel[userId]
          .filter((stateLabelId) => stateLabelId !== labelId);
      });
      return {
        ...state,
        userToLabel: newUserToLabel,
        labelToUser: newLabelToUser,
      };
    }
    case GET_USER_SETTINGS:
    case UPDATE_USER_SETTINGS: {
      return {
        ...state,
        settings: action.payload,
      };
    }
    case GET_USER_CUSTOM_FIELD_TEMPLATE:
    case UPDATE_USER_CUSTOM_FIELD_TEMPLATE: {
      const {
        payload: {
          template = {},
        } = {},
      } = action;
      return {
        ...state,
        customFieldTemplate: template,
      };
    }
    case GET_USER_CUSTOM_DATA: {
      const {
        data: {
          data = [],
          fileMap = {},
        } = {},
      } = action.payload;
      return {
        ...state,
        customData: data,
        customDataFiles: fileMap,
      };
    }
    case CREATE_PROJECT: {
      const {
        payload: {
          project = {},
        } = {},
      } = action;
      const {
        id: projectId,
        name,
        assignToUser: userId,
      } = project;
      const { users } = state;
      const newUsers = users.map((user) => {
        if (user.id !== userId) return user;
        const { projects: existingProjects = [] } = user;
        if (existingProjects.some((userProj) => userProj.id === projectId)) return user;
        return {
          ...user,
          projects: existingProjects.concat([{ id: projectId, name }]),
        };
      });
      return {
        ...state,
        users: newUsers,
      };
    }
    default:
      return state;
  }
}
