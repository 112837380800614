/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { message } from 'antd';

import { PropTypes } from 'prop-types';
import CardGrid from '../common/cardGrid/cardGrid';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import FilterDropdown from '../common/FilterDropdown';
import WarningHeader from '../common/text/WarningHeader';

import FormsAddView from './FormsAddView';
import FormTemplateCard from './FormTemplateCard';
import { getFilterData, getDefaultFilters } from './formsFilters';

import {
  createTemplate,
  archiveTemplate,
  editTemplate,
  deleteTemplate,
} from './state/forms.actions';

import {
  getProjectFileTemplates,
} from '../projects/state/projects.actions';

import {
  getTemplates as getCostcodeTemplates,
} from '../costcodes/state/costcodes.actions';

import {
  FORM_CREATE_TYPE,
} from '../nux/nux.constants';

import {
  createNuxEntry,
} from '../nux/state/nux.actions';
import { getFormLibrary } from './FormLibrary/state/formLibrary.actions';

import { storageUpgradeWarning } from '../files/fileHelpers';

import { parseDrawOptions } from '../common/pdf/PDFDesigner/PDFDesignerHelpers';

import Permissions from '../auth/Permissions';
import sortByString from '../helpers/helpers';
import { removeAPIFieldsFromSections } from './formHelpers';

const getBoardMappings = async (id) => {
  try {
    const { data } = await axios.get(`/forms/templates/${id}/boards/mappings`);
    return data ?? [];
  } catch (err) {
    message.error('Failed to get form template mappings');
    return [];
  }
};

export default function FormTemplates({ history, projectId }) {
  const dispatch = useDispatch();
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const formTypes = useSelector((state) => state.forms.types);

  const [formData, setFormData] = useState();
  const [activeFilters, setActiveFilters] = useState({ active: new Set([1]), typeId: new Set() });

  const defaultFilterData = useMemo(() => getDefaultFilters(formTypes), [formTypes]);
  const filterData = useMemo(() => getFilterData(formTypes), [formTypes]);

  const { templateArray, formTemplates, isLoading } = useSelector((state) => {
    const {
      forms: {
        templates = {},
        templatesIsLoading = true,
      } = {},
    } = state;
    const tempArr = Object.values(templates);
    tempArr.sort(sortByString('name'));
    return {
      formTemplates: templates,
      templateArray: tempArr,
      isLoading: templatesIsLoading,
    };
  });

  const nux = useSelector((state) => state.nux.nux);

  const {
    hasDocManagement,
    isOwner,
  } = useSelector((state) => {
    const {
      settings: {
        company: {
          userId: ownerId,
          documentManagementEnabled,
        } = {},
      } = {},
    } = state;
    return {
      hasDocManagement: documentManagementEnabled,
      isOwner: ownerId === Permissions.id,
    };
  });

  useEffect(() => {
    setActiveFilters(defaultFilterData);
  }, [defaultFilterData]);

  useEffect(() => {
    if (!projectId) dispatch(getFormLibrary());
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(getProjectFileTemplates());
    dispatch(getCostcodeTemplates());
  }, []);

  const onAdd = useCallback(async ({
    exportLocation,
    name,
    divisionId,
    typeId,
    externalFormPrimaryColor,
    externalFormAccentColor,
    externalFormSecondaryAccentColor,
    externalFormRedirectLink,
    // externalFormPassword,
    // externalFormPasswordConfirm,
  } = {}) => {
    const {
      drawOptions,
      externalFormURL,
      sections,
      isExternalForm,
      displayFields,
      ...data
    } = formData;
    const fullData = {
      name,
      exportLocation,
      divisionId,
      typeId,
      ...data,
      ...parseDrawOptions(drawOptions),
      projectId,
      sections: isExternalForm ? removeAPIFieldsFromSections(sections) : sections,
      isExternalForm,
      settings: {
        url: externalFormURL,
        // password: externalFormPassword,
        primaryColor: externalFormPrimaryColor,
        accentColor: externalFormAccentColor,
        secondaryAccentColor: externalFormSecondaryAccentColor,
        redirectLink: externalFormRedirectLink,
      },
      displayFields: displayFields?.map(({ fieldId, orderIndex, columnKey }) => ({
        fieldId,
        orderIndex,
        columnKey,
      })),
    };

    if (!dispatch) return false;
    if (await dispatch(createTemplate(fullData))) {
      setFormData();
      if (!nux.has(FORM_CREATE_TYPE)) {
        dispatch(createNuxEntry(FORM_CREATE_TYPE));
      }
      return true;
    }
    return false;
  }, [formData, nux, projectId]);

  const onEdit = useCallback(async (id, {
    exportLocation,
    name,
    divisionId,
    typeId,
    externalFormPrimaryColor,
    externalFormAccentColor,
    externalFormSecondaryAccentColor,
    externalFormRedirectLink,
    // externalFormPassword,
    // externalFormPasswordConfirm,
  } = {}) => {
    const {
      [id]: oldTemplate,
    } = formTemplates;
    if (!oldTemplate) return false;
    const newName = name || oldTemplate.name;
    const newType = typeId || oldTemplate.typeId;

    const {
      drawOptions,
      externalFormURL,
      sections,
      isExternalForm,
      displayFields,
      ...data
    } = formData;
    const fullData = {
      ...data,
      ...parseDrawOptions(drawOptions),
      name: newName,
      divisionId,
      typeId: newType,
      exportLocation,
      sections: isExternalForm ? removeAPIFieldsFromSections(sections) : sections,
      isExternalForm,
      settings: {
        url: externalFormURL,
        // password: externalFormPassword,
        primaryColor: externalFormPrimaryColor,
        accentColor: externalFormAccentColor,
        secondaryAccentColor: externalFormSecondaryAccentColor,
        redirectLink: externalFormRedirectLink,
      },
      displayFields: displayFields?.map(({ fieldId, orderIndex, columnKey }) => ({
        fieldId,
        orderIndex,
        columnKey,
      })),
    };

    if (!dispatch) return false;
    if (await dispatch(editTemplate(id, fullData))) {
      setFormData();
      return true;
    }
    return false;
  }, [formData, formTemplates]);

  const onArchive = useCallback(async (template) => {
    const mode = template.active ? 'Archive' : 'Activate';
    const boardFormMappings = await getBoardMappings(template.id);
    const content = boardFormMappings?.length
      ? (
        <div>
          Warning: this form template is attached to the following board mappings
          <ul style={{ margin: 10, textAlign: 'left' }}>
            {boardFormMappings.map((bfm) => (
              <li>
                <b style={{ color: 'black' }}>Board:</b> {bfm.board} <b style={{ color: 'black' }}>Mapping:</b> {bfm.name}
              </li>
            ))}
          </ul>
          Archiving this form template will delete these mappings
        </div>
      )
      : null;
    CustomConfirmModal({
      title: `${mode} Form Template '${template.name}'?`,
      okText: mode,
      cancelText: 'Cancel',
      content,
      async onOk() {
        dispatch(archiveTemplate(template.id, !template.active));
      },
    });
  }, [dispatch]);

  const onDelete = useCallback(({ id, name }) => {
    if (!dispatch) return;
    CustomConfirmModal({
      title: `Delete Form Template '${name}'?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        dispatch(deleteTemplate(id));
      },
    });
  }, [dispatch]);

  const onFilter = useCallback((checkedFilters, key) => {
    const newFilters = { ...activeFilters };
    newFilters[key] = new Set(checkedFilters);
    setActiveFilters(newFilters);
  }, [activeFilters]);

  const filterIsAtDefault = useMemo(() => {
    const { active, typeId } = activeFilters;
    return active.has(1) && !active.has(0) && formTypes.every((type) => typeId.has(type.id));
  }, [activeFilters, formTypes]);

  const data = useMemo(() => (
    templateArray.filter((template) => (
      template.notDeleted
      && ((!projectId && !template.projectId) || template.projectId === projectId)
      && activeFilters.active.has(template.active)
      && activeFilters.typeId.has(template.typeId)
      && selectedDivisions.has(template.divisionId)
    ))
  ), [activeFilters, templateArray, selectedDivisions, projectId]);

  const hasWritePerms = Permissions.has('FORMS_WRITE');

  const addConfig = useMemo(() => {
    if (!hasWritePerms) return null;
    return {
      title: 'Enter Form Details',
      formView: (form) => (
        <FormsAddView
          {...form}
          onFormDataChange={setFormData}
          canAddGlobal={!!projectId}
          canAddFromLibrary={!projectId}
          projectId={projectId}
        />
      ),
      width: 'fit-content',
      onClose: onAdd,
      numberOfSteps: 4,
      disabled: !hasDocManagement,
      disabledPopoverTitle: <WarningHeader />,
      disabledPopoverContent: storageUpgradeWarning({
        isOwner,
        history,
        action: 'create custom forms',
      }),
    };
  }, [hasWritePerms, onAdd, hasDocManagement, history, isOwner, projectId]);

  if (!Permissions.has('FORMS_READ')) {
    history.replace('/dashboard');
    return '';
  }

  return (
    <CardGrid
      dataSource={data}
      isLoading={isLoading}
      itemView={FormTemplateCard}
      itemDimensions={{ width: 285, height: 197 }}
      colCount={3}
      pageSize={12}
      add={addConfig}
      edit={{
        title: 'Edit Form Details',
        formView: (form, formProps) => (
          <FormsAddView
            {...form}
            formProps={formProps}
            onFormDataChange={setFormData}
            projectId={projectId}
          />
        ),
        width: 'fit-content',
        onClose: onEdit,
        numberOfSteps: 4,
        canEdit: () => Permissions.has('FORMS_WRITE'),
        canArchive: () => Permissions.has('FORMS_WRITE'),
        canDelete: () => Permissions.has('FORMS_WRITE'),
        alwaysReturn: true,
      }}
      onArchive={onArchive}
      onDelete={onDelete}
      filter={(
        <FilterDropdown
          filters={[filterData]}
          activeFilters={activeFilters}
          onFilter={onFilter}
          style={{ width: 350 }}
        />
        )}
      filterActive={filterIsAtDefault}
    />
  );
}

FormTemplates.propTypes = {
  history: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};
