import axios from 'axios';

import { request } from '../../helpers/requests';
import { decorateFormWithFiles } from '../../forms/formHelpers';
import { parseAndUploadFiles } from '../../files/fileHelpers';
import { constructFormPayloadForAPI } from '../../forms/ResponderHelpers';
import FormsService from '../../forms/state/forms.service';

const SubtaskService = {
  get: (query) => request({
    call: axios.get('/subtasks', { params: query }),
    errMsg: 'Could not get tasks',
    hideSuccessToast: true,
  }),
  create: (payload) => request({
    call: async () => {
      const newPayload = { ...payload };
      const { files } = newPayload;
      delete newPayload.files;
      const parsedFiles = await parseAndUploadFiles(files);
      newPayload.files = parsedFiles;

      if (payload.formData) {
        try {
          const { data: form } = await FormsService.submitForm(payload.formData, null, true);

          if (form) {
            delete newPayload.formData;
            newPayload.formId = form.id;
            newPayload.status = 'Done';
          }
        } catch (_) {
          // fail silently as it might be a template import
        }
      }

      // If we failed to submit the form, we still want to create the task with form data
      // This is due to importing a template with form, but not submitting the form
      if (newPayload.formData) {
        try {
          newPayload.formData = await constructFormPayloadForAPI({
            form: payload.formData,
            addSectionId: true,
          });
        } catch (e) {
          // fail silently as it might be a template import
        }
      }

      return axios.post('/subtask', newPayload);
    },
    errMsg: 'Could not create task',
  }),
  update: (id, payload) => request({
    call: async () => {
      const realPayload = { ...payload };
      const { addedFiles = [] } = payload;
      const parsedFiles = await parseAndUploadFiles(addedFiles);
      realPayload.addedFiles = parsedFiles;
      delete realPayload.files;

      if (payload.formData) {
        try {
          const { data: form } = await FormsService.submitForm(realPayload.formData, null, true);

          if (form) {
            delete realPayload.formData;
            realPayload.formId = form.id;
            realPayload.status = 'Done';
          }
        } catch (_) {
          // fail silently as it might be a template import
        }
      }

      // If we failed to submit the form, we still want to create the task with form data
      // This is due to importing a template with form, but not submitting the form
      if (realPayload.formData) {
        try {
          realPayload.formData = await constructFormPayloadForAPI({
            form: payload.formData,
            addSectionId: true,
          });
        } catch (e) {
          // fail silently as it might be a template import
        }
      }

      return axios.put(`/subtask/${id}`, realPayload);
    },
    errMsg: 'Could not update task',
  }),
  getFiles: (id) => request({
    call: axios.get(`/subtasks/${id}/files`, { params: { getSignedUrls: false } }),
    errMsg: 'Could not get task files',
    hideSuccessToast: true,
  }),
  delete: (id, isGroup) => request({
    call: axios.delete(`/subtasks/${id}`, { params: { isGroup } }),
    errMsg: 'Could not delete task',
  }),
  getReminders: (id) => request({
    call: axios.get(`/subtasks/${id}/reminders`),
    errMsg: 'Could not get task reminders',
    hideSuccessToast: true,
  }),
  createTemplate: (payload) => request({
    call: async () => {
      const newPayload = { ...payload };

      let newFormData = newPayload.formData;

      try {
        newFormData = await constructFormPayloadForAPI({
          form: payload.formData,
          addSectionId: true,
        });
      } catch (e) {
        // fail silently
      }

      newPayload.formData = newFormData;

      return axios.post('/subtasks/templates', newPayload);
    },
    errMsg: 'Could not create template',
  }),
  getTemplates: () => request({
    call: axios.get('/subtasks/templates'),
    errMsg: 'Could not create template',
    hideSuccessToast: true,
  }),
  updateTemplate: (id, payload) => request({
    call: async () => {
      const newPayload = { ...payload };
      if (payload.formData) {
        newPayload.formData = await constructFormPayloadForAPI({
          form: payload.formData,
          addSectionId: true,
        });
      }

      return axios.put(`/subtasks/templates/${id}`, newPayload);
    },
    errMsg: 'Could not update template',
  }),
  deleteTemplate: (id) => request({
    call: axios.delete(`/subtasks/templates/${id}`),
    errMsg: 'Failed to delete task template',
    successMsg: 'Task template deleted successfully',
  }),
  getFormData: (id) => request({
    call: async () => {
      const { data: form, err } = await axios.get(`/subtasks/${id}/formData`);
      if (!form) return { err };
      await decorateFormWithFiles(form);
      return { data: form };
    },
    errMsg: 'Could not get task form data',
    hideSuccessToast: true,
  }),
  getTemplateFormData: (id) => request({
    call: async () => {
      const { data: form, err } = await axios.get(`/subtasks/templates/${id}/formData`);
      if (!form) return { err };
      await decorateFormWithFiles(form);
      return { data: form };
    },
    errMsg: 'Could not get task template form data',
    hideSuccessToast: true,
  }),
  changeStatus: (id, status) => request({
    call: axios.put(`/subtask/${id}/status`, { status }),
    errMsg: 'Could not change task status',
  }),
};

export default SubtaskService;
