import { DateTime } from 'luxon';
import {
  GET_FILE_STRUCTURE,
  CREATE_FOLDERS,
  DELETE_FILE_ITEMS,
  UPLOAD_FILES,
  MOVE_FILES,
  LOAD_IMAGE,
  HIDE_IMAGE,
  DESCRIBE_FILE,
  CLOSE_FILE_DRAWER,
  GET_FILE_ACCESS_LIST,
  GRANT_FILE_ACCESS,
  REVOKE_FILE_ACCESS,
  TOGGLE_FILE_PUBLIC_STATUS,
  ADD_FILE_TO_FILEMAP,
  REMOVE_FILE_FROM_FILEMAP,
  CLEAR_FILEMAP,
  UPDATE_FILE_PERMISSIONS,
} from '../../state/actionTypes';

import {
  addItemsToStructure,
  deleteItems,
} from '../fileHelpers';

import Permissions from '../../auth/Permissions';

const MAX_STORED_FILES = 25;

const getNewFileMap = (fileMap) => {
  const fileIds = Object.keys(fileMap);
  const sortedFileIds = fileIds.sort((a, b) => fileMap[a].timestamp - fileMap[b].timestamp);
  const numFiles = sortedFileIds.length;

  if (numFiles <= MAX_STORED_FILES) {
    return { ...fileMap };
  }
  const newFileMap = {};
  for (let i = numFiles - MAX_STORED_FILES; i < numFiles; i += 1) {
    const fileId = sortedFileIds[i];
    newFileMap[fileId] = fileMap[fileId];
  }

  return newFileMap;
};

const initialState = {
  fileMap: {},
  files: {},
  fileStructure: {},
  selectedPhoto: {},
  rootFiles: [],
  fileDetails: {},
  fileAccessLists: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FILE_STRUCTURE: {
      const {
        payload: {
          data: {
            files = {},
            rootFiles = [],
          } = {},
        } = {},
      } = action;
      return {
        ...state,
        fileStructure: files,
        rootFiles,
      };
    }
    case CREATE_FOLDERS: {
      const {
        payload: {
          newFolders: nameMap = {},
          currentFolderIsPublic,
        } = {},
      } = action;
      const {
        fileStructure = {},
        rootFiles = [],
      } = state;
      const items = Object.keys(nameMap).map((path) => ({
        path,
        id: nameMap[path],
      }));
      return {
        ...state,
        ...addItemsToStructure({
          fileStructure,
          rootFiles,
          items,
          currentFolderIsPublic,
        }),
      };
    }
    case DELETE_FILE_ITEMS: {
      const {
        payload: {
          items = [],
        } = {},
      } = action;
      const {
        fileStructure = {},
        rootFiles = [],
      } = state;
      return {
        ...state,
        ...deleteItems({
          deletedItems: items,
          fileStructure,
          rootFiles,
        }),
      };
    }
    case UPLOAD_FILES: {
      const {
        payload: {
          files = [],
          currentFolderIsPublic,
        } = {},
      } = action;
      const {
        fileStructure = {},
        rootFiles = [],
        fileAccessLists = {},
      } = state;
      const newAccessLists = { ...fileAccessLists };
      files.forEach(({ id: fileId }) => {
        const {
          [fileId]: fileAcccessSet = new Set(),
        } = newAccessLists;
        fileAcccessSet.add(Permissions.id);
        newAccessLists[fileId] = fileAcccessSet;
      });
      return {
        ...state,
        ...addItemsToStructure({
          fileStructure,
          rootFiles,
          items: files,
          currentFolderIsPublic,
        }),
      };
    }
    case MOVE_FILES: {
      // Currently not used.
      return state;
    }
    case LOAD_IMAGE: {
      const {
        payload: {
          image,
          path,
          name,
        } = {},
      } = action;
      return {
        ...state,
        selectedPhoto: {
          image,
          path,
          name,
        },
      };
    }
    case HIDE_IMAGE: {
      return {
        ...state,
        selectedPhoto: {},
      };
    }
    case DESCRIBE_FILE: {
      return {
        ...state,
        fileDetails: action.payload.data,
      };
    }
    case CLOSE_FILE_DRAWER: {
      return {
        ...state,
        fileDetails: {},
      };
    }
    case GET_FILE_ACCESS_LIST: {
      const {
        payload: {
          fileId,
          accessList = [],
        } = {},
      } = action;
      const {
        fileAccessLists = {},
      } = state;
      const newAccessLists = { ...fileAccessLists };
      newAccessLists[fileId] = new Set(accessList);
      return {
        ...state,
        fileAccessLists: newAccessLists,
      };
    }
    case UPDATE_FILE_PERMISSIONS: {
      const {
        payload: {
          id: fileId,
          userIds = [],
        } = {},
      } = action;
      const {
        fileAccessLists = {},
      } = state;
      const newAccessLists = { ...fileAccessLists };
      newAccessLists[fileId] = new Set(userIds);
      return {
        ...state,
        fileAccessLists: newAccessLists,
      };
    }
    case TOGGLE_FILE_PUBLIC_STATUS: {
      const {
        payload: {
          path,
          isPublic,
          id,
        } = {},
      } = action;
      const {
        fileStructure = {},
        rootFiles = [],
      } = state;
      const newStruct = { ...fileStructure };
      if (path in newStruct) {
        newStruct[path].isPublic = isPublic;
      }
      const newRootFiles = rootFiles.map((file) => {
        if (file.id !== id) return file;
        return {
          ...file,
          isPublic,
        };
      });
      return {
        ...state,
        fileStructure: newStruct,
        rootFiles: newRootFiles,
      };
    }
    case ADD_FILE_TO_FILEMAP: {
      const {
        payload: {
          fileId,
          jsFileObject,
        } = {},
      } = action;
      const {
        fileMap = {},
      } = state;
      const now = DateTime.now().toMillis();
      jsFileObject.timestamp = now;
      const newFileMap = getNewFileMap(fileMap);
      newFileMap[fileId] = jsFileObject;
      return {
        ...state,
        fileMap: newFileMap,
      };
    }
    case REMOVE_FILE_FROM_FILEMAP: {
      const {
        payload: {
          fileId,
        } = {},
      } = action;
      const {
        fileMap = {},
      } = state;
      const newFileMap = { ...fileMap };
      delete newFileMap[fileId];
      return {
        ...state,
        fileMap: newFileMap,
      };
    }
    case CLEAR_FILEMAP: {
      return {
        ...state,
        fileMap: {},
      };
    }
    default:
      return state;
  }
};
