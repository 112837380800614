/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { Files } from 'ontraccr-common';
import FilePreview from './FilePreview';

import { getCroppedDimensions } from '../../helpers/photos';

import InvisibleButton from '../buttons/InvisibleButton';
import ProgressWheel from '../ProgressWheel';

import { visuallyDownloadFile } from '../../files/fileHelpers';

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB

const IMAGE_DIM = 100;
const SMALL_DIM = 50;

const getTimeText = (ts) => (
  DateTime
    .fromMillis(ts)
    .toLocaleString(DateTime.DATETIME_MED)
);

export default function PhotoReelPreview({
  rotation = 0,
  uri,
  file,
  type,
  shadow,
  onDelete,
  onClick,
  small,
  showCondensedView,
  locationMap = {},
  timestamp,
}) {
  const dispatch = useDispatch();

  const cursor = onClick ? 'pointer' : 'auto';
  const ourDim = small ? SMALL_DIM : IMAGE_DIM;
  const globalFileMap = useSelector((state) => state.files.fileMap);
  const [style, setStyle] = useState({ });
  const [loaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0);

  const loading = progress > 0 && progress < 100;

  const trueId = file?.id || file?.autoSaveId;
  const globalFile = file instanceof File ? file : globalFileMap[trueId];

  useEffect(() => {
    setLoaded(false);
  }, [file, uri]);

  const onImageLoad = ({ target: img }) => {
    if (!loaded) {
      setLoaded(true);
      setStyle(getCroppedDimensions({
        img,
        scale: 8,
        height: ourDim,
        width: ourDim,
      }));
    }
  };

  const updateTimeText = useMemo(() => (
    timestamp
      ? getTimeText(timestamp)
      : null
  ), [timestamp]);

  const getLocations = (id) => {
    let locationString = '';
    if (!id || !locationMap[id]) {
      return locationString;
    }
    if (locationMap[id]) {
      locationMap[id].forEach((obj) => {
        const appendedString = `${locationString} ${obj.location}`;
        if (obj.timestamp) {
          locationString = `${appendedString} ${getTimeText(obj.timestamp)},`;
        } else {
          locationString = `${appendedString},`;
        }
      });
    }
    return `Location(s): ${locationString}`;
  };

  const handleClick = async (e) => {
    e.stopPropagation();
    if (!onClick) return;
    if (!globalFile || !(globalFile instanceof File)) {
      visuallyDownloadFile({
        dispatch,
        setProgress,
        file,
      }).then((success) => {
        if (success) {
          onClick();
        }
      });
      return;
    }
    onClick();
  };

  const getChildView = () => {
    if (loading) {
      return <ProgressWheel percentage={progress} style={{ position: 'absolute' }} />;
    }

    let showPreview = false;
    if ((!globalFile || !(globalFile instanceof File)) && !file?.thumbnailURL) showPreview = true;
    else if (globalFile && globalFile instanceof File) {
      const { size } = globalFile;
      if (size > MAX_FILE_SIZE) showPreview = true;
    }

    if (showPreview) {
      return (
        <FilePreview
          file={file}
          onClick={handleClick}
          small={small}
          location={getLocations(file?.id)}
          style={{
            paddingTop: 10,
          }}
        />
      );
    }

    let fileType = globalFile?.type ?? file?.type ?? file?.jsFileObject?.type;

    if (!fileType && file?.name) {
      fileType = Files.getFileType(file);
    }

    switch (fileType) {
      case 'image':
      case 'image/png':
      case 'image/jpeg':
      case 'image/svg+xml': {
        const src = (globalFile && globalFile instanceof File && !file.thumbnailURL)
          ? URL.createObjectURL(globalFile)
          : file.thumbnailURL;
        return (
          <Tooltip placement="bottom" title={getLocations(file?.id)}>
            <InvisibleButton
              onClick={handleClick}
              style={{
                height: '100%',
              }}
            >
              <img
                className={small ? 'photo-preview-small' : 'photo-preview'}
                src={src}
                key={file?.id}
                alt="Post"
                onLoad={onImageLoad}
                style={{
                  ...style,
                  cursor,
                  width: '100%',
                  height: '100%',
                  transform: `rotate(${rotation}deg)`,
                  top: 0,
                  objectFit: 'cover',
                }}
              />
            </InvisibleButton>
          </Tooltip>
        );
      }
      default:
        return (
          <FilePreview
            file={file}
            onClick={handleClick}
            small={small}
            location={getLocations(file?.id)}
            style={{
              paddingTop: 10,
            }}
          />
        );
    }
  };

  const width = showCondensedView ? ourDim : ourDim + 10;
  const height = showCondensedView ? ourDim : ourDim + 20;
  return (
    <div
      key={uri}
      style={{
        height: height + (timestamp ? 40 : 0),
        width,
        borderRadius: 4,
      }}
    >
      <div
        className={`photo-preview-container ${shadow ? 'photo-shadow' : ''}`}
        style={{
          height: ourDim,
          width: ourDim,
        }}
      >
        {uri && type === 'image' ? (
          <InvisibleButton
            onClick={onClick}
            style={{
              height: '100%',
            }}
          >
            <img
              className={small ? 'photo-preview-small' : 'photo-preview'}
              src={globalFile instanceof File ? URL.createObjectURL(globalFile) : uri}
              key={uri}
              alt="Post"
              onLoad={onImageLoad}
              style={{
                ...style,
                cursor,
              }}
            />
          </InvisibleButton>
        ) : getChildView()}
      </div>
      {
        updateTimeText
        && (
          <div style={{ width, fontSize: 10, textAlign: 'center' }}>
            {updateTimeText}
          </div>
        )
      }
      {onDelete && (
      <Button
        className="photo-preview-delete"
        icon={
          <CloseOutlined className="photo-preview-delete-icon" />
        }
        onClick={onDelete}
      />
      )}
    </div>
  );
}

PhotoReelPreview.propTypes = {
  showCondensedView: PropTypes.bool,
  shadow: PropTypes.bool,
  small: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  file: PropTypes.shape({
    id: PropTypes.string,
    autoSaveId: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string,
    thumbnailURL: PropTypes.string,
    jsFileObject: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  uri: PropTypes.string,
  type: PropTypes.string,
  locationMap: PropTypes.shape({}),
  timestamp: PropTypes.number,
  rotation: PropTypes.number,
};

PhotoReelPreview.defaultProps = {
  showCondensedView: false,
  shadow: true,
  small: false,
  onClick: null,
  onDelete: null,
  file: null,
  uri: null,
  type: null,
  locationMap: {},
  timestamp: null,
  rotation: 0,
};
