import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { getMaterialIcon } from './materialsHelpers';

function MaterialSearchDropdownItem({
  item: {
    name,
    partNumber,
    description,
    isFolder,
    isAssembly,
  } = {},
}) {
  return (
    <>
      <Row gutter={4}>
        <Col>
          {getMaterialIcon({ isFolder, isAssembly })}
        </Col>
        <Col>
          <div className="board-search-item-title">{name}</div>
        </Col>
      </Row>
      <div>
        {partNumber}
      </div>
      <div>
        {description}
      </div>
    </>
  );
}

MaterialSearchDropdownItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    number: PropTypes.number,
    board: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default MaterialSearchDropdownItem;
