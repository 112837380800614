import axios from 'axios';
import { request } from '../../helpers/requests';
import { parseAndUploadFiles } from '../../files/fileHelpers';
import { formatPayloadCustomData } from '../../equipment/state/equipment.service';

const formatCertificationPayload = async (payload, files) => {
  const fullPayload = { ...payload };
  const {
    customData: formattedCustomData,
    files: formattedFiles,
  } = await formatPayloadCustomData(payload.customData);
  fullPayload.customData = formattedCustomData;

  const parsedFiles = await parseAndUploadFiles(files);
  fullPayload.files = parsedFiles.concat(
    formattedFiles.map((file) => ({ ...file, isFormFile: true })),
  );
  return fullPayload;
};

const CertificationService = {
  getAllCertifications: (entityType) => request({
    call: axios.get(`/${entityType}/certifications`, { params: { getCustomData: true } }),
    errMsg: 'Could not get certifications',
    hideSuccessToast: true,
  }),
  getAllCertificationsForEntity: (entityType, id) => request({
    call: axios.get(`/${entityType}/${id}/certifications`, { params: { getCustomData: true } }),
    hideSuccessToast: true,
    errMsg: `Could not get ${entityType?.slice(0, -1)} certifications`,
  }),
  getCertification: (entityType, id, certificationId) => request({
    call: axios.get(`/${entityType}/${id}/certifications/${certificationId}`),
    errMsg: `Could not get ${entityType?.slice(0, -1)} certification`,
    hideSuccessToast: true,
  }),
  createCertification: (entityType, id, payload, files) => request({
    call: async () => {
      const formattedPayload = await formatCertificationPayload(payload, files);
      return axios.post(`/${entityType}/${id}/certifications`, formattedPayload, { params: { getCustomData: true } });
    },
    errMsg: `Could not create ${entityType?.slice(0, -1)} certification`,
  }),
  updateCertification: (entityType, id, certificationId, payload, files) => request({
    call: async () => {
      const formattedPayload = await formatCertificationPayload(payload, files);
      return axios.put(`/${entityType}/${id}/certifications/${certificationId}`, formattedPayload, { params: { getCustomData: true } });
    },
    errMsg: `Could not update ${entityType?.slice(0, -1)} certification`,
  }),
  deleteCertification: (entityType, id, certificationId) => request({
    call: axios.delete(`/${entityType}/${id}/certifications/${certificationId}`, { params: { getCustomData: true } }),
    errMsg: `Could not delete ${entityType?.slice(0, -1)} certification`,
  }),
  getCertificationCustomFieldTemplate: (entityType) => request({
    call: axios.get(`/${entityType?.slice(0, -1)}certifications/customFieldTemplate`),
    errMsg: `Failed to get ${entityType?.slice(0, -1)} certification custom field template`,
    hideSuccessToast: true,
  }),
  updateCertificationCustomFieldTemplate: (entityType, payload) => request({
    call: axios.post(`/${entityType?.slice(0, -1)}certifications/customFieldTemplate`, payload),
    errMsg: `Failed to update ${entityType?.slice(0, -1)} certification custom field template`,
  }),
  getCertificationCustomData: (entityType, id) => request({
    call: axios.get(`/${entityType?.slice(0, -1)}certifications/${id}/data`, { params: { getSignedUrls: false } }),
    errMsg: `Failed to get ${entityType?.slice(0, -1)} certification custom data`,
    hideSuccessToast: true,
  }),
};

export default CertificationService;
