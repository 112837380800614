import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, message, DatePicker, Table,
} from 'antd';
import axios from 'axios';
import moment from 'moment';

import { logColumns } from './qbAuditLog.constants';

const getDefaultRange = () => [moment().subtract(7, 'd'), moment()];
function QBAuditLogDrawer({
  visible,
  onClose,
}) {
  const [range, setRange] = useState(getDefaultRange());
  const [data, setData] = useState([]);

  useEffect(() => {
    const getLog = async () => {
      try {
        const { data: newData } = await axios.get('/quickbooks/log', {
          params: {
            startTime: range[0].valueOf(),
            endTime: range[1].valueOf(),
          },
        });
        setData(newData ?? []);
      } catch (err) {
        message.error('Failed to get QuickBooks log');
        setData([]);
      }
    };
    if (visible
        && range?.length === 2
        && moment.isMoment(range[0])
        && moment.isMoment(range[1])
    ) getLog();
  }, [range, visible]);

  const expandedRowRender = useCallback((record) => {
    const body = Array.isArray(record.response)
      ? (
        record.response?.map((res) => (
          <div>- {res}</div>
        )) ?? ''
      ) : record.response ?? '';
    return (
      <p style={{ margin: 0, marginLeft: 70 }}>
        {body}
      </p>
    );
  }, []);

  return (
    <Drawer
      title="QuickBooks Log"
      visible={visible}
      width={1300}
      onClose={onClose}
      style={{
        height: '100%',
      }}
      bodyStyle={{ padding: 0 }}
    >
      <DatePicker.RangePicker
        style={{ width: 250, margin: 20 }}
        format="MMM Do YY"
        disabledDate={(current) => current > moment().endOf('day')}
        value={range}
        onChange={setRange}
        getPopupContainer={(node) => (node ? node.parentNode : document.body)}
      />
      <Table
        rowKey="id"
        dataSource={data}
        columns={logColumns}
        pagination={{
          hideOnSinglePage: true,
        }}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => (
            record.status !== 'Succeeded'
            && !!record.response
          ),
        }}
      />
    </Drawer>
  );
}

QBAuditLogDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

QBAuditLogDrawer.defaultProps = {
  visible: false,
};

export default QBAuditLogDrawer;
