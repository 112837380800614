/* eslint-disable */
import React from 'react';
import { message, Tabs } from 'antd';

import RolePermissionCard from './RolePermissionCard';
import PositionCreateModal from './PositionCreateModal';
import DeleteRoleModal from './DeleteRoleModal';

import { defaultRoles } from './permissionConstants';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import DelayedEdit from '../common/editable/DelayedEdit';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

const { TabPane } = Tabs;

const sortRoles = (positions) => {
  const positionNames = Object.keys(positions);
  const customRoles = positionNames.filter((name) => !defaultRoles.has(name));
  return Array.from(defaultRoles).concat(customRoles.sort());
};

export default class RolePermissions extends React.Component {
  constructor(props) {
    super(props);
    const {
      positions = {},
      renamePosition,
    } = this.props;
    this.state = {
      activeKey:'0',
      positions,
    };

    this.onTabChanged = this.tabChanged.bind(this);
    this.onCreatePosition = this.createPosition.bind(this);
    this.onPositionCreateCancel = this.positionCreateCancel.bind(this);
    this.onPositionCreateSubmit = this.positionCreateSubmit.bind(this);
    this.onNewPositionNameChange = this.newPositionNameChanged.bind(this);
    
    this.onNameChanged = this.changePositionName.bind(this);
    this.cancelNameChange = this.undoPositionNameChange.bind(this);
    this.onDelete = this.deleteRole.bind(this);
    this.closeModal = this.closeDeleteModal.bind(this);
    this.onNewRoleChanged = this.newRoleChanged.bind(this);
    this.onRoleDelete = this.submitDelete.bind(this);


    this.delayedEdit = new DelayedEdit({
      onExecute:async (type,newName) => {
        const oldName = this.nameMap[newName];
        if(await renamePosition({ oldName, newName})) {
          delete this.nameMap[newName];
        }
      },
      onCancel:this.cancelNameChange,
    });

    this.nameMap = {};
  }

  componentDidUpdate(prevProps) {
    const {
      positions = {},
    } = this.props;
    const {
      positions:prevPositions,
    } = prevProps;
    if(prevPositions !== positions) {
      this.setState({
        positions,
      });
    }
  }

  tabChanged(tab) {
    const {
      positions = {},
    } = this.props;
   
    const numPositions = Object.keys(positions).length;

    if(tab === numPositions.toString()) return;
    this.setState({
      activeKey:tab,
    });
  }

  createPosition() {
    PositionCreateModal({
      textInput:<OnTraccrTextInput
        placeholder='Enter position name'
        style={{
          textAlign:'center'
        }}
        onChange={this.onNewPositionNameChange}
      />,
      onOk:this.onPositionCreateSubmit,
      onCancel:this.onPositionCreateCancel,
    });
  }

  newPositionNameChanged(event) {
    const {
      target:{
        value: newPositionName,
      } = {}
    } = event;
    this.setState({
      newPositionName: newPositionName.trim(),
    });
  }

  async positionCreateSubmit() {
    const {
      createPosition,
    } = this.props;
    const {
      newPositionName,
    } = this.state;
    if (!newPositionName) return;
    if (newPositionName.includes('/')) {
      message.error('Role name cannot include "/"');
      return;
    }
    if (await createPosition(newPositionName)) {
      this.positionCreateCancel();
    }
  }

  positionCreateCancel() {
    this.setState({
      newPositionName:null,
    });
  }

  changePositionName(oldName,newName) {
    if(oldName === newName) return;
    let ourNewName = newName.length > 0 ? newName : oldName;

    const {
      positions = {}
    } = this.state;
    const newPositions = {};
    Object.keys(positions).forEach((name) => {
      const key = name === oldName ? ourNewName : name;
      newPositions[key] = positions[name];
    });

    this.setState({
      positions:newPositions,
    });
    this.delayedEdit.trigger('name',newName);
    this.nameMap[newName] = oldName;
  }

  undoPositionNameChange(type,newName) {
    const {
      positions = {}
    } = this.state;
    const {
      positions:propPositions = {},
    } = this.props;
    if(Object.keys(propPositions).length === 0) return;
    const newPositions = {};
    Object.keys(positions).forEach((name) => {
      const key = name === newName ? this.nameMap[newName] : name;
      newPositions[key] = propPositions[key];
    });

    this.setState({
      positions:newPositions,
    });
    delete this.nameMap[newName];
  }

  deleteRole(role) {
    const {
      users = [],
    } = this.props;
    const releventUsers = users.filter((user) => user.position === role);
    if(releventUsers.length > 0 ) {
      this.setState({
        selectedRole:role,
        showDeleteModal:true,
        affectedUsers:releventUsers,
      });
    } else {
      this.setState({
        selectedRole:role,
      });
      CustomConfirmModal({
        title:`Delete ${role} role?`,
        okText:'Delete',
        onOk:this.onRoleDelete,
      });
    }
  }

  closeDeleteModal() {
    this.setState({
      showDeleteModal:false,
      newRole:undefined,
    });
  }

  newRoleChanged(newRole) {
    this.setState({
      newRole,
    });
  }

  async submitDelete() {
    const {
      selectedRole,
      newRole,
    } = this.state;
    if(await this.props.deletePosition({
      position:selectedRole,
      newRole,
    })) {
      this.closeDeleteModal();
      this.setState({
        activeKey:'0',
      });
    }
  }

  render() {
    const {
      resetPermissions,
      onCheckChanged,
      settings = {},
    } = this.props;
    const {
      activeKey,
      positions = {},
      showDeleteModal,
      selectedRole,
      affectedUsers = [],
      newRole,
    } = this.state;
    const positionNames = sortRoles(positions);
    return (
      <div>
        <Tabs 
          defaultActiveKey='0' 
          style={{ height: '90vh' }}
          tabPosition={'left'} 
          tabBarStyle={{
            marginTop:15,
            width: 135,
          }}
          onChange={this.onTabChanged}
          activeKey={activeKey}
        >
          {positionNames.map((role,index) => 
            <TabPane tab={role} key={index}>
              <RolePermissionCard
                roleName={role}
                settings={settings}
                resetPermissions={() => resetPermissions(role)}
                onDelete={() => this.onDelete(role)}
                onCheckChanged={(permission) => onCheckChanged(role,permission)}
                onNameChanged={(type,value) => this.onNameChanged(role,value)}
              />
            </TabPane>
          )}
          <TabPane key={positionNames.length} tab={ <OnTraccrButton
            title='Add Role'
            type='primary'
            style={{
              marginTop:0,
            }}
            onClick={this.onCreatePosition}
          />}/>
         
        </Tabs>
        <DeleteRoleModal
          visible={showDeleteModal}
          role={selectedRole}
          onCloseClicked={this.closeModal}
          users={affectedUsers}
          roleNames={positionNames}
          onNewRoleChange={this.onNewRoleChanged}
          newRole={newRole}
          onSubmit={this.onRoleDelete}
        />
      </div>
    );
  }
}
