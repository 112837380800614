import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import WorkflowTag from '../forms/FormWorkflows/WorkflowTag';
import DisplayText from '../common/text/DisplayText';
import { getIdMap } from '../helpers/helpers';

//eslint-disable-next-line no-useless-escape
const emailRegex = new RegExp(/^[^@\s]+@[^@\s\.]+(?:\.[^@\.\s]+)+$/);

export default ({
  isDisplay,
  value,
  onChange: propOnChange,
}) => {
  const users = useSelector(state => state.users.users);

  const userMap = useMemo(() => getIdMap(users),[users]);
  const userOpts = useMemo(() => (
    users.filter((user) => user.active)
      .map((user) => ({ value: user.id, label: user.name }))
  ),[users]);
  const userIdSet = useMemo(() => new Set(users.map((user) => user.id)),[users]);
  const onChange = (selectedValues) => {
    propOnChange(
      selectedValues.filter((emailOrId) => emailRegex.test(emailOrId) || userIdSet.has(emailOrId))
    );
  };

  const displayText = useMemo(() => {
    if (!value) return '';
    const parts = value.map((val) => {
      if (val && val.includes('@')) return val;
      const {
        [val]: { name } = {},
      } = userMap;
      return name;
    });
    return parts.join(', ');
  },[userMap, value]);

  if (isDisplay) return <DisplayText title={displayText}/>;

  return (
    <Select
      showSearch
      tokenSeparators={[',','\n']}
      mode='tags'
      style={{ width: '100%' }}
      onChange={onChange}
      value={value}
      optionLabelProp='label'
      optionFilterProp='label'
      options={userOpts}
      tagRender={(props) =>  (
        <WorkflowTag
        closable={!isDisplay}
        itemProps={props}
        label={props.label}
        type='email'
      />
    )}
    />
  );
};
