import { DatabaseOutlined, FolderOutlined, GoldOutlined } from '@ant-design/icons';
import { getIdMap } from '../helpers/helpers';

export default {};

export const hasMarkup = (markup) => markup || markup === 0;
export const getMarkupPercent = (markup) => (hasMarkup(markup) ? markup * 100 : markup);
export const getMarkupString = (markup) => (hasMarkup(markup) ? `${(markup * 100).toFixed(2)} %` : '');

export const getPriceFloat = (cost, markup, discount = 0) => (
  parseFloat((cost * (1 + (markup / 100)) * (1 - discount)).toFixed(2))
);
export const getPrice = (cost, markup) => `$${getPriceFloat(cost, markup).toFixed(2)}`;
export const changeIsNumber = (change) => (
  change !== null
  && change !== undefined
  && Number.isFinite(change)
);

export const prepareMaterialLocations = ({
  oldLocations = [],
  newLocations = [],
}) => {
  const oldMap = getIdMap(oldLocations);
  const newMap = getIdMap(newLocations);

  const deletedLocations = [];
  const updatedLocations = [];
  const createdLocations = [];

  oldLocations.forEach((oldLoc) => {
    const {
      id,
      quantity: oldQuant,
      quantityAllocated: oldQuantAlloc,
      equipmentId: oldEqId,
      globalMaterialLocationsId: oldGloLocId,
    } = oldLoc;
    if (!(id in newMap)) {
      deletedLocations.push(id);
    } else {
      const {
        [id]: {
          quantity: newQuant,
          quantityAllocated: newQuantAlloc,
          equipmentId: newEqId,
          globalMaterialLocationsId: newGloLocId,
        } = {},
      } = newMap;

      if (
        oldQuant !== newQuant
        || oldEqId !== newEqId
        || oldGloLocId !== newGloLocId
        || oldQuantAlloc !== newQuantAlloc
      ) {
        updatedLocations.push({
          id,
          quantity: newQuant,
          quantityAllocated: newQuantAlloc,
          equipmentId: newEqId,
          globalMaterialLocationsId: newGloLocId,
        });
      }
    }
  });

  newLocations.forEach((newLoc) => {
    const {
      id, quantity, quantityAllocated, equipmentId, isNew, globalMaterialLocationsId,
    } = newLoc;
    if (
      (!(id in oldMap) || isNew)
      && (equipmentId || globalMaterialLocationsId)
      && (quantity || quantity === 0)
      && (quantityAllocated || quantityAllocated === 0)
    ) {
      createdLocations.push({
        quantity,
        quantityAllocated,
        equipmentId,
        globalMaterialLocationsId,
      });
    }
  }, []);
  return {
    deletedLocations,
    updatedLocations,
    createdLocations,
  };
};

export const getMaterialIcon = (record) => {
  if (record.isFolder) return <FolderOutlined />;
  if (record.isAssembly) return <DatabaseOutlined />;
  return <GoldOutlined />;
};

export const getRelevantDeleteWarning = (
  materials,
  materialIds,
) => {
  const materialIdSet = new Set(materialIds);
  const relevantMaterials = new Set();
  const relevantAssemblyMaterials = Object.values(materials).filter((material) => (
    material.assemblyMaterials?.some((assemblyMaterial) => {
      if (materialIdSet.has(assemblyMaterial.materialId)) {
        relevantMaterials.add(assemblyMaterial.materialId);
        return true;
      }

      return false;
    })
  ));

  return relevantAssemblyMaterials.length > 0
    ? (
      <>
        <div>
          The following materials are used in {relevantAssemblyMaterials.length} assemblies:
        </div>
        <ul>
          {Array.from(relevantMaterials).map((materialId) => (
            <li key={materialId}>{materials[materialId].name}</li>
          ))}
        </ul>
      </>
    )
    : null;
};
