/* eslint-disable */
import React from "react"
import {
  Row,
  Col,
  Input,
  Pagination,
  Popover,
  Dropdown,
  Button,
  Badge,
  Switch,
  Spin
} from 'antd';
import { PlusOutlined, MoreOutlined, DownOutlined } from '@ant-design/icons';

import { getSortMenu } from './helpers';

import AddDrawer from '../addDrawer';
import OnTraccrButton from '../buttons/OnTraccrButton';

import CardGridItem from './CardGridItem';
import CardGridAddPopover from './CardGridAddPopover';

import OnTraccrEmpty from '../OnTraccrEmpty';
import ListView from "../listview";
import { search } from '../../helpers/helpers';
const last = function last(array) {
  return array[array.length -1];
};

const splitArray = function splitArray(array,size) {
  let i = 0;
  const res = [];
  if(size === 0) return res;
  while ( i < array.length) {
    res.push(array.slice(i,Math.min(i+size,array.length)));
    i += size;
  }
  return res;
};

export default class CardGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrawer:false,
      showEditDrawer:false,
      dataSource:props.dataSource,
      currentPage:0,
      numCols:3,
      numRows:2,
    };

    this.searching = null;
    this.currentPage = 0;
    const { pageSize = 16 } = props;
    this.pageSize = pageSize;
    this.dataSource = [];
    this.lastActivePage = [];

    this.onEditClose = this.editClosed.bind(this);
    this.onResize = this.getDimensions.bind(this);
    this.onDrawerClose = this.closeDrawer.bind(this);

    this.lastPagination = 0;
  }

  displayItem(item) {
    const {
      showEditDrawer,
    } = this.state;
    if (showEditDrawer) return;
    this.setState({
      editProps:{ editing:false,...item,key:item.id },
      showEditDrawer:true,
      newAdd:Math.floor(new Date()),
    })
  }

  async closeDrawer(values) {
    if(!values || (this.props.add && await this.props.add.onClose(values))) {
      this.setState({
        showDrawer:false,
      });
    }
  }

  editClosed(id,values) {
    if(this.props.edit) return this.props.edit.onClose(id,values);
    return false;
  }

  onSearch(e) {
    const val = e.target.value;
    this.searchFilter(val);
  }

  searchFilter(val) {
    const {
      search:searchProps = {},
    } = this.props;
    if(val) {
      this.searching = val;
      const relevant = search({
        data:this.props.dataSource,
        value:val,
        getField: searchProps.getField ? searchProps.getField : (item) => item.name,
        customSearchRanking: searchProps.customRanking,
        searchTieBreaker: searchProps.tieBreaker
      });

      this.setPages(relevant);
      this.setState({
        dataSource:relevant,
        currentPage:0,
      });
      this.currentPage = 0;
      this.setCurrentPage();
    } else {
      this.setPages();
      this.setCurrentPage();
      this.searching = null;
      this.setState({
        dataSource:this.props.dataSource,
      });
    }
  }

  setPages(source = this.props.dataSource,pageSize = this.getPageSize()) {
    const {
      scrollable = false,
    } = this.props;
    this.dataPages = scrollable ? [source] : splitArray(source,pageSize);
    if(this.targetItem && this.dataPages.length > 0) {
      this.scrollToItem(this.targetItem);
    }
  }

  getUpdatedActivePageIndex() {
    const prevData = this.dataSource;
    const newData = this.props.dataSource;
    if(prevData.length >= newData.length) return null;

    const prevDataIds = new Set(prevData.map(data => data.id));
    const newDataFiltered = newData.filter(data => !prevDataIds.has(data.id));
    if (newDataFiltered === 0) return this.currentPage;
    const newItem = newDataFiltered[0];
    const newPage = this.dataPages.findIndex((page) => {
      return page.some((item) => item.id === newItem.id);
    });

    return newPage || null;
  }

  componentDidUpdate() {
    if(!this.searching) {
      this.setPages();
    } else if(this.targetItem && this.dataPages.length > 0) {
      this.scrollToItem(this.targetItem);
    }
    if(this.props.dataSource !== this.dataSource && !(this.props.dataSource.length === 0 && this.dataSource.length === 0)) {
      const newPage = this.getUpdatedActivePageIndex();
      this.setState({
        dataSource:this.props.dataSource
      });
      this.dataSource = this.props.dataSource;
      if(this.dataPages.length > 0) {
        this.currentPage = Math.min(newPage || this.currentPage,this.dataPages.length - 1);

        this.setState({
          activePage:this.dataPages[this.currentPage],
        });
      } else {
        this.setState({
          activePage: [],
        });
      }
      this.searchFilter(this.searching);
    }
  }


  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }
    this.setPages();
    if(this.dataPages.length > 0) {
      this.setState({
        activePage:this.dataPages[0],
      });
    }
    window.addEventListener('resize', this.onResize);
    this.getDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize',this.onResize);
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  getPageSize() {
    return this.state.numCols * this.state.numRows;
  }

  scrollToItem(item) {
    let targetPage = this.dataPages.findIndex((page) => (
      page.find((pageItem) => pageItem.id === item.id)
    ));

    if(targetPage < 0 || this.currentPage === targetPage) return;
    this.currentPage = targetPage;
    this.setCurrentPage();
    this.targetItem = null;
  }

  setCurrentPage() {
    this.setState({
      activePage:this.dataPages[this.currentPage],
      currentPage:this.currentPage,
    });
  }

  onPageChange(page) {
    const now = Math.floor(new Date());
    if(now - this.lastPagination < 500) return;
    this.lastPagination = now;
    this.currentPage = page - 1;
    this.setCurrentPage();
  }

  getDimensions() {
    if(!this.container) return;
    const {
      clientHeight:containerHeight,
      clientWidth:containerWidth,
    } = this.container;

    const {
      itemDimensions:{
        width:itemWidth = 285,
        height:itemHeight=  205,
      } = {},
    } = this.props;

    const hGutter = 8;
    const vGutter = 16;


    const numCols = parseInt(Math.floor((containerWidth + hGutter) / (itemWidth + hGutter)));
    const numRows = parseInt(Math.floor((containerHeight + vGutter)/(itemHeight + vGutter)));

    /*
      If the download bar is shown in chrome
      containerHeight and containerWidth will be zero
      No idea why.
    */
    if (numCols === 0 || numRows === 0) return;

    if(numCols !== this.state.numCols || numRows !== this.state.numCols) {
      this.setState({
        numCols,
        numRows,
      });
      this.setPages(this.state.dataSource,numCols * numRows);
      this.setCurrentPage();
    }
  }

  handleClick = (props) => {
    this.setState({
      editProps: { editing: false, ...props, key: props.id },
      showEditDrawer: true,
      newAdd: Math.floor(new Date()),
    });
  };

  getContent() {
    const MyItems = this.props.itemView;
    const {
      itemDimensions,
      scrollable,
      itemProps = {},
      isLoading,
      loadingIds,
    } = this.props;
    const {
      numCols:colCount,
      numRows,
      activePage = [],
    } = this.state;

    if(
      activePage === this.lastActivePage
      && !scrollable
      && this.lastLoadingIds === loadingIds
    ) return this.cachedContent;
    this.lastActivePage = activePage;

    const spinner = <Spin
      size="medium"
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100px',
        height: '100px',
      }}
    />

    const empty = <OnTraccrEmpty/>;

    let myContent = isLoading ? spinner : empty;

    if(activePage && activePage.length > 0) {
      const {
        clientHeight:containerHeight,
      } = this.container;

      const {
        height:itemHeight =  205,
      } = itemDimensions;

      let myCols = [[],];

      activePage.forEach((data,idx) => {
        const lastItem = last(myCols);
        // Inject dataSource item values and dimensions as props.
        const cardItemProps = {
          ...itemProps,
          ...data,
          ...{
            style:{
              ...itemDimensions,
            }
          }
        };
        lastItem.push(
          <Col key={idx.toString()}>
            <CardGridItem>
              <MyItems {...cardItemProps}
                key={cardItemProps.id}
                onClick={this.handleClick}
                idx={idx}
                onArchive={this.props.onArchive}
                onDelete={this.props.onDelete}
                onEdit={(props) => {
                  this.setState({
                    editProps:{ editing:true,...props,key:props.id },
                    showEditDrawer:true,
                    newAdd:Math.floor(new Date()),
                  });
                }}
                loadingIds={loadingIds}
              />
              </CardGridItem>
          </Col>
        );
        if (lastItem.length === colCount && idx !== activePage.length - 1) {
          myCols.push([]);
        }
      });
      const lastItem = last(myCols);
      while (lastItem.length !== 0 && lastItem.length < colCount) {
        lastItem.push(<div style={{...itemDimensions}} key={`dummyItem-${lastItem.length}`}/>)
      }


      const margin = (
        containerHeight
        ? (containerHeight - (numRows * itemHeight))/(numRows + 1)
        : 20
      );
      myContent = [];
      myCols.forEach((cols,idx, arr) => {
        myContent.push(
          <Row
            key={idx.toString()}
            type='flex'
            justify='space-around'
            style={{
              width:'100%',
              height:itemHeight,
              marginTop:idx === 0 ? margin : margin/2,
              marginBottom: idx === arr.length - 1 ? margin : margin/2,
            }}>
              {cols}
          </Row>
        );
      });
    }
    this.cachedContent = myContent;
    this.lastLoadingIds = loadingIds;
    return myContent;
  }

  render() {
    const {
      more,
      sort = {},
      showMoreBadge,
      filter,
      filterActive,
      add = {},
      edit = {},
      scrollable,
      extra,
      onToggleListViewSwitch = false,
      isListView,
      onViewTypeChanged,
      displayColumns,
      loadingIds,
    } = this.props;

    const {
      sortOptions = [],
      sortActive,
    } = sort;

    const  {
      numberOfSteps = 1,
      disabled = false,
      disabledPopoverContent,
      disabledPopoverTitle,
    } = add || {};

    const {
      canEdit = () => true,
      canArchive = () => true,
      canDelete = () => true,
      numberOfSteps:editSteps = 1,
      getTitle: getEditTitle,
    } = edit;

    let myAdd = '';
    if (add) {
      myAdd = (
        <CardGridAddPopover
          disabled={disabled}
          title={disabledPopoverTitle}
          content={disabledPopoverContent}
        >
          <OnTraccrButton
            title='Add'
            icon={<PlusOutlined/>}
            style={{ marginRight: extra ? 0 : 24 }}
            onClick={() => {
              if(disabled) return;
              this.setState({
                showDrawer:true,
                newAdd:Math.floor(new Date()),
              });
            }}
          />
        </CardGridAddPopover>
      );
    }

    const {
      editProps = {},
      activePage = [],
    } = this.state;
    const isEdit = editProps.editing;

    let editTitle  = isEdit ? this.props.edit.title : this.props.edit.title.replace('Edit ','');
    if (getEditTitle) editTitle = getEditTitle(editProps);
    return (
      <div
        style={{height:'100%',width:'100%'}}
      >
        <Row type="flex" justify="start" gutter={14} style={{height:32,marginBottom:10}}>
          <Col span={add ? 5 : 7}>
            <Input.Search
              className='searchbar'
              placeholder="Search"
              onChange={this.onSearch.bind(this)}
              allowClear
            />
          </Col>

          <Col flex={add ? '80px' : '0px'}>
            {myAdd}
          </Col>

          {
            extra &&
            <Col span={2}>
              {extra}
            </Col>
          }

          <Col flex='auto'>
            <Row align='middle' justify="end" style={{ width:'100%'}} gutter={12}>
              {onToggleListViewSwitch && <Col>
                {isListView ? 'List' : 'Card'} View
                <Switch
                  checked={isListView}
                  onChange={onViewTypeChanged}
                  style={{ marginLeft: 5 }}
                />
              </Col>}
              <Col>
                {filter &&
                  <Popover trigger='click' placement='bottomRight' content={filter}>
                    <OnTraccrButton title='Filter' type={filterActive ? 'primary' : 'back'}/>
                  </Popover>
                }
              </Col>
              <Col>
                  {sortOptions.length > 0 && !this.searching && <Dropdown overlay={getSortMenu(sortOptions,sortActive)}>
                    <Button className='OnTraccrBtn-cancel'>
                      {sortActive} <DownOutlined/>
                    </Button>
                  </Dropdown>}

              </Col>
              <Col>
                {!scrollable && !isListView && <Pagination
                  showSizeChanger={false}
                  defaultCurrent={1}
                  current={this.state.currentPage + 1}
                  total={this.state.dataSource.length}
                  pageSize={this.getPageSize()}
                  hideOnSinglePage
                  onChange={this.onPageChange.bind(this)}
                />}
              </Col>
              <Col>
                {more &&
                  <Popover trigger='click' placement='bottomRight' content={more}>
                    <Badge dot count={showMoreBadge ? 1 : 0}>
                      <MoreOutlined
                        style={{ fontSize:25, height: 30, paddingTop:2.5}}
                      />
                    </Badge>
                  </Popover>
                }
              </Col>
            </Row>

          </Col>
        </Row>
        <div
          id='cardGridContainer'
          ref={(ref) => this.container = ref}
          style={{
            height:'calc(100% - 32px)',
          }}
        >
          {
            isListView
            ? (
              <ListView
                dataSource={this.state.dataSource}
                onClick={this.handleClick}
                columns={displayColumns}
                loadingIds={loadingIds}
              />
            ) : (
              <Row style={{
                width:'100%',
                height:'100%',
              }} justify='center' align={activePage.length > 0 ? 'top' : 'middle'}>
                <Col style={{
                width:'100%',
                height:'100%',
                overflowY:scrollable ? 'scroll' : 'hidden',
              }}>
                  {this.getContent()}
                </Col>
              </Row>
            )
          }

        </div>
        {add && <AddDrawer
          onClose={this.onDrawerClose}
          isAdd
          visible={this.state.showDrawer}
          title={this.props.add.title}
          formView={this.props.add.formView}
          width={this.props.add.width}
          className={this.props.add.className}
          numberOfSteps={numberOfSteps}
          key={this.state.newAdd} // Fresh component every time
          push={this.props.add.push}
          customValidator={this.props.add.customValidator}
        />}

        {!this.props.edit ? null :
        <AddDrawer // Editing
          push={this.props.edit.push}
          customValidator={this.props.edit.customValidator}
          onClose={async (values) => {
            const {
              editProps = {}
            } = this.state;
            if (!values) {
              this.setState({
                showEditDrawer:false,
              });
              return;
            }
            const editedData = {};
            // Only return changed fields
            Object.keys(values).forEach((key) => {
              if(editProps[key] !== values[key]) {
                editedData[key] = values[key];
              }
            });
            // Complex add/edit forms have data outside of the form 'values'
            // passed in here. So data may change out of band, and the parent component
            // may want to overrade the no data check.
            const {
              alwaysReturn,
            } = this.props.edit;
            const hasNoData = Object.keys(editedData).length === 0;
            if ((hasNoData && !alwaysReturn) || await this.onEditClose(editProps.id,editedData)) {
              this.setState({
                showEditDrawer:false,
              });
            }
          }}
          onEditStarted={canEdit(editProps) ? () => {
            this.setState({
              editProps:{...this.state.editProps,editing:true },
            });
          } : null}
          onEditCanceled={() => {
            this.setState({
              editProps:{...this.state.editProps,editing:false },
            })
          }}
          onArchive={canArchive(editProps) ? this.props.onArchive : null}
          onDelete={canDelete(editProps) ? this.props.onDelete : null}
          visible={this.state.showEditDrawer}
          title={editTitle}
          formView={this.props.edit.formView}
          formProps={this.state.editProps} // data
          width={this.props.edit.width}
          className={this.props.edit.className}
          numberOfSteps={editSteps}
          key={`edit - ${this.state.newAdd}`} // Fresh component every time
        />}
      </div>
    );
  }
}
