import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Form } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import FormResponder from '../forms/FormResponder';
import BorderlessButton from '../common/buttons/BorderlessButton';
import DisplayText from '../common/text/DisplayText';

import {
  getTemplateDetails,
} from '../forms/state/forms.actions';

import {
  getShiftFormData,
} from './state/schedule.actions';

import { constructFormPayloadForAPI } from '../forms/ResponderHelpers';
import { getIdMap } from '../helpers/helpers';

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 5,
  },
};

export default function ScheduleEventForm({
  shiftId,
  isDisplay,
  initialValue,
  onFormDataChanged,
  isNew,
  isEvent,
}) {
  const dispatch = useDispatch();
  const formTemplates = useSelector((state) => state.forms.templates);
  const eventFormData = useSelector((state) => state.schedule.eventFormData);
  const projects = useSelector((state) => state.projects.projects);

  const [showResponder, setShowResponder] = useState(false);
  const [formTemplate, setFormTemplate] = useState(initialValue);
  const [formData, setFormData] = useState({});

  const displayValue = useMemo(() => {
    const {
      [initialValue]: { name = '' } = {},
    } = formTemplates;
    return name;
  }, [formTemplates, initialValue]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const formArray = useMemo(() => (
    Object.values(formTemplates)
      .filter((ft) => ft.notDeleted)
      .map((ft) => {
        const {
          projectId: templateProjectId,
        } = ft;
        let secondRow = null;
        if (templateProjectId && templateProjectId in projectIdMap) {
          const { [templateProjectId]: { name: projectName } = {} } = projectIdMap;
          secondRow = <div style={{ fontSize: 10, fontStyle: 'italic', lineHeight: '12px' }}>{projectName}</div>
        }
        return (
          <Select.Option key={ft.id} value={ft.id} label={ft.name}>
            {ft.name}
            {secondRow}
          </Select.Option>
        );
      })
  ), [formTemplates, projectIdMap]);

  const onFormButtonClick = useCallback(async () => {
    /*
      If we're updating a shift, pull preloaded form data

      When changing from a shift to event, there will be a shiftId
      but initialValue (formTemplateId) will be null

      See: https://projectharbour.atlassian.net/browse/HARBOUR-1768
    */
    if (!isNew && shiftId && initialValue && await dispatch(getShiftFormData(shiftId))) {
      setShowResponder(true);
      return;
    }
    // Otherwise just load form template
    if (!formTemplate) return;
    if (await dispatch(getTemplateDetails(formTemplate))) {
      setShowResponder(true);
    }
  }, [formTemplate, shiftId, initialValue]);
  const closeResponder = useCallback(() => setShowResponder(false), []);

  const onFormDataSave = useCallback(async (newFormData) => {
    onFormDataChanged(newFormData);
    const parsedData = await constructFormPayloadForAPI({
      form:
      newFormData,
      shouldUploadFiles: false,
      addSectionId: true,
    });
    const {
      files = [],
    } = parsedData;
    const fileMap = {};
    files.forEach((jsFileObject) => {
      const id = jsFileObject.id || jsFileObject.uid;
      jsFileObject.id = id;
      fileMap[id] = {
        jsFileObject,
        trueType: jsFileObject.type,
      };
    });
    setFormData({
      ...parsedData,
      fileMap,
    });
    setShowResponder(false);
    return true;
  }, [onFormDataChanged]);

  useEffect(() => {
    setFormTemplate(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setFormData(eventFormData);
  }, [eventFormData]);

  return (
    <>
      <Form.Item
        name="formTemplateId"
        label="Form"
        className="schedule-form-item"
        labelCol={formLabelStyle}
        rules={[{ required: !isDisplay && isEvent, message: 'Please select a form' }]}
        initialValue={initialValue}
      >
        {isDisplay
          ? <DisplayText title={displayValue} />
          : (
            <Select
              showSearch
              optionFilterProp="label"
              onSelect={setFormTemplate}
            >
              {formArray}
            </Select>
          )
        }
      </Form.Item>
      {!isDisplay && !!formTemplate && !!isEvent && (
        <BorderlessButton
          title="Enter Form Details"
          iconNode={<FormOutlined />}
          onClick={onFormButtonClick}
          style={{ width: 200, textAlign: 'left', paddingLeft: 5 }}
        />
      )}
      <FormResponder
        visible={showResponder}
        onClose={closeResponder}
        controlledMode
        controlledData={formData}
        onSubmit={onFormDataSave}
        draftDisabled
      />
    </>
  );
}

ScheduleEventForm.propTypes = {
  isEvent: PropTypes.bool,
};

ScheduleEventForm.defaultProps = {
  isEvent: false,
};
