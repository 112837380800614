import React, { useCallback, useEffect, useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import {
  Row,
  Col,
  Dropdown,
  Menu,
  Popover,
} from 'antd';
import {
  PlusOutlined,
  DollarOutlined,
  PercentageOutlined,
  LeftOutlined,
  SwapOutlined,
  DeleteOutlined,
  CopyOutlined,
  FolderOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';

import Permissions from '../auth/Permissions';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import BorderlessButton from '../common/buttons/BorderlessButton';

import MaterialsColumnChanger from './MaterialsColumnChanger';
import MaterialSearchDropdown from './MaterialSearchDropdown';

const baseDisplayHeaderStyle = {
  minHeight: 32,
  marginTop: 0,
  width: '100%',
};

export default ({
  onAdd,
  onFolderAdd,
  onBulkChangeClicked,
  selected = [],
  onColumnChange,
  onBack,
  onMove,
  onCopy,
  onArchive,
  onDelete,
  onSearchClick,
  filter,
  filterActive,
}) => {
  const [primaryHeaderStyle,setPrimaryHeaderStyle] = useSpring(() => ({
    ...baseDisplayHeaderStyle,
    display: 'block',
  }));
  const [secondaryHeaderStyle, setSecondaryHeaderStyle] = useSpring(() => ({
    ...baseDisplayHeaderStyle,
    display: 'none',
  }));

  const onCostChangeClicked = useCallback(() => {
    onBulkChangeClicked({ type: 'Cost', costType: 'material' });
  }, []);
  const onLabourCostChangeClicked = useCallback(() => {
    onBulkChangeClicked({ type: 'Cost', costType: 'labour' });
  }, []);
  const onMarkupChangeClicked = useCallback(() => {
    onBulkChangeClicked({ type: 'Markup', costType: 'material' });
  }, []);
  const onLabourMarkupChangeClicked = useCallback(() => {
    onBulkChangeClicked({ type: 'Markup', costType: 'labour' });
  }, []);

  useEffect(() => {
    const hasSelected = selected.length > 0;
    setPrimaryHeaderStyle({
      display: hasSelected ? 'none' : 'block',
      transform: `translateX(${hasSelected ? 42 : 0}px)`
    });

    setSecondaryHeaderStyle({
      display: hasSelected ? 'block' : 'none',
      transform: `translateX(${hasSelected ? 0 : -42}px)`
    });
  },[selected]);

  const isDelete = useMemo(() => (
    selected.every((material) => !material.active && !material.isFolder)
  ), [selected]);

  const onAddClicked = useCallback(({ key }) => {
    switch (key) {
      case 'folder':
        onFolderAdd();
        break;
      case 'material':
        onAdd();
        break;
      default:
        onAdd(true);
    }
  }, [onFolderAdd, onAdd]);

  const selectedModifier =  selected.length === 1 ? '' : 's';
  return (
    <div className='materials-header' >
      <animated.div style={primaryHeaderStyle}>
        <Row justify='space-between' align='top'>
          <Col>
            <Row justify='start' align='middle' gutter={14}>
              { onBack && (
                <Col flex='35px'>
                  <BorderlessButton
                    style={{ backgroundColor:'transparent', padding:0, }}
                    iconNode={<LeftOutlined style={{ padding:0, margin:0 }}/>}
                    onClick={onBack}
                  />
                </Col>
              )}
              <Col flex='200px'>
                <MaterialSearchDropdown onMaterialSelect={onSearchClick} />
              </Col>
              {Permissions.hasWrite('MATERIALS') && (
                <Col>
                  <Dropdown
                    overlay={(
                      <Menu onClick={onAddClicked}>
                        <Menu.Item key="folder">
                          <span><FolderOutlined /></span>
                          &nbsp;Add Folder
                        </Menu.Item>
                        <Menu.Item key="material">
                          <span><PlusOutlined /></span>
                          &nbsp;Add Material
                        </Menu.Item>
                        <Menu.Item key="assembly">
                          <span><DatabaseOutlined /></span>
                          &nbsp;Add Assembly
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <OnTraccrButton
                      title='Add'
                      icon={<PlusOutlined/>}
                    />
                  </Dropdown>
                </Col>
              )}
              {!!filter && (
                <Col>
                  <Popover trigger='click' placement='bottomRight' content={filter}>
                    <OnTraccrButton title='Filter' type={filterActive ? 'primary' : 'back'}/>
                  </Popover>
                </Col>
              )}
            </Row>
          </Col>
          <Col>
            <Row style={{ height: 32 }} justify='end'>
              <MaterialsColumnChanger
                onChange={onColumnChange}
              />
            </Row>
          </Col>
        </Row>
      </animated.div>
      <animated.div style={secondaryHeaderStyle}>
        <Row justify='end' align='middle' gutter={14} style={{ minHeight: 32, marginBottom: 5 }}>
          <Col>
            Selected <b>{selected.length}</b> item{selectedModifier}
          </Col>
          <Col style={{ paddingTop: 5 }}>
            <OnTraccrButton
              title='Copy'
              icon={<CopyOutlined />}
              onClick={onCopy}
              type='cancel'
              disabled={!selected.length}
            />
          </Col>
          <Col style={{ paddingTop: 5 }}>
            <OnTraccrButton
              title='Move'
              icon={<SwapOutlined/>}
              onClick={onMove}
              type='cancel'
              disabled={!selected.length}
            />
          </Col>
          <Col style={{ paddingTop: 5 }}>
            <OnTraccrButton
              title='Change Material Costs'
              icon={<DollarOutlined />}
              onClick={onCostChangeClicked}
              type="cancel"
              disabled={!selected.length}
            />
          </Col>
          <Col style={{ paddingTop: 5 }}>
            <OnTraccrButton
              title='Change Material Markup'
              icon={<PercentageOutlined />}
              onClick={onMarkupChangeClicked}
              type='cancel'
              disabled={!selected.length}
            />
          </Col>
          <Col style={{ paddingTop: 5 }}>
            <OnTraccrButton
              title='Change Labour Costs'
              icon={<DollarOutlined />}
              onClick={onLabourCostChangeClicked}
              type='cancel'
              disabled={!selected.length}
            />
          </Col>
          <Col style={{ paddingTop: 5 }}>
            <OnTraccrButton
              title='Change Labour Markup'
              icon={<PercentageOutlined />}
              onClick={onLabourMarkupChangeClicked}
              type='cancel'
              disabled={!selected.length}
            />
          </Col>
          <Col style={{ paddingTop: 5 }}>
            <OnTraccrButton
              title={isDelete ? 'Delete' : 'Archive'}
              type='back'
              icon={<DeleteOutlined/>}
              onClick={isDelete ? onDelete : onArchive}
              disabled={!selected.length}
            />
          </Col>
        </Row>
      </animated.div>
    </div>
  );
};
