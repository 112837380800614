import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
} from 'react';
import { Row, Col, Checkbox, Select } from 'antd';
import { FormOutlined, SettingOutlined } from '@ant-design/icons';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import HoverHelp from '../../common/HoverHelp';

import WorkflowActionNode from './WorkflowActionNode';
import FormSelector from './selectors/FormSelector';
import UserAssignmentSelector from './selectors/UserAssignmentSelector';
import WorkflowHandle from './WorkflowHandle';
import WorkflowTriggerConfigureDrawer from './WorkflowTriggerConfigureDrawer';

import {
  locateWorkflowParentElement,
  updateData,
} from './workflowHelpers';
import { createSourceToTargetMap, createTargetToSourceMap } from '../formHelpers';
import { AdditionalItem, AdditionalLabel } from './AssignmentDropdownItems';
import FormWorkflowContext from './FormWorkflowContext';

const HOVER_TEXT = `
Use this step to initiate another form
`;

const AUTHOR_HELP = `The new form will be assigned to the original form\'s author`;

export default ({
  setDataMap,
  setElements,
  isDisplay,
  divisionId,
  templateId,
  name,
  sections = [],
  projectId,
  isExternalForm,
} = {}) => ({
  id,
  draggable,
  data = {},
}) => {
  const {
    elements = [],
    dataMap,
  } = useContext(FormWorkflowContext) ?? {};

  const parentDispatchNode = useMemo(() => (
    locateWorkflowParentElement(id, 'dispatch', elements)
  ), [id, elements, dataMap]);

  const parentLogicalLoopNode = useMemo(() => {
    const logicalLoopNode = locateWorkflowParentElement(id, 'logicalLoop', elements);

    if (!logicalLoopNode) return logicalLoopNode;

    return {
      ...logicalLoopNode,
      data: dataMap[logicalLoopNode.id] ?? logicalLoopNode.data,
    }
  }, [id, elements, dataMap]);

  const {
    form: initialForm,
    users: initialUsers,
    fieldMappings: initialFieldMappings = {},
    sharedForm: initialSharedForm,
    linkedNumber: initialLinkedNumber,
  } = data;

  const [selectedForm,setSelectedForm] = useState(initialForm);
  const [selectedUsers,setSelectedUsers] = useState(initialUsers || []);
  const [showDrawer,setShowDrawer] = useState(false);
  const [fieldMappings,setFieldMappings] = useState(
    initialFieldMappings?.version === 'v2' ? initialFieldMappings : createTargetToSourceMap(initialFieldMappings)
  );

  const openDrawer = useCallback(() => setShowDrawer(true),[]);
  const closeDrawer = useCallback(() => setShowDrawer(false),[]);
  const onFormChange = useCallback((form) => {
    if(!setDataMap || !id) return;
    setSelectedForm(form);
    setDataMap(updateData(id, { form, }));
  },[setDataMap, id]);
  const onAssignmentChange = useCallback((users) => {
    if(!setDataMap || !id) return;
    setSelectedUsers(users);
    setDataMap(updateData(id, { users, }));
  },[setDataMap, id]);

  const onFieldMappingsChange = useCallback((newMappings, formId) => {
    if(!setDataMap || !id) return;
    const updatedFieldMappings = {
      ...fieldMappings,
      version: 'v2',
      [formId]: createSourceToTargetMap(newMappings),
    };

    setFieldMappings(updatedFieldMappings);
    setDataMap(updateData(id, { fieldMappings: updatedFieldMappings }));
  },[setDataMap, id, fieldMappings]);

  const onCheckChanged = useCallback((key) => (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setDataMap(updateData(id, { [key]: checked }));
  },[setDataMap, id]);

  const additionalFormOptions = useMemo(() => {
    const formDropdownFields = [];

    sections.forEach(({ name: sectionName, fields = [] }) => {
      fields.forEach(({ selectedType, configProps, id: fieldId }) => {
        if (selectedType === 'dropdown' && configProps?.dataType === 'Forms') {
          formDropdownFields.push({
            sectionName,
            fieldName: configProps?.title,
            fieldId,
          });
        }
      });
    });

    return formDropdownFields.map(({ sectionName, fieldName, fieldId }) => (
      <Select.Option key={fieldId} value={fieldId} label={`${sectionName} - ${fieldName}`}>
        <AdditionalItem
          title={`${sectionName} - ${fieldName}`}
          help='Selected forms from this field will be initiated'
        />
      </Select.Option>
    ));
  }, [sections]);

  const additionalUserOptions = useMemo(() => {
    if (!parentDispatchNode) return [];
    const title = 'Shift User(s)';
    const help = 'The user(s) assigned to the dispatch shift will be assigned to the new form when clocking in/clocking out.';

    return [{
      id: 'dispatchUsers',
      children: (
        <AdditionalItem
          title={title}
          help={help}
        />
      ),
      label: (
        <AdditionalLabel
          title={title}
          help={help}
        />
      )
    }];
  }, [parentDispatchNode]);

  return (
    <WorkflowActionNode
      title='Form Trigger'
      Icon={FormOutlined}
      type='formTrigger'
      id={id}
      draggable={draggable}
      onNodeUpdate={setElements}
      isDisplay={isDisplay}
      hover={HOVER_TEXT}
    >
      {!draggable && <div>
        <Row style={{ width: '100%', margin: '5px 0px' }}>
          <Col>
            <Checkbox onChange={onCheckChanged('sharedForm')} defaultChecked={initialSharedForm}/>
          </Col>
          <Col style={{ padding: '0px 5px' }}>
            Shared Form?
          </Col>
          <Col>
            <HoverHelp
              content={
                <div style={{ width: 300 }}>
                  Check this box if you want to create one form and assign it to multiple people <br/> <br/>
                  Leave this box unchecked if you want to create a seperate form for each person.
                </div>
              }
            />
          </Col>
        </Row>
        <Row style={{ width: '100%', margin: '5px 0px' }}>
          <Col>
            <Checkbox onChange={onCheckChanged('linkedNumber')} defaultChecked={initialLinkedNumber}/>
          </Col>
          <Col style={{ padding: '0px 5px' }}>
            Link Number?
          </Col>
          <Col>
            <HoverHelp
              content={
                <div style={{ width: 300 }}>
                  Check this box if you want the triggered form's number to be linked to this form's number <br/> <br/>
                  Leave this box unchecked if you want the triggered form to have its own number.
                </div>
              }
            />
          </Col>
        </Row>
        <FormSelector
          divisionId={divisionId}
          disable={draggable || isDisplay}
          onChange={onFormChange}
          selected={selectedForm}
          projectId={projectId}
          hideExternalForms
          additionalFormOptions={additionalFormOptions}
        />
        <UserAssignmentSelector
          type='user'
          isDisplay={isDisplay}
          draggable={draggable}
          divisions={[divisionId]}
          showPositions
          onChange={onAssignmentChange}
          selected={selectedUsers}
          showFields
          sections={sections}
          showFormAuthor={!isExternalForm}
          formAuthorHelperText={AUTHOR_HELP}
          additionalOptions={additionalUserOptions}
        />
        <BorderlessButton
          title='Configure'
          style={{ margin: 5 }}
          iconNode={<SettingOutlined/>}
          onClick={openDrawer}
          disabled={!selectedForm}
        />
        <WorkflowTriggerConfigureDrawer
          visible={showDrawer}
          onClose={closeDrawer}
          onSubmit={closeDrawer}
          targetFormId={selectedForm}
          sourceName={name}
          sourceSections={sections}
          fieldMappings={fieldMappings}
          onFieldMappingsChange={onFieldMappingsChange}
          divisionId={divisionId}
          templateId={templateId}
          parentLogicalLoopNode={parentLogicalLoopNode}
        />
        <WorkflowHandle type='target' position='top' disabled={isDisplay}/>
      </div>}
    </WorkflowActionNode>
  );
}
