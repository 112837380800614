import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  Form,
  Select,
  TreeSelect,
  DatePicker,
  Divider,
  Row,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { getIdMap, getProperty } from '../../../helpers/helpers';
import MaterialTableAddDrawer from '../../../forms/FormBuilder/FormFields/MaterialTableAddDrawer';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import SimpleTextInputModal from '../../../common/modals/SimpleTextInputModal';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import {
  addUserGanttScheduleFilterView,
  deleteUserGanttScheduleFilterView,
  updateGanttScheduleFilters,
  updateUserGanttScheduleFilterView,
} from '../../state/schedule.actions';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';
import {
  constructCostCodeTreeData,
  filterViewFields,
  getFilteredCostCodes,
  getFilteredEquipment,
  getFilteredMaterials,
  getFilteredPhaseMap,
  getFilteredUsers,
  getPhaseOptions,
} from '../ganttScheduleHelpers';

const { RangePicker } = DatePicker;

export default function GanttScheduleCustomFilterTab(props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    users = [],
    materials = [],
    costCodes = [],
    equipment = [],
    labels = [],
    phases = [],
    schedule = {},
    onClose,
    filterView = {},
    setActiveTab,
  } = props;

  const dispatch = useDispatch();
  const {
    ganttScheduleFilters: activeFilters,
    ganttSchedules: schedules,
  } = useSelector((state) => state.schedule);
  const {
    projects,
  } = useSelector((state) => state.projects);
  const {
    selectedDivisions,
    divisions,
  } = useSelector((state) => state.settings);
  const projectTypes = useSelector((state) => state.projects.projectTypes);

  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [selectedCostCodes, setSelectedCostCodes] = useState([]);
  const [showMaterialDrawer, setShowMaterialDrawer] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const costCodeMap = useMemo(() => getIdMap(costCodes), [costCodes]);
  const scheduleMap = useMemo(() => getIdMap(schedules), [schedules]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);

  const currentFilters = useMemo(() => {
    if (!filterView || activeFilters.filterView === filterView.id) {
      return activeFilters;
    }

    return filterView;
  }, [filterView, activeFilters]);

  const relevantProjectIds = useMemo(() => {
    if (schedule.projectId) {
      return [schedule.projectId];
    }

    if (schedule.schedules) {
      return schedule.schedules
        .map((s) => s.projectId)
        .filter((id) => (
          projectMap[id] && selectedDivisions.has(projectMap[id].divisionId)
        ));
    }

    return [];
  }, [projectMap, schedule, selectedDivisions]);

  const phaseMap = useMemo(() => getFilteredPhaseMap({
    phases,
    selectedProjects: new Set(relevantProjectIds),
    selectedPhases: new Set(currentFilters.phases),
  }), [
    currentFilters,
    phases,
    relevantProjectIds,
  ]);

  const userOptions = useMemo(() => getFilteredUsers({
    users,
    divisions,
    selectedDivisions,
    selectedUsers: new Set(currentFilters.users),
  }), [
    currentFilters,
    users,
    divisions,
    selectedDivisions,
  ]);

  const equipmentOptions = useMemo(() => getFilteredEquipment({
    equipment,
    selectedDivisions,
    selectedEquipment: new Set(currentFilters.equipment),
  }), [
    currentFilters,
    equipment,
    selectedDivisions,
  ]);

  const materialOptions = useMemo(() => getFilteredMaterials({
    materials,
    selectedDivisions,
    selectedMaterials: new Set(currentFilters.materials),
  }), [
    currentFilters,
    materials,
    selectedDivisions,
  ]);

  const costCodeOptions = useMemo(() => getFilteredCostCodes({
    costCodes,
    selectedDivisions,
    selectedCostCodes: new Set(currentFilters.costCodes),
    selectedProjects: new Set(relevantProjectIds),
  }), [
    currentFilters,
    costCodes,
    selectedDivisions,
    relevantProjectIds,
  ]);

  const formTitle = useMemo(() => {
    if (filterView) {
      return `Edit ${filterView.filterViewName} View`;
    }

    return 'Custom Filter';
  }, [filterView]);

  const defaultFormValues = {
    users: [],
    equipment: [],
    costCodes: [],
    materials: [],
    labels: [],
    phases: [],
    schedules: [],
    dateRange: null,
    name: '',
    projectTypes: [],
  };

  const resetFields = () => {
    form.setFieldsValue(defaultFormValues);
    setSelectedMaterials([]);
    setSelectedPhases([]);
    setSelectedCostCodes([]);
  };

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        ...defaultFormValues,
        filterViewName: getProperty(currentFilters, 'filterViewName', ''),
        users: getProperty(currentFilters, 'users', []),
        equipment: getProperty(currentFilters, 'equipment', []),
        costCodes: getProperty(currentFilters, 'costCodes', []),
        materials: getProperty(currentFilters, 'materials', []),
        labels: getProperty(currentFilters, 'labels', []),
        phases: getProperty(currentFilters, 'phases', []),
        dateRange: currentFilters.dateRange
          ? currentFilters.dateRange.map((date) => moment(date))
          : null,
        name: getProperty(currentFilters, 'name', ''),
        schedules: getProperty(currentFilters, 'schedules', []),
        projectTypes: getProperty(currentFilters, 'projectTypes', []),
      });

      setSelectedMaterials(
        getProperty(currentFilters, 'materials', []),
      );
      setSelectedPhases(
        getProperty(currentFilters, 'phases', []),
      );
      setSelectedCostCodes(
        getProperty(currentFilters, 'costCodes', []),
      );
    }

    return () => {
      resetFields();
    };
  }, [activeFilters, filterView]);

  const onSelectChange = useCallback((val, option) => {
    if (option === 'phases') {
      setSelectedPhases(val);

      const relevantCostCodeSet = new Set();
      val.forEach((phase) => {
        const phaseCostCodes = phaseMap[phase] || [];
        const costCodesMap = getIdMap(phaseCostCodes, 'costcodeId');

        form.getFieldValue('costCodes').forEach((costCode) => {
          const [, costCodeId] = costCode.split('.');
          if (costCodesMap[costCodeId]) {
            relevantCostCodeSet.add(`${val}.${costCodeId}`);
          }
        });
      });

      // Only leave cost codes associated with the phase
      form.setFieldsValue({
        costCodes: Array.from(relevantCostCodeSet.keys()),
        [option]: val,
      });

      return;
    }

    form.setFieldsValue({
      [option]: val,
    });
  }, [form, phaseMap]);

  const phaseOptions = useMemo(() => getPhaseOptions(phaseMap), [phaseMap]);

  const costCodesTreeData = useMemo(() => constructCostCodeTreeData({
    costCodes: costCodeOptions,
    costCodeMap,
    phaseMap,
    phaseIds: selectedPhases,
    t,
  }), [costCodeOptions, selectedPhases, phaseMap, costCodeMap]);

  const onMaterialSubmit = useCallback((materialIds) => {
    setSelectedMaterials(materialIds);
    setShowMaterialDrawer(false);
  }, []);

  const onApply = useCallback(() => {
    const values = form.getFieldsValue();
    values.materials = selectedMaterials;

    if (filterView) {
      values.filterView = filterView.id;
    } else {
      values.filterView = null;
      values.filterViewName = null;
    }

    dispatch(updateGanttScheduleFilters(values));
    onClose();
  }, [filterView, selectedMaterials]);

  const onSave = useCallback(() => {
    const values = form.getFieldsValue();
    values.materials = selectedMaterials;
    if (filterView) {
      dispatch(updateUserGanttScheduleFilterView(schedule.id, filterView.id, {
        name: values.filterViewName,
        filters: filterViewFields.map(({ field, defaultValue }) => ({
          filterProperty: field,
          filterValue: JSON.stringify(values[field] || defaultValue),
          filterViewId: filterView.id,
        })),
      }));
      onApply();
    } else {
      setIsModalOpen(true);
    }
  }, [
    schedule,
    filterView,
    activeFilters,
    selectedMaterials,
  ]);

  const onDelete = useCallback(() => new Promise((resolve) => {
    CustomConfirmModal({
      title: 'Delete Filter View',
      content: (
        <p>
          Are you sure you wish to delete this filter view?
        </p>
      ),
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        resolve(await dispatch(
          deleteUserGanttScheduleFilterView(schedule.id, filterView.id),
        ));

        if (activeFilters.filterView === filterView.id) {
          dispatch(updateGanttScheduleFilters({
            filterView: null,
            filterViewName: null,
          }));
        } else {
          setActiveTab(activeFilters.filterView || 'custom');
        }

        setIsModalOpen(false);
      },
      onCancel() {
        resolve();
      },
    });
  }), [
    schedule,
    activeFilters,
    filterView,
    onClose,
  ]);

  const addNewFilterView = useCallback(async (filterViewName) => {
    const values = form.getFieldsValue();
    values.materials = selectedMaterials;

    const {
      id,
      filterViewName: newFilterViewName,
    } = await dispatch(addUserGanttScheduleFilterView(schedule.id, {
      name: filterViewName,
      filters: filterViewFields.map(({ field, defaultValue }) => ({
        filterProperty: field,
        filterValue: JSON.stringify(values[field] || defaultValue),
      })),
    }));

    onApply();
    setActiveTab(id);
    dispatch(updateGanttScheduleFilters({
      filterView: id,
      filterViewName: newFilterViewName,
    }));
  }, [
    form,
    schedule,
    onClose,
    setActiveTab,
    selectedMaterials,
  ]);

  const scheduleOptions = useMemo(() => {
    const options = [];

    if (schedule) {
      options.push(
        {
          value: schedule.id,
          label: schedule.name,
        },
      );

      const filteredScheduleMap = new Set(currentFilters.schedules);

      schedule.schedules?.forEach((s) => {
        const {
          projectId: scheduleProjectId,
          name: scheduleName,
          id,
        } = scheduleMap[s];

        if (!scheduleProjectId) {
          options.push({
            value: id,
            label: scheduleName,
          });
        } else {
          const project = projectMap[scheduleProjectId];

          if (selectedDivisions.has(project?.divisionId) || filteredScheduleMap.has(id)) {
            options.push({
              value: id,
              label: project.name,
            });
          }
        }
      });
    }

    return options;
  }, [
    schedule,
    scheduleMap,
    projectMap,
    currentFilters,
    selectedDivisions,
  ]);

  const projectTypeOptions = useMemo(() => (
    [{ label: 'None', value: 'None' }].concat(
      projectTypes.map((projectType) => ({
        value: projectType.id,
        label: projectType.name,
      }))
    )
  ), [projectTypes]);

  return (
    <>
      <div>
        <Row>
          <h3>{formTitle}</h3>
          <OnTraccrButton
            onClick={resetFields}
            style={{ marginLeft: 'auto' }}
            title="Reset"
          />
        </Row>
        <Form
          form={form}
          layout="vertical"
        >
          {
            filterView
              && (
                <>
                  <Form.Item
                    name="filterViewName"
                    label="Filter View Name"
                    rules={[
                      { required: true, message: 'Filter View Name is required' },
                    ]}
                  >
                    <OnTraccrTextInput
                      style={{ width: '100%' }}
                      placeholder="Name"
                    />
                  </Form.Item>
                  <Divider />
                </>
              )
            }
          { !!schedule.isMaster && (
            <Form.Item
              name="schedules"
              label="Schedules"
            >
              <Select
                mode="multiple"
                onChange={(val) => onSelectChange(val, 'schedules')}
                allowClear
                options={scheduleOptions}
                placeholder="Schedules"
                optionFilterProp="label"
              />
            </Form.Item>
          )}
          <Form.Item
            name="name"
            label="Task Name"
          >
            <OnTraccrTextInput
              style={{ width: '100%' }}
              placeholder="Name"
            />
          </Form.Item>
          <Form.Item
            name="dateRange"
            label="Date Range"
          >
            <RangePicker
              format="MMM Do YY"
              onOpenChange={(status) => setIsDatePickerOpen(status)}
              open={isDatePickerOpen}
            />
          </Form.Item>
          <Form.Item
            name="users"
            label="Users"
          >
            <Select
              mode="multiple"
              onChange={(val) => onSelectChange(val, 'users')}
              allowClear
              options={userOptions.map((item) => ({ value: item.id, label: item.name }))}
              placeholder="Users"
              optionFilterProp="label"
            />
          </Form.Item>
          {
          materialOptions.length > 0
            && (
              <>
                <p className="form-label">Materials</p>
                <Select
                  mode="multiple"
                  disabled
                  value={selectedMaterials}
                  options={materialOptions.map((item) => ({ value: item.id, label: item.name }))}
                  placeholder="No Materials"
                  style={{ paddingBottom: 10 }}
                />
                <OnTraccrButton
                  type="primary"
                  title="Manage Materials"
                  onClick={() => setShowMaterialDrawer(true)}
                />
                <br />
                <br />
              </>
            )
        }
          {
          equipment.length > 0
            && (
              <Form.Item
                name="equipment"
                label="Equipment"
                className="error-warning-form-item"
              >
                <Select
                  mode="multiple"
                  onChange={(val) => onSelectChange(val, 'equipment')}
                  allowClear
                  options={equipmentOptions.map((item) => ({ value: item.id, label: item.name }))}
                  placeholder="Equipment"
                  optionFilterProp="label"
                />
              </Form.Item>
            )
        }
          {
          Object.keys(phaseMap).length > 0
            && (
              <Form.Item
                name="phases"
                label="Phases"
              >
                <Select
                  onChange={(val) => onSelectChange(val, 'phases')}
                  allowClear
                  options={phaseOptions}
                  placeholder="Phases"
                  mode="multiple"
                  optionFilterProp="label"
                />
              </Form.Item>
            )
        }
          <Form.Item
            name="costCodes"
            label="Cost Codes"
          >
            <TreeSelect
              onChange={(val) => onSelectChange(val, 'costCodes')}
              allowClear
              treeData={costCodesTreeData}
              multiple
              treeNodeFilterProp="title"
              placeholder="Cost Codes"
              values={selectedCostCodes}
              treeDefaultExpandAll={selectedPhases}
            />
          </Form.Item>
          {
          labels.length > 0
            && (
              <Form.Item
                name="labels"
                label="Labels"
                style={{ paddingBottom: 15 }}
              >
                <Select
                  onChange={(val) => onSelectChange(val, 'labels')}
                  mode="multiple"
                  allowClear
                  placeholder="Labels"
                  optionFilterProp="label"
                >
                  {labels.map(({ id, title, color }) => (
                    <Select.Option key={id} value={id} style={{ color }}>
                      {title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
        }
        { !!schedule.isMaster && projectTypeOptions?.length > 1 && (
            <Form.Item
              name="projectTypes"
              label="Project Types"
            >
              <Select
                mode="multiple"
                onChange={(val) => onSelectChange(val, 'projectTypes')}
                allowClear
                options={projectTypeOptions}
                placeholder="Project Types"
                optionFilterProp="label"
              />
            </Form.Item>
          )}
        </Form>
        <MaterialTableAddDrawer
          visible={showMaterialDrawer}
          onClose={() => setShowMaterialDrawer(false)}
          onSubmit={onMaterialSubmit}
          selected={selectedMaterials}
          materials={getIdMap(materialOptions)}
        />
      </div>
      <div className="drawer-footer">
        <Row justify="end" gutter={10}>
          <div style={{ marginRight: 'auto' }}>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              onClick={onClose}
            />
            {
              filterView
                && (
                  <OnTraccrButton
                    title="Delete"
                    type="back"
                    onClick={onDelete}
                    style={{ marginLeft: 8 }}
                  />
                )
            }
          </div>
          <OnTraccrButton
            title={`${!filterView ? 'Create' : 'Save'} and Apply`}
            onClick={onSave}
            style={{ marginRight: 8 }}
          />
          <OnTraccrButton
            title="Apply"
            onClick={onApply}
          />
        </Row>
      </div>
      <SimpleTextInputModal
        title="Add Filter View"
        visible={isModalOpen}
        placeholder="Enter Filter View name"
        onClose={() => setIsModalOpen(false)}
        onSave={addNewFilterView}
      />
    </>
  );
}

GanttScheduleCustomFilterTab.propTypes = {
  costCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  materials: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  equipment: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  phases: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ),
  onClose: PropTypes.func.isRequired,
  filterView: PropTypes.shape({
    filterViewName: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.string),
    equipment: PropTypes.arrayOf(PropTypes.string),
    materials: PropTypes.arrayOf(PropTypes.string),
    phases: PropTypes.arrayOf(PropTypes.string),
    costCodes: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.arrayOf(PropTypes.string),
    dateRange: PropTypes.arrayOf(PropTypes.number),
  }),
  schedule: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

GanttScheduleCustomFilterTab.defaultProps = {
  labels: [],
  costCodes: [],
  equipment: [],
  materials: [],
  users: [],
  phases: [],
  filterView: null,
};
