import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Row,
} from 'antd';
import { CloudSyncOutlined, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import WorkflowHandle from './WorkflowHandle';
import WorkflowActionNode from './WorkflowActionNode';
import WorkflowSimpleMappingConfigureDrawer from './WorkflowSimpleMappingConfigureDrawer';

import useToggle from '../../common/hooks/useToggle';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import { updateData } from './workflowHelpers';

import { getEclipseWorkflowSyncFields } from '../../settings/Integrations/Eclipse/eclipse.constants';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';

const DISABLED_TEXT = 'This step requires a linked Eclipse division and an equipment table';
const REQUIRED_FIELDS = new Set(['timecard_date']);
const ERROR_DETAILS = {
  content: (
    <div style={{ maxWidth: 300 }}>
      This step requires the <b>Eclipse User Queue</b> and <b>Date</b> field to be configured
    </div>
  ),
};

export default ({
  setDataMap,
  setElements,
  isDisplay,
  name,
  sections = [],
} = {}) => {
  const { t } = useTranslation();
  const WorkflowEclipseSync = function WorkflowEclipseSync({
    id,
    draggable,
    data = {},
    disabled,
  }) {
    const {
      fieldMappings: initialFieldMappings = {},
      queueUser: initialQueueUser,
    } = data;

    const [fieldMappings, setFieldMappings] = useState(initialFieldMappings);
    const [queueUser, setQueueUser] = useState(initialQueueUser);

    const onFieldMappingsChange = useCallback((newMappings) => {
      if (!setDataMap || !id) return;
      setFieldMappings(newMappings);
      setDataMap(updateData(id, { fieldMappings: newMappings }));
    }, [setDataMap, id]);

    const onQueueUserChange = useCallback((e) => {
      if (!setDataMap || !id) return;
      const newUser = e?.target?.value;
      setQueueUser(newUser);
      setDataMap(updateData(id, { queueUser: newUser }));
    }, [setDataMap, id]);

    const style = useMemo(() => {
      if (disabled) return { opacity: 0.7 };
      return draggable ? {} : { width: 220 };
    }, [disabled, draggable]);

    const hover = useMemo(() => (
      disabled
        ? DISABLED_TEXT
        : 'Use this step to sync with Eclipse'
    ), [disabled]);

    const eclipseFields = useMemo(() => getEclipseWorkflowSyncFields(t), [t]);

    const {
      isToggled: isMappingDrawerVisible,
      toggle: toggleMappingDrawer,
    } = useToggle();

    return (
      <WorkflowActionNode
        id={id}
        title="Sync with Eclipse"
        Icon={CloudSyncOutlined}
        type="eclipse"
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={hover}
        style={style}
        errorPopover={
          (!queueUser || !fieldMappings?.timecard_date)
            ? ERROR_DETAILS
            : null
        }
      >
        {!draggable && (
          <div>
            <Row style={{ marginTop: 5 }} className="form-required-field">
              Eclipse User Queue
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <OnTraccrTextInput
                value={queueUser}
                onChange={onQueueUserChange}
              />
            </Row>
            <Row style={{ margin: '20px 0px' }}>
              <BorderlessButton
                title="Configure"
                style={{ margin: 5 }}
                iconNode={<SettingOutlined />}
                onClick={toggleMappingDrawer}
              />
            </Row>
            <WorkflowSimpleMappingConfigureDrawer
              id={id}
              visible={isMappingDrawerVisible}
              onClose={toggleMappingDrawer}
              onSubmit={toggleMappingDrawer}
              sourceName={name}
              sourceSections={sections}
              fieldMappings={fieldMappings}
              onFieldMappingsChange={onFieldMappingsChange}
              data={data}
              setDataMap={setDataMap}
              fields={eclipseFields}
              requiredFields={REQUIRED_FIELDS}
            />
            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          </div>
        )}
      </WorkflowActionNode>
    );
  };
  WorkflowEclipseSync.propTypes = {
    id: PropTypes.string.isRequired,
    draggable: PropTypes.bool,
    disabled: PropTypes.bool,
    data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  WorkflowEclipseSync.defaultProps = {
    draggable: false,
    disabled: false,
    data: {},
  };
  return WorkflowEclipseSync;
};
