import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography, Col, Modal, Row, Spin,
} from 'antd';
import { Files } from 'ontraccr-common';
import { downloadFile, getFileDetails, getFileType } from '../files/fileHelpers';
import PhotoReel from '../common/photos/PhotoReel';
import FullPhoto from '../common/photos/FullPhoto';
import { rotateFile } from '../files/state/files.actions';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';

const {
  Paragraph,
  Text,
} = Typography;

export default function AttachmentTab({
  attachments: {
    instantiatedFiles = [],
    locationMap = {},
  } = {},
  formId,
  loading,
}) {
  const dispatch = useDispatch();
  const globalFileMap = useSelector((state) => state.files.fileMap);

  const attachmentWType = useMemo(() => (
    instantiatedFiles.map((a) => ({ ...a, type: getFileType(a) }))
  ), [instantiatedFiles]);
  const [selectedFile, setSelectedFile] = useState({});
  const [showModal, setShowModal] = useState(false);

  const onFileClick = async (index) => {
    const file = attachmentWType[index];

    if (!file) return;
    if (file instanceof File) {
      setSelectedFile({
        file,
      });
    } else {
      let fileDetails = file;
      if (!file.url) {
        fileDetails = {
          ...await getFileDetails(file),
          ...file,
        };
      }

      setSelectedFile({
        ...fileDetails,
      });
    }
  };

  const clearSelectedFile = useCallback((rotation = -1) => {
    const fileType = Files.getFileType(selectedFile);
    if (fileType === 'image') dispatch(rotateFile(selectedFile?.id, { rotation, formId }, selectedFile?.rotation));
    setSelectedFile({});
  }, [selectedFile]);

  const executeDownload = useCallback(() => {
    if (selectedFile?.fullPath) {
      downloadFile({ fileDetails: selectedFile });
    } else if (selectedFile?.file) {
      downloadFile({ fileObject: selectedFile.file });
    }
    if (showModal) setShowModal(false);
  }, [selectedFile]);

  const handleOnDownload = useCallback(() => {
    const { isExternal } = selectedFile;

    if (isExternal) {
      setShowModal(true);
      return;
    }
    executeDownload();
  }, [selectedFile, executeDownload]);

  return (
    <>
      {
        loading
          ? (
            <Row style={{ height: '100%', width: '100%' }} justify="center" align="middle">
              <Col>
                <Spin />
              </Col>
            </Row>
          )
          : (
            <PhotoReel
              small
              files={attachmentWType}
              onClick={onFileClick}
              showCondensedView
              locationMap={locationMap}
            />
          )
      }
      <FullPhoto
        file={globalFileMap[selectedFile.id]}
        url={selectedFile.url}
        type={selectedFile.type}
        rotation={selectedFile.rotation}
        onClose={clearSelectedFile}
        onDownload={handleOnDownload}
      />

      <Modal
        type="warning"
        visible={showModal}
        title={(
          <div style={{ textAlign: 'left' }}>
            <Text style={{ fontSize: '0.8em' }}>
              Download External File
            </Text>
          </div>
        )}
        bodyStyle={{ borderRadius: 0, paddingBottom: 30, backgroundColor: '#ffffff' }}
        onCancel={() => setShowModal(false)}
        maskClosable
        footer={(
          <DrawerSubmitFooter
            onSubmit={executeDownload}
            onClose={() => setShowModal(false)}
            submitTitle="Download"
          />
)}
      >
        <Col style={{
          margin: 15,
          textAlign: 'left',
        }}
        >
          <Paragraph style={{ fontSize: '0.6em' }}>
            This file was uploaded by an external user from a public form.
            It is recommended to scan the file for viruses before opening it.
            Ontraccr is not responsible for the content of this file.
          </Paragraph>
        </Col>
      </Modal>
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
AttachmentTab.propTypes = {
  attachments: PropTypes.shape({
    instantiatedFiles: PropTypes.array,
    locationMap: PropTypes.object,
  }),
  formId: PropTypes.string,
  loading: PropTypes.bool,
};

AttachmentTab.defaultProps = {
  attachments: {
    instantiatedFiles: [],
    locationMap: {},
  },
  formId: null,
  loading: false,
};
