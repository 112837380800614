import { message } from 'antd';
import {
  CREATE_COSTCODE,
  CREATE_MANY_COSTCODE,
  GET_ALL_COSTCODES,
  DELETE_COSTCODE_BY_ID,
  UPDATE_COSTCODE,
  ARCHIVE_COSTCODE,
  GET_PHASES,
  GET_COSTCODE_CATEGORIES,
  CREATE_COSTCODE_TEMPLATE,
  GET_COSTCODE_TEMPLATES,
  UPDATE_COSTCODE_TEMPLATE,
  DELETE_COSTCODE_TEMPLATE_BY_ID,
  GET_COSTCODE_BILLING_DATA,
  GET_COSTCODE_CUSTOM_FIELD_TEMPLATE,
  UPDATE_COSTCODE_CUSTOM_FIELD_TEMPLATE,
  GET_COSTCODE_CUSTOM_DATA,
} from '../../state/actionTypes';
import Analytics from '../../helpers/Analytics';
import CostCodeService from './costcodes.service';
import { decorateFormWithFiles } from '../../forms/formHelpers';

const constructPayload = ({
  id,
  code,
  name,
  description,
  divisionId,
  categoryId,
  hourlyWage,
  dailyWage,
  wageAdjustment,
  wageMultiplier,
  hourlyBillingRate,
  dailyBillingRate,
  customData,
  intuitId,
}, projectId) => {
  const data = {
    id,
    code,
    name,
    description,
    divisionId,
    categoryId,
    hourlyWage,
    dailyWage,
    wageAdjustment,
    wageMultiplier,
    hourlyBillingRate,
    dailyBillingRate,
    customData,
    intuitId,
  };
  if (projectId) data.projectId = projectId;
  return data;
};

const createCostCode = (costcode) => async (dispatch) => {
  const { data } = await CostCodeService.create(costcode);
  if (!data) return false;
  dispatch({
    type: CREATE_COSTCODE,
    payload: {
      costcodes: data,
    },
  });
  return true;
};
const createManyCostCode = (costcodes) => async (dispatch) => {
  Analytics.track('Costcode/Create/Many');
  const { data } = await CostCodeService.createMany(costcodes);
  if (!data) return false;
  dispatch({
    type: CREATE_MANY_COSTCODE,
    payload: {
      costcodes: data,
    },
  });
  return true;
};

export const createCostCodes = (values, projectId) => async (dispatch) => {
  try {
    if (values) {
      if (values instanceof Array) {
        return createManyCostCode(
          values.map((value) => constructPayload(value, projectId)),
        )(dispatch);
      }
      return createCostCode(constructPayload(values, projectId))(dispatch);
    }
  } catch (err) {
    message.error(err.message);
    return false;
  }
};

export const getAllCostCodes = () => async (dispatch) => {
  const { data: costcodes } = await CostCodeService.getAll();
  if (!costcodes) return;
  dispatch({
    type: GET_ALL_COSTCODES,
    payload: {
      costcodes,
    },
  });
};

export const getAllPhases = () => async (dispatch) => {
  const phases = await CostCodeService.getAllPhases();
  if (!phases) return;
  dispatch({
    type: GET_PHASES,
    payload: {
      phases,
    },
  });
};

export const deleteCostCodeById = (id) => async (dispatch) => {
  Analytics.track('Costcode/Delete');
  const didDelete = await CostCodeService.delete(id);
  if (!didDelete) return false;
  dispatch({
    type: DELETE_COSTCODE_BY_ID,
    payload: {
      deletedId: id,
    },
  });
  return true;
};

export const archiveCostCode = (id, active = false) => async (dispatch) => {
  Analytics.track('Costcode/Archive');
  const didArchive = await CostCodeService.archive(id, active);
  if (!didArchive) return false;
  dispatch({
    type: ARCHIVE_COSTCODE,
    payload: {
      costcodeId: id,
      active,
    },
  });
  return true;
};

export const updateCostCode = (id, payload) => async (dispatch) => {
  Analytics.track('Costcode/Edit');
  const { data } = await CostCodeService.update(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_COSTCODE,
    payload: {
      costcode: { id, ...data },
    },
  });
  return true;
};

export const getCategories = () => async (dispatch) => {
  const { data: categories } = await CostCodeService.getCategories();
  dispatch({
    type: GET_COSTCODE_CATEGORIES,
    payload: {
      categories,
    },
  });
};

export const createTemplate = (payload) => async (dispatch) => {
  Analytics.track('Costcode/Template/Create');
  const { data } = await CostCodeService.createTemplate(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_COSTCODE_TEMPLATE,
    payload: {
      template: data,
    },
  });
  return true;
};

export const getTemplates = () => async (dispatch) => {
  const { data: templates } = await CostCodeService.getTemplates();
  dispatch({
    type: GET_COSTCODE_TEMPLATES,
    payload: {
      templates,
    },
  });
};

export const updateTemplate = (id, payload) => async (dispatch) => {
  Analytics.track('Costcode/Template/Edit');
  const { data } = await CostCodeService.updateTemplate(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_COSTCODE_TEMPLATE,
    payload: {
      template: { id, ...data },
    },
  });
  return true;
};

export const deleteTemplateById = (id) => async (dispatch) => {
  Analytics.track('Costcode/Template/Delete');
  const didDelete = await CostCodeService.deleteTemplate(id);
  if (!didDelete) return false;
  dispatch({
    type: DELETE_COSTCODE_TEMPLATE_BY_ID,
    payload: {
      deletedId: id,
    },
  });
  return true;
};

export const getCostcodeBillingData = (query) => async (dispatch) => {
  const { data: billingData } = await CostCodeService.getBillingData(query);
  dispatch({
    type: GET_COSTCODE_BILLING_DATA,
    payload: {
      billingData,
    },
  });
};

export const getCostcodeCustomFieldTemplate = () => async (dispatch) => {
  const { data: template } = await CostCodeService.getCustomFieldTemplate();
  dispatch({
    type: GET_COSTCODE_CUSTOM_FIELD_TEMPLATE,
    payload: {
      template,
    },
  });
};

export const updateCostcodeCustomFieldTemplate = (payload) => async (dispatch) => {
  const { data: template } = await CostCodeService.updateCustomFieldTemplate(payload);
  dispatch({
    type: UPDATE_COSTCODE_CUSTOM_FIELD_TEMPLATE,
    payload: {
      template,
    },
  });
};

export const getCostcodeCustomData = (id) => async (dispatch) => {
  if (!id) return dispatch({ type: GET_COSTCODE_CUSTOM_DATA, payload: { data: [] } });

  const { data } = await CostCodeService.getCustomData(id);
  await decorateFormWithFiles({ fileMap: data.fileMap });

  if (!data) return false;
  dispatch({
    type: GET_COSTCODE_CUSTOM_DATA,
    payload: {
      data,
    },
  });
  return true;
};
