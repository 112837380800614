import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import { DateTime } from 'luxon';
import { PropTypes } from 'prop-types';
import Badge from '../common/badge';
import FormTemplates from './FormTemplates';
import FormsList from './CompletedForms/FormsList';
import AssignedForms from './AssignedForms/AssignedForms';
import ApproveForms from './ApproveForms/ApproveForms';
import ManualForms from './ManualForms/ManualForms';
import FormImport from './FormImport/FormImport';

import { getNumberOfAssignedAndDraftForms } from './formHelpers';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';

import Permissions from '../auth/Permissions';
import Analytics from '../helpers/Analytics';

import {
  getTemplates,
  getStatuses,
  getCustomTables,
  getDrafts,
  getAssignedForms,
  getUserFormFilterViews,
} from './state/forms.actions';
import {
  getEmails,
} from '../emails/state/email.actions';
import { getBillingRates } from '../billingRates/state/billingRates.actions';
import { getCustomerLabels, getCustomers } from '../contacts/customers/state/customers.actions';
import { getUserLabels, getUserTeams } from '../users/state/users.actions';
import { getSchedule } from '../schedule/state/schedule.actions';
import { getEquipment } from '../equipment/state/equipment.actions';
import { getCompanyImageURL } from '../settings/state/settings.actions';
import { getVendorLabels } from '../contacts/vendors/state/vendors.actions';
import { getCustomFields } from '../timecards/state/timecards.actions';
import { getLabels } from '../labels/state/labels.actions';
import { getProjectEquipment } from '../projects/state/projects.actions';
import { getBoards, getBoardCardTemplates } from '../boards/state/boards.actions';
import { isFilterActive } from '../schedule/GanttSchedule/ganttScheduleHelpers';
import { getEclipseDivisions } from '../settings/Integrations/Eclipse/state/eclipse.actions';

const { TabPane } = Tabs;

const crumbs = [{ text: 'Forms', icon: 'form' }];

// Default to log tab if no tab is active
const getActiveTab = (pathname = '') => {
  if (pathname.includes('assigned')) {
    return 'assigned';
  }
  if (pathname.includes('approve')) {
    return 'approve';
  }
  if (pathname.includes('manual')) {
    return 'manual';
  }
  if (pathname.includes('templates')) {
    return 'templates';
  }
  if (pathname.includes('import') && Permissions.has('FORMS_WRITE')) {
    return 'import';
  }
  if (Permissions.has('FORMS_READ_LOGS')) {
    return 'forms';
  }
  return 'assigned';
};

const badgeStyle = {
  paddingTop: '0',
  paddingBottom: '1px',
  fontSize: '12px',
  position: 'absolute',
  lineHeight: '20px',
  paddingRight: 0,
};

export default function Forms({
  history,
  location: {
    pathname = '',
  } = {},
}) {
  const dispatch = useDispatch();
  const forms = useSelector((state) => state.forms.forms);
  const formFilters = useSelector((state) => state.forms.formFilters);
  const { connectedToEclipse = false } = useSelector((state) => state.settings.company);
  const [range, setRange] = useState([
    DateTime.local().minus({ day: 7 }).toMillis(),
    DateTime.local().toMillis(),
  ]);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const formList = useMemo(() => Object.values(forms), [forms]);

  const formApprovals = useSelector((state) => state.forms.approvals);
  const formApprovalCount = useMemo(() => (
    Object.values(formApprovals)
      .filter((approval) => (
        approval.status !== 'Cancelled'
        && (!approval.editable || approval.editableApprovals)
      )).length
  ), [formApprovals]);

  const formAssignedCount = useSelector((state) => getNumberOfAssignedAndDraftForms(state.forms));

  const onRangeChange = useCallback((newRange) => {
    setRange(newRange);
  }, []);

  const hasEditForms = Permissions.has('FORMS_WRITE');

  const onTabChange = useCallback((tab) => {
    switch (tab) {
      case 'assigned':
        history.replace('/forms/assigned');
        break;
      case 'approve':
        Analytics.track('Forms/ViewApprove');
        history.replace('/forms/approve');
        break;
      case 'manual':
        Analytics.track('Forms/ViewManual');
        history.replace('/forms/manual');
        break;
      case 'forms':
        Analytics.track('Forms/ViewFormLog');
        history.replace('/forms/log');
        break;
      case 'import':
        Analytics.track('Forms/ViewImport');
        history.replace('/forms/import');
        break;
      default:
        Analytics.track('Forms/ViewForms');
        history.replace('/forms/templates');
    }
  }, [history]);

  if (!initialLoaded && formApprovalCount) {
    setInitialLoaded(true);
    onTabChange(getActiveTab(pathname));
  }

  useEffect(() => {
    dispatch(getEmails());
    dispatch(getTemplates({ getPreview: true }));
    dispatch(getBillingRates());
    dispatch(getStatuses());
    dispatch(getCustomers());
    dispatch(getCustomerLabels());
    dispatch(getCustomTables());
    dispatch(getUserLabels());
    dispatch(getUserTeams());
    dispatch(getDrafts());
    dispatch(getAssignedForms());
    dispatch(getSchedule());
    dispatch(getEquipment());
    dispatch(getProjectEquipment());
    dispatch(getCompanyImageURL());
    dispatch(getVendorLabels());
    dispatch(getCustomFields());
    dispatch(getLabels());
    dispatch(getBoards());
    dispatch(getBoardCardTemplates());
    dispatch(getUserFormFilterViews());
  }, []);

  useEffect(() => {
    if (connectedToEclipse) dispatch(getEclipseDivisions());
  }, [connectedToEclipse]);

  const activeTab = getActiveTab(pathname);

  const assignedTabTitle = useMemo(() => (
    <span>
      Assigned
      {' '}
      {formAssignedCount ? <Badge count={formAssignedCount} style={badgeStyle} /> : ''}
    </span>
  ), [formAssignedCount]);

  const approveTabTitle = useMemo(() => (
    <span>
      Approve
      {' '}
      {formApprovalCount ? <Badge count={formApprovalCount} style={badgeStyle} /> : ''}
    </span>
  ), [formApprovalCount]);

  const query = useMemo(() => {
    if (formFilters) {
      const { dateRange } = formFilters;
      const queryObj = { ...formFilters };
      const startTime = dateRange?.[0]?.valueOf() ?? range[0];
      const endTime = dateRange?.[1]?.valueOf() ?? range[1];
      queryObj.startTime = startTime;
      queryObj.endTime = endTime;
      if (!queryObj.formNumber) delete queryObj.formNumber;
      delete queryObj.dateRange;
      delete queryObj.filterView;
      delete queryObj.filterViewName;
      return queryObj;
    }

    return {};
  }, [formFilters, range]);

  const isFiltersActive = useMemo(() => {
    const formFiltersObj = formFilters ?? {};
    return isFilterActive(formFiltersObj);
  }, [formFilters]);

  if (!Permissions.has('FORMS_READ')) {
    history.replace('/dashboard');
    return '';
  }

  return (
    <BreadCrumbContainer crumbs={crumbs}>
      <Tabs
        onChange={onTabChange}
        activeKey={activeTab}
        tabPosition="left"
        style={{
          position: 'absolute',
          top: 90,
          right: 30,
          bottom: 23,
          left: 20,
        }}
        tabBarStyle={{
          width: 110,
        }}
      >
        <TabPane tab="Templates" key="templates" className="form-tab-pane">
          <FormTemplates
            history={history}
            activeTab={activeTab}
          />
        </TabPane>
        {Permissions.has('FORMS_READ_LOGS') && (
          <TabPane
            tab={<span>Log</span>}
            key="forms"
            className="form-tab-pane"
          >
            <FormsList
              forms={formList}
              range={range}
              onRangeChange={onRangeChange}
              query={query}
              hideDate={!!formFilters?.dateRange}
              isFilterActive={isFiltersActive}
              enableFormFilters
            />
          </TabPane>
        )}
        <TabPane tab={assignedTabTitle} key="assigned" className="form-tab-pane">
          <AssignedForms history={history} />
        </TabPane>
        <TabPane tab={approveTabTitle} key="approve" className="form-tab-pane">
          <ApproveForms />
        </TabPane>
        { !!hasEditForms && (
          <TabPane tab="Import" key="import" className="form-tab-pane">
            <FormImport history={history} />
          </TabPane>
        )}
        <TabPane
          tab={<span>Manual</span>}
          key="manual"
          className="form-tab-pane"
        >
          <ManualForms visible={activeTab === 'manual'} history={history} />
        </TabPane>
      </Tabs>
    </BreadCrumbContainer>
  );
}

Forms.propTypes = {
  history: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

Forms.defaultProps = {
  location: {},
};
