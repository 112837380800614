import Analytics from '../../helpers/Analytics';
import {
  GET_COMPANY_IMAGE,
  SAVE_COMPANY_IMAGE,
  GET_COMPANY_SETTINGS,
  SAVE_COMPANY_SETTINGS,
  GET_COMPANY,
  UPDATE_COMPANY_OWNER,
  GET_COMPANY_POSITIONS,
  GET_COMPANY_AC_POSITIONS,
  RESET_PERMISSIONS,
  TOGGLE_PERMISSIONS,
  TOGGLE_AC_PERMISSIONS,
  REORDER_AC_POSITIONS,
  CREATE_POSITION,
  RENAME_POSITION,
  DELETE_POSITION,
  VIEW_BILLING,
  UPDATE_USER_LIMIT,
  CONNECT_QUICKBOOKS,
  GET_QUICKBOOKS_EMPLOYEES,
  GET_QUICKBOOKS_PROJECTS,
  DISCONNECT_QUICKBOOKS,
  GET_QUICKBOOKS_COSTCODES,
  IMPORT_QUICKBOOKS_DATA,
  CONNECT_PROCORE,
  DISCONNECT_PROCORE,
  PROCORE_IMPORT,
  GET_PROCORE_COMPANIES,
  GET_PROCORE_GLOBAL_CODES_LIST,
  IMPORT_INTEGRATION_DATA,
  UPDATE_PROCORE_COMPANY,
  CHECK_INTEGRATION_CONNECTION_STATUS,
  DEACTIVATE_INTEGRATION,
  GET_DIVISIONS,
  CREATE_DIVISIONS,
  DELETE_DIVISIONS,
  SET_SELECTED_DIVISIONS,
  TRANSFER_DIVISIONS,
  RENAME_DIVISION,
  GET_SAGE_DATA,
  CONNECT_MICROSOFT365,
  GET_INTEGRATED_EMAILS,
  GET_COMPANY_CUSTOMIZATION_TABS,
  UPDATE_COMPANY_CUSTOMIZATION_TABS,
  GET_QUICKBOOKS_MATERIALS,
  GET_QUICKBOOKS_INTEGRATIONS,
  UPDATE_QUICKBOOKS_INTEGRATION,
  GET_COMPANY_TRANSLATIONS,
  UPDATE_COMPANY_TRANSLATIONS,
  GET_PROJECT_TYPES,
  GET_EQUIPMENT_TYPES,
  GET_QUICKBOOKS_CLASSES,
  GET_WORKING_HOURS,
  UPDATE_POSITION_WORKING_HOURS,
  GET_SAGE_TIME_SYNC,
  WRITE_SAGE_TIME,
  RESOLVE_SAGE_TIME,
  GET_COMPANY_ROUNDING_POLICIES,
  CREATE_COMPANY_ROUNDING_POLICY,
  UPDATE_COMPANY_ROUNDING_POLICY,
  DELETE_COMPANY_ROUNDING_POLICY,
  UPDATE_DIVISION_CODE,
  GET_QUICKBOOKS_CUSTOMERS,
  GET_QUICKBOOKS_VENDORS,
  CONNECT_DOCUSIGN,
  DISCONNECT_DOCUSIGN,
  GET_SAGE_JOB_MAPPINGS,
  UPDATE_SAGE_JOB_MAPPINGS,
  UPDATE_SCHEDULE_RESTRICTION,
  GET_SCHEDULE_RESTRICTION,
} from '../../state/actionTypes';
import SettingsService from './settings.service';

const integrationMap = {
  procore: 'connectedToProcore',
  quickbooks: 'connectedToQuickbooks',
  microsoft365: 'connectedToMicrosoft365',
  gmail: 'connectedToGmail',
};

const typeToActionTypeMap = {
  projects: GET_PROJECT_TYPES,
  equipment: GET_EQUIPMENT_TYPES,
};

export const getCompanyImageURL = (companyId, getUrl) => async (dispatch) => {
  const relevantCompanyId = companyId || window.sessionStorage.getItem('companyId');
  const companyImageURL = await SettingsService.getCompanyImageURL(relevantCompanyId, getUrl);
  dispatch({
    type: GET_COMPANY_IMAGE,
    payload: {
      companyImageURL,
    },
  });
};

export const uploadCompanyImage = (file) => async (dispatch) => {
  const { err } = await SettingsService.uploadCompanyImage(file);
  if (err) return false;
  dispatch({
    type: SAVE_COMPANY_IMAGE,
    payload: {
      companyImageURL: file && file instanceof File ? URL.createObjectURL(file) : null,
    },
  });
  return true;
};

export const getCompanySettings = () => async (dispatch) => {
  const settings = await SettingsService.getCompanySettings();
  dispatch({
    type: GET_COMPANY_SETTINGS,
    payload: {
      settings,
    },
  });
};

export const saveCompanySettings = (settings) => async (dispatch) => {
  Analytics.track('Settings/Update', settings);
  await SettingsService.saveCompanySettings(settings);
  dispatch({
    type: SAVE_COMPANY_SETTINGS,
    payload: {
      settings,
    },
  });
};

export const getCompany = () => async (dispatch) => {
  const { data } = await SettingsService.getCompany();
  if (!data) return false;
  dispatch({
    type: GET_COMPANY,
    payload: {
      company: data,
    },
  });
  return true;
};

export const updateCompanyOwner = (userId) => async (dispatch) => {
  Analytics.track('Settings/ChangeOwner');
  const { data } = await SettingsService.updateCompanyOwner(userId);
  if (!data) return false;
  dispatch({
    type: UPDATE_COMPANY_OWNER,
    payload: {
      company: {
        userId,
      },
    },
  });
  return true;
};

export const getPositions = () => async (dispatch) => {
  const { data } = await SettingsService.getPositions();
  if (!data) return false;
  dispatch({
    type: GET_COMPANY_POSITIONS,
    payload: {
      positions: data.permMap,
      positionNames: data.positions,
    },
  });
  return true;
};

export const getACPositions = () => async (dispatch) => {
  const { data } = await SettingsService.getACPositions();
  if (!data) return false;
  dispatch({
    type: GET_COMPANY_AC_POSITIONS,
    payload: {
      acPositions: data.permMap,
      positionNames: data.positions,
    },
  });
  return true;
};

export const resetPermissions = (position) => async (dispatch) => {
  Analytics.track('Settings/ResetPermissions');
  const { data } = await SettingsService.resetPermissions(position);
  if (!data) return false;
  const { data: positions } = await SettingsService.getPositions();
  dispatch({
    type: RESET_PERMISSIONS,
    payload: {
      positions: positions.permMap,
      positionNames: positions.positions,
    },
  });
  return true;
};

export const togglePermissions = (payload) => async (dispatch) => {
  const { data } = await SettingsService.togglePermission(payload);
  if (!data) return false;
  const { data: positions } = await SettingsService.getPositions();
  dispatch({
    type: TOGGLE_PERMISSIONS,
    payload: {
      positions: positions.permMap,
      positionNames: positions.positions,
    },
  });
  return true;
};

export const toggleACPermissions = (payload) => async (dispatch) => {
  const { data } = await SettingsService.toggleACPermission(payload);
  if (!data) return false;
  dispatch({
    type: TOGGLE_AC_PERMISSIONS,
    payload: {
      acPositions: data.acPermMap,
      acPositionNames: data.newPositions,
    },
  });
  return true;
};

export const reorderAC = (payload) => async (dispatch) => {
  const { data } = await SettingsService.reorderAC(payload);
  if (!data) return false;
  dispatch({
    type: REORDER_AC_POSITIONS,
    payload: {
      acPositions: data.acPermMap,
      acPositionNames: data.newPositions,
    },
  });
  return true;
};

export const createPosition = (name) => async (dispatch) => {
  Analytics.track('Settings/CreatePosition', { CustomRoleName: name });
  const { data } = await SettingsService.createPosition(name);
  if (!data) return false;
  const { data: positions } = await SettingsService.getPositions();
  dispatch({
    type: CREATE_POSITION,
    payload: {
      positions: positions.permMap,
      positionNames: positions.positions,
    },
  });
  return true;
};

export const renamePosition = ({ oldName, newName }) => async (dispatch) => {
  Analytics.track('Settings/RenamePosition', { CustomRoleName: newName });
  const { data } = await SettingsService.renamePosition({ oldName, newName });
  if (!data) return false;
  const { data: positions } = await SettingsService.getPositions();
  dispatch({
    type: RENAME_POSITION,
    payload: {
      positions: positions.permMap,
      positionNames: positions.positions,
    },
  });
  return true;
};

export const deletePosition = ({ position, newRole }) => async (dispatch) => {
  Analytics.track('Settings/DeletePosition', { CustomRoleName: position });
  const { data } = await SettingsService.deletePosition({ position, newRole });
  if (!data) return false;
  const { data: positions } = await SettingsService.getPositions();
  dispatch({
    type: DELETE_POSITION,
    payload: {
      positions: positions.permMap,
      positionNames: positions.positions,
    },
  });
  return true;
};

export const viewBilling = () => async (dispatch) => {
  Analytics.track('Settings/ViewBilling');
  const { data } = await SettingsService.viewBilling();
  if (!data) return false;
  dispatch({
    type: VIEW_BILLING,
    payload: {
      data,
    },
  });
  return true;
};

export const updateUserLimit = (payload) => ({
  type: UPDATE_USER_LIMIT,
  payload,
});

export const checkIntegrationConnection = (type, id) => async (dispatch) => {
  const { data } = await SettingsService.checkIntegrationConnection(type, id);
  dispatch({
    type: CHECK_INTEGRATION_CONNECTION_STATUS,
    payload: {
      data: {
        [integrationMap[type]]: data,
      },
    },
  });
  return true;
};

export const deactivateIntegration = (type) => async (dispatch) => {
  const { data } = await SettingsService.deactivateIntegration(type);
  if (!data) return false;
  dispatch({
    type: DEACTIVATE_INTEGRATION,
    payload: {
      data: {
        [integrationMap[type]]: false,
      },
    },
  });
  return true;
};

export const connectQuickbooks = (payload) => async (dispatch) => {
  Analytics.track('QuickBooks/Connect/Complete');
  const { data } = await SettingsService.connectQuickbooks(payload);
  if (!data) return false;
  dispatch({
    type: CONNECT_QUICKBOOKS,
    payload: {
      data,
    },
  });
  return true;
};

export const getQuickbooksIntegrations = () => async (dispatch) => {
  const { data } = await SettingsService.getQuickbooksIntegrations();
  if (!data) return false;
  dispatch({
    type: GET_QUICKBOOKS_INTEGRATIONS,
    payload: {
      data,
    },
  });
  return true;
};

export const getQuickbooksEmployees = (id) => async (dispatch) => {
  Analytics.track('QuickBooks/GetEmployees');
  const { data } = await SettingsService.getQuickbooksEmployees(id);
  if (!data) return false;
  dispatch({
    type: GET_QUICKBOOKS_EMPLOYEES,
    payload: {
      data,
    },
  });
  return true;
};

export const getQuickbooksProjects = (id) => async (dispatch) => {
  Analytics.track('QuickBooks/GetProjects');
  const { data } = await SettingsService.getQuickbooksProjects(id);
  if (!data) return false;
  dispatch({
    type: GET_QUICKBOOKS_PROJECTS,
    payload: {
      data,
    },
  });
  return true;
};

export const getQuickbooksCostcodes = (id) => async (dispatch) => {
  Analytics.track('QuickBooks/GetCostcodes');
  const { data } = await SettingsService.getQuickbooksCostcodes(id);
  if (!data) return false;
  dispatch({
    type: GET_QUICKBOOKS_COSTCODES,
    payload: {
      data,
    },
  });
  return true;
};

export const getQuickbooksMaterials = (id) => async (dispatch) => {
  Analytics.track('QuickBooks/GetMaterials');
  const { data } = await SettingsService.getQuickbooksMaterials(id);
  if (!data) return false;
  dispatch({
    type: GET_QUICKBOOKS_MATERIALS,
    payload: {
      data,
    },
  });
  return true;
};

export const getQuickbooksCustomers = (id) => async (dispatch) => {
  Analytics.track('QuickBooks/GetCustomers');
  const { data } = await SettingsService.getQuickbooksCustomers(id);
  if (!data) return false;
  dispatch({
    type: GET_QUICKBOOKS_CUSTOMERS,
    payload: {
      data,
    },
  });
  return true;
};

export const getQuickbooksVendors = (id) => async (dispatch) => {
  Analytics.track('Quickbooks/GetVendors');
  const { data } = await SettingsService.getQuickbooksVendors(id);
  if (!data) return false;
  dispatch({
    type: GET_QUICKBOOKS_VENDORS,
    payload: {
      data,
    },
  });
  return true;
};

export const getQuickbooksClasses = () => async (dispatch) => {
  const { data } = await SettingsService.getQuickbooksClasses();
  if (!data) return false;
  dispatch({
    type: GET_QUICKBOOKS_CLASSES,
    payload: {
      data,
    },
  });
  return true;
};

export const importQuickbooksData = (payload) => async (dispatch) => {
  Analytics.track('QuickBooks/Import');
  const { data } = await SettingsService.importQuickbooksData(payload);
  if (!data) return false;
  const {
    syncSettings = {},
  } = payload;
  dispatch({
    type: IMPORT_QUICKBOOKS_DATA,
    payload: {
      settings: syncSettings,
    },
  });
  return true;
};

export const disconnectQuickBooks = (id) => async (dispatch) => {
  Analytics.track('QuickBooks/Disconnect');
  const { data } = await SettingsService.disconnectQuickBooks(id);
  if (!data) return false;
  dispatch({
    type: DISCONNECT_QUICKBOOKS,
    payload: { id },
  });
  return true;
};

export const updateQuickBooksIntegration = (id, payload) => async (dispatch) => {
  Analytics.track('QuickBooks/Update');
  const { data } = await SettingsService.updateQuickBooksIntegration(id, {
    ...payload,
    pushEnabled: !!payload.pushEnabled,
    pullEnabled: !!payload.pullEnabled,
    pushMaterials: !!payload.pushMaterials,
    pullMaterials: !!payload.pullMaterials,
    pullInvoices: !!payload.pullInvoices,
    ignoreInvoiceNoProject: !!payload.ignoreInvoiceNoProject,
    ignoreInvoiceNoProjectMatch: !!payload.ignoreInvoiceNoProjectMatch,
    dynamicProjects: !!payload.dynamicProjects,
    dynamicCustomers: !!payload.dynamicCustomers,
    dynamicVendors: !!payload.dynamicVendors,
    dynamicCostcodes: !!payload.dynamicCostcodes,
    dynamicMaterials: !!payload.dynamicMaterials,
  });
  if (!data) return false;
  dispatch({
    type: UPDATE_QUICKBOOKS_INTEGRATION,
    payload: { id, newData: payload },
  });
  return true;
};

export const connectDocusign = (payload) => async (dispatch) => {
  Analytics.track('Docusign/Connect/Complete');
  const { data } = await SettingsService.connectDocusign(payload);
  if (!data) return false;
  dispatch({
    type: CONNECT_DOCUSIGN,
    payload: {
      data,
    },
  });
  return true;
};

export const disconnectDocusign = (payload) => async (dispatch) => {
  Analytics.track('Docusign/Disconnect');
  const { data } = await SettingsService.disconnectDocusign(payload);
  if (!data) return false;
  dispatch({
    type: DISCONNECT_DOCUSIGN,
    payload: {
      data,
    },
  });
  return true;
};

export const connectProcore = (payload) => async (dispatch) => {
  Analytics.track('Procore/Connect/Complete');
  const { data } = await SettingsService.connectProcore(payload);
  if (!data) return false;
  dispatch({
    type: CONNECT_PROCORE,
    payload: {
      data,
    },
  });
  return true;
};

export const disconnectProcore = (payload) => async (dispatch) => {
  Analytics.track('Procore/Disconnect');
  const { data } = await SettingsService.disconnectProcore(payload);
  if (!data) return false;
  dispatch({
    type: DISCONNECT_PROCORE,
    payload: {
      data,
    },
  });
  return true;
};

export const getProcoreCompanies = () => async (dispatch) => {
  Analytics.track('Procore/GetCompanies');
  const { data } = await SettingsService.getProcoreCompanies();
  if (!data) return false;
  dispatch({
    type: GET_PROCORE_COMPANIES,
    payload: {
      data,
    },
  });
  return true;
};

export const getProcoreGlobalCodesList = (company) => async (dispatch) => {
  Analytics.track('Procore/GetGlobalCodesList');
  const { data, err } = await SettingsService.getProcoreGlobalCodesList(company);
  if (!data && !err) return false;
  dispatch({
    type: GET_PROCORE_GLOBAL_CODES_LIST,
    payload: {
      data: data || err,
    },
  });
  return true;
};

export const importFromProcore = (query) => async (dispatch) => {
  Analytics.track('Procore/Import');
  const { data } = await SettingsService.importFromProcore(query);
  if (!data) return false;
  dispatch({
    type: PROCORE_IMPORT,
    payload: {
      data,
    },
  });
  return true;
};

export const importIntegrationData = (payload) => async (dispatch) => {
  Analytics.track('Integration/Import');
  const { data } = await SettingsService.importIntegrationData(payload);
  if (!data) return false;
  const {
    syncSettings = {},
  } = payload;
  dispatch({
    type: IMPORT_INTEGRATION_DATA,
    payload: {
      settings: syncSettings,
    },
  });
  return true;
};

export const updateProcoreCompany = (payload) => async (dispatch) => {
  Analytics.track('Procore/UpdateCompany');
  const { data } = await SettingsService.updateProcoreCompany(payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_PROCORE_COMPANY,
  });
  return true;
};

export const setSelectedDivisions = (selected) => {
  window.localStorage.setItem(
    'selectedDivisions',
    JSON.stringify(Array.from(selected)),
  );
  return {
    type: SET_SELECTED_DIVISIONS,
    payload: {
      selected,
    },
  };
};

export const getDivisions = () => async (dispatch) => {
  const { data } = await SettingsService.getDivisions();
  const { divisions = {} } = data;
  const divArr = Object.values(divisions);

  const selectedDivisions = window.localStorage.getItem('selectedDivisions');
  if (divArr.length === 1 || !selectedDivisions) {
    dispatch(setSelectedDivisions(new Set(divArr.map(({ id }) => id))));
  }
  dispatch({
    type: GET_DIVISIONS,
    payload: data,
  });
  return true;
};

export const createDivision = (name) => async (dispatch) => {
  const { data: id } = await SettingsService.createDivision(name);
  if (!id) return false;

  dispatch({
    type: CREATE_DIVISIONS,
    payload: {
      name,
      id,
    },
  });

  return true;
};

export const deleteDivision = (divisionId) => async (dispatch) => {
  const { data } = await SettingsService.deleteDivision(divisionId);
  if (!data) return false;

  const selectedDivisions = window.localStorage.getItem('selectedDivisions');
  if (selectedDivisions) {
    const newSelected = new Set(JSON.parse(selectedDivisions));
    newSelected.delete(divisionId);
    dispatch(setSelectedDivisions(newSelected));
  }

  dispatch({
    type: DELETE_DIVISIONS,
    payload: {
      divisionId,
    },
  });
  return true;
};

export const transferDivisions = (payload) => async (dispatch) => {
  const { data } = await SettingsService.transferDivisions(payload);
  if (!data) return false;

  dispatch({
    type: TRANSFER_DIVISIONS,
    payload,
  });
  return true;
};

export const renameDivision = (id, name) => async (dispatch) => {
  const { data } = await SettingsService.renameDivision(id, name);
  if (!data) return false;

  dispatch({
    type: RENAME_DIVISION,
    payload: {
      id, name,
    },
  });
  return true;
};

export const updateDivisionCode = (id, code) => async (dispatch) => {
  const { data } = await SettingsService.updateDivisionCode(id, code);
  if (!data) return false;

  dispatch({
    type: UPDATE_DIVISION_CODE,
    payload: {
      id, code,
    },
  });
  return true;
};

export const getSageData = (integrationId, version) => async (dispatch) => {
  const { data } = await SettingsService.getSageData(integrationId, version);
  if (!data) return false;
  dispatch({
    type: GET_SAGE_DATA,
    payload: {
      data,
    },
  });
  return true;
};

export const connectMicrosoft365 = (payload) => async (dispatch) => {
  Analytics.track('Microsoft365/Connect/Complete');
  const { data } = await SettingsService.connectMicrosoft365(payload);
  if (!data) return false;
  dispatch({
    type: CONNECT_MICROSOFT365,
    payload: {
      data,
    },
  });
  return true;
};

export const getIntegratedEmails = () => async (dispatch) => {
  const { data } = await SettingsService.getIntegratedEmails();
  if (!data) return false;
  dispatch({
    type: GET_INTEGRATED_EMAILS,
    payload: {
      emails: data,
    },
  });
  return true;
};

export const getCompanyCustomizationTabs = () => async (dispatch) => {
  const { data } = await SettingsService.getCompanyCustomizationTabs();
  if (!data) return false;
  dispatch({
    type: GET_COMPANY_CUSTOMIZATION_TABS,
    payload: {
      customizationTabs: data,
    },
  });
  return true;
};

export const updateCompanyCustomizationTabs = (id, payload) => async (dispatch) => {
  const { data } = await SettingsService.updateCompanyCustomizationTabs(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_COMPANY_CUSTOMIZATION_TABS,
    payload: {
      customizationTabs: data,
    },
  });
  return true;
};

export const getCompanyTranslations = () => async (dispatch) => {
  const { data } = await SettingsService.getCompanyTranslations();
  if (!data) return false;
  dispatch({
    type: GET_COMPANY_TRANSLATIONS,
    payload: {
      translations: data,
    },
  });
  return true;
};

export const updateCompanyTranslations = (payload) => async (dispatch) => {
  const { data } = await SettingsService.updateCompanyTranslations(payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_COMPANY_TRANSLATIONS,
    payload: {
      translations: data,
    },
  });
  return true;
};

export const createCustomType = (type, payload) => async (dispatch) => {
  const { data } = await SettingsService.createCustomType(type, payload);
  if (!data) return false;

  dispatch({
    type: typeToActionTypeMap[type],
    payload: {
      customTypes: data,
    },
  });
  return true;
};

export const updateCustomType = (type, id, payload) => async (dispatch) => {
  const { data } = await SettingsService.updateCustomType(type, id, payload);
  if (!data) return false;
  dispatch({
    type: typeToActionTypeMap[type],
    payload: {
      customTypes: data,
    },
  });
  return true;
};

export const deleteCustomType = (type, id) => async (dispatch) => {
  const { data } = await SettingsService.deleteCustomType(type, id);
  if (!data) return false;
  dispatch({
    type: typeToActionTypeMap[type],
    payload: {
      customTypes: data,
    },
  });
  return true;
};

export const getWorkingHours = () => async (dispatch) => {
  const { data } = await SettingsService.getWorkingHours();
  if (!data) return false;
  dispatch({
    type: GET_WORKING_HOURS,
    payload: {
      workingHours: data,
    },
  });
  return true;
};

export const updatePositionWorkingHours = (positionName, payload) => async (dispatch) => {
  const { data } = await SettingsService.updateWorkingHours({ ...payload, positionName });
  if (!data) return false;
  dispatch({
    type: UPDATE_POSITION_WORKING_HOURS,
    payload: {
      positionName,
      data: payload,
    },
  });
  return true;
};

export const getSageTimeSync = (query) => async (dispatch) => {
  const { data } = await SettingsService.getSageTimeSync(query);
  if (!data) return false;
  dispatch({
    type: GET_SAGE_TIME_SYNC,
    payload: {
      data,
      query,
      integrationId: query?.integrationId,
    },
  });
  return true;
};

export const writeSageTime = (integrationId, taskIds) => async (dispatch) => {
  const { data } = await SettingsService.writeSageTime(integrationId, taskIds);
  if (!data) return false;
  dispatch({
    type: WRITE_SAGE_TIME,
    payload: {
      data,
      integrationId,
      taskIds,
    },
  });
  return true;
};

export const resolveSageTime = (integrationId, taskIds, resolved) => async (dispatch) => {
  const { data } = await SettingsService.resolveSageTime(integrationId, taskIds, resolved);
  if (!data) return false;
  dispatch({
    type: RESOLVE_SAGE_TIME,
    payload: {
      data,
      integrationId,
      taskIds,
    },
  });
  return true;
};

export const getSageJobMappings = (integrationId) => async (dispatch) => {
  const { data } = await SettingsService.getSageJobMappings(integrationId);
  if (!data) return false;
  dispatch({
    type: GET_SAGE_JOB_MAPPINGS,
    payload: {
      data,
      integrationId,
    },
  });
  return true;
};

export const updateSageJobMappings = (integrationId, mappings) => async (dispatch) => {
  const { data } = await SettingsService.updateSageJobMappings(integrationId, mappings);
  if (!data) return false;
  dispatch({
    type: UPDATE_SAGE_JOB_MAPPINGS,
    payload: {
      data,
      integrationId,
    },
  });
  return true;
};

export const getRoundingPolicies = () => async (dispatch) => {
  const { data } = await SettingsService.getRoundingPolicies();
  if (!data) return false;
  dispatch({
    type: GET_COMPANY_ROUNDING_POLICIES,
    payload: {
      data,
    },
  });
  return true;
};

export const createRoundingPolicy = (payload) => async (dispatch) => {
  const { data } = await SettingsService.createRoundingPolicy(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_COMPANY_ROUNDING_POLICY,
    payload: {
      data,
    },
  });
  return true;
};

export const updateRoundingPolicy = (id, payload) => async (dispatch) => {
  const { data } = await SettingsService.updateRoundingPolicy(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_COMPANY_ROUNDING_POLICY,
    payload: {
      id,
      data,
    },
  });
  return true;
};

export const deleteRoundingPolicy = (id) => async (dispatch) => {
  const { data } = await SettingsService.deleteRoundingPolicy(id);
  if (!data) return false;
  dispatch({
    type: DELETE_COMPANY_ROUNDING_POLICY,
    payload: {
      id,
    },
  });
  return true;
};

export const updateScheduleRestrictions = (positionName, payload) => async (dispatch) => {
  const { data } = await SettingsService.updateScheduleRestrictions({ ...payload, positionName });
  if (!data) return false;
  dispatch({
    type: UPDATE_SCHEDULE_RESTRICTION,
    payload: {
      positionName,
      data: payload,
    },
  });
  return true;
};

export const getScheduleRestrictions = () => async (dispatch) => {
  const { data } = await SettingsService.getScheduleRestrictions();
  if (!data) return false;
  dispatch({
    type: GET_SCHEDULE_RESTRICTION,
    payload: {
      scheduleRestriction: data,
    },
  });
  return true;
};
