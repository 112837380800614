import React, { useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Col,
  Row,
  Select,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined, DragOutlined, PlusOutlined } from '@ant-design/icons';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import useToggle from '../common/hooks/useToggle';
import SimpleFormModal from '../common/modals/SimpleFormModal';
import { textBasedFieldTypes } from './formHelpers';
import { getIdMap } from '../helpers/helpers';
import BorderlessButton from '../common/buttons/BorderlessButton';

export default function FormTemplatesDisplayFieldSettings({
  isDisplay,
  displayFields,
  setDisplayFields,
  fields,
}) {
  const { toggle, isToggled } = useToggle(false);

  const [selectedField, setSelectedField] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const orderedDisplayFields = useMemo(() => (
    displayFields?.sort((a, b) => a.orderIndex - b.orderIndex)
  ), [displayFields]);

  const onFieldDrag = (result) => {
    if (isDisplay) return;
    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;
    const newDisplayFields = [...orderedDisplayFields];
    newDisplayFields.splice(source.index, 1);
    newDisplayFields.splice(destination.index, 0, orderedDisplayFields[source.index]);
    setDisplayFields(newDisplayFields.map(
      (displayField, idx) => ({ ...displayField, orderIndex: idx }),
    ));
  };

  const addField = () => {
    if (!selectedField) {
      setErrorText('Please select a field');
      return;
    }

    const [fieldId, columnKey] = selectedField.split('#');

    const newDisplayFields = [...displayFields];
    newDisplayFields.push({
      fieldId,
      orderIndex: newDisplayFields.length,
      columnKey,
    });
    setDisplayFields(newDisplayFields);
    setSelectedField(null);
    toggle();
  };

  const tableColumns = useMemo(() => {
    const tableFields = fields?.filter((field) => field.selectedType === 'table');
    const cols = [];
    tableFields.forEach((field) => {
      const {
        name,
        configProps: {
          columns = [],
        } = {},
      } = field;
      columns.forEach((col) => {
        cols.push({
          id: `${field.id}#${col.key}`,
          name: `${name} - ${col.name}`,
          key: col.key,
          baseId: field.id,
        });
      });
    });
    return cols;
  }, [fields]);

  const relevantFields = useMemo(() => {
    const regularFields = fields?.filter((field) => (
      !displayFields?.find((displayField) => displayField.fieldId === field.id)
      && textBasedFieldTypes.has(field.selectedType)
    ));

    const relevantColumns = [];
    tableColumns.forEach((col) => {
      const { baseId: fieldId, key: columnKey } = col;
      const isColumnSelected = !!displayFields?.find((displayField) => (
        displayField.fieldId === fieldId && displayField.columnKey === columnKey
      ));
      if (!isColumnSelected) {
        relevantColumns.push(col);
      }
    });

    const allFields = [...regularFields, ...relevantColumns];

    return allFields;
  }, [fields, tableColumns, displayFields]);

  const columnMap = useMemo(() => getIdMap(tableColumns, 'key'), [tableColumns]);

  const fieldMap = getIdMap(fields);

  const onDeleteClicked = (fieldId) => () => {
    setDisplayFields(
      orderedDisplayFields
        .filter((displayField) => displayField.fieldId !== fieldId)
        .map((displayField, idx) => ({ ...displayField, orderIndex: idx })),
    );
  };

  return (
    <>
      { !isDisplay && (
        <OnTraccrButton
          title="Add Field"
          icon={<PlusOutlined />}
          onClick={toggle}
        />
      )}
      <DragDropContext onDragEnd={onFieldDrag}>
        <Droppable droppableId="parent">
          {({ droppableProps, innerRef, placeholder }) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...droppableProps}
              ref={innerRef}
              style={{ width: '100%' }}
            >
              {
                orderedDisplayFields.map(({ fieldId, orderIndex, columnKey }) => (
                  <Draggable draggableId={fieldId} index={orderIndex} key={fieldId}>
                    {({ draggableProps, dragHandleProps, innerRef: fieldInnerRef }) => (
                      <div
                        ref={fieldInnerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...draggableProps}
                        className="boards-status-card"
                      >
                        <Row justify="space-between" align="middle">
                          { !isDisplay && (
                            <Col flex="18px">
                              <Row justify="center" style={{ height: '100%', paddingRight: 5 }} align="middle">
                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                <DragOutlined {...dragHandleProps} className="boards-status-dragger" />
                              </Row>
                            </Col>
                          )}
                          <Col flex="auto">
                            <div
                              style={{
                                marginLeft: 5,
                              }}
                            >
                              <Typography.Text>
                                {columnKey ? columnMap[columnKey]?.name : fieldMap[fieldId]?.name}
                              </Typography.Text>
                            </div>
                          </Col>
                          { !isDisplay && (
                            <Col flex="30px">
                              <BorderlessButton
                                iconNode={<DeleteOutlined style={{ margin: 0, color: 'red' }} />}
                                onClick={onDeleteClicked(fieldId)}
                              />
                            </Col>
                          )}
                        </Row>
                      </div>
                    )}
                  </Draggable>
                ))
              }
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <SimpleFormModal
        title="Choose a Field"
        visible={isToggled}
        onClose={toggle}
        onSave={addField}
        errorText={errorText}
      >
        <Select
          style={{ width: '100%' }}
          placeholder="Select a Field"
          onChange={setSelectedField}
          value={selectedField}
          optionFilterProp="label"
        >
          {relevantFields.map((field) => (
            <Select.Option key={field.id} value={field.id} label={field.name}>
              {field.name}
            </Select.Option>
          ))}
        </Select>
      </SimpleFormModal>

    </>
  );
}

FormTemplatesDisplayFieldSettings.propTypes = {
  isDisplay: PropTypes.bool.isRequired,
  displayFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDisplayFields: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};
