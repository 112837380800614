import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Table,
  Collapse,
} from 'antd';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import HistoryDrawer from './History/HistoryDrawer';
import { getScheduleOfValueColumns } from './ScheduleOfValuesColumns';
import ScheduleOfValuesAddRow from './ScheduleOfValuesAddRow';
import DraggableTable from './DraggableTable';

function ProjectScheduleOfValuesSubContract({
  isReadOnly,
  visible,
  onClose,
  onAddChangeOrder,
  record,
  onDeleteClick,
  liveSummaryValues,
  isScheduleOfValueLocked,
  templateId,
  newItemNumbers = {},
  subContractCOs,
  files,
  onPreviewClick,
  removeFile,
  addFile,
  setFiles,
  selectedFile,
  clearSelectedFile,
  updateFile,
  onSetData,
  isProgress,
}) {
  const recordKey = isProgress ? 'formId' : 'rowId';
  const {
    description,
    [recordKey]: ourKey,
  } = record ?? {};
  const {
    [ourKey]: {
      changes: changeOrders = [],
    } = {},
  } = subContractCOs;

  const [showAdd, setShowAdd] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedCO, setSelectedCO] = useState();

  const columns = useMemo(() => (
    getScheduleOfValueColumns({
      hideButtons: true,
      readOnly: true,
      isSubContract: true,
      isProgress,
    })
  ), [isProgress]);

  const onAddRow = useCallback(() => setShowAdd(true), []);
  const onCloseAdd = useCallback(() => setShowAdd(false), []);

  const onHistoryClick = useCallback((changeOrder) => {
    setShowHistory(true);
    setSelectedCO(changeOrder);
  }, []);
  const onCloseHistory = useCallback(() => {
    setShowHistory(false);
    setSelectedCO();
  }, []);

  const onSubmitCO = useCallback(async (payload) => {
    if (await onAddChangeOrder({ ...payload, isChangeOrder: true, parentRowId: ourKey })) {
      setShowAdd(false);
    }
  }, [onAddChangeOrder, ourKey]);

  const changeColumns = useMemo(
    () => getScheduleOfValueColumns({
      onHistory: onHistoryClick,
      onDelete: onDeleteClick,
      readOnly: isReadOnly,
      liveSummaryValues,
      isScheduleOfValueLocked,
      isProgress,
      isChanges: true,
    }),
    [
      onHistoryClick,
      liveSummaryValues,
      isReadOnly,
      isScheduleOfValueLocked,
      onDeleteClick,
      isProgress,
    ],
  );

  const dataSource = useMemo(() => {
    if (!record) return [];
    if (!subContractCOs) return [record];
    const {
      [ourKey]: {
        totalChanges = 0,
      } = {},
    } = subContractCOs;

    const data = { ...record, totalChanges };
    if (isProgress) data.contractTotal = (data?.contractValue ?? 0) + totalChanges;

    return [data];
  }, [record, subContractCOs, isProgress]);

  const sortedCOs = useMemo(() => {
    const sorted = [...changeOrders];
    sorted.sort((a, b) => a.itemNumber - b.itemNumber);
    return sorted;
  }, [changeOrders]);

  return (
    <Drawer
      title={`${description}`}
      visible={visible}
      width={1250}
      push={false}
      bodyStyle={{ padding: 0 }}
      onClose={onClose}
    >
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey={recordKey}
      />
      <Collapse
        className="project-sov-collapse"
        defaultActiveKey="changes"
      >
        <Collapse.Panel
          collapsible="disabled"
          header="Changes"
          key="changes"
          showArrow={false}
          extra={
            !isReadOnly
            && (
              <OnTraccrButton
                title="Add Row"
                onClick={onAddRow}
              />
            )
          }
        />
      </Collapse>
      <DraggableTable
        pagination={false}
        dataSource={sortedCOs}
        columns={changeColumns}
        setData={onSetData}
        type="subContractChangeOrders"
        expandable={{
          defaultExpandAllRows: true,
        }}
        isDraggable={!isReadOnly && !isScheduleOfValueLocked}
      />
      <ScheduleOfValuesAddRow
        templateId={templateId}
        liveSummaryValues={liveSummaryValues}
        newItemNumber={newItemNumbers.subContractCOs}
        isChangeOrder
        isSubContract
        isNonHoldback
        visible={showAdd}
        onClose={onCloseAdd}
        handleSubmit={onSubmitCO}
      />
      <HistoryDrawer
        visible={showHistory}
        record={selectedCO}
        onClose={onCloseHistory}
        isReadOnly={isReadOnly}
        files={files}
        onPreviewClick={onPreviewClick}
        removeFile={removeFile}
        addFile={addFile}
        setFiles={setFiles}
        selectedFile={selectedFile}
        clearSelectedFile={clearSelectedFile}
        updateFile={updateFile}
        isProgress={isProgress}
        formId={record?.formId}
      />
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
ProjectScheduleOfValuesSubContract.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAddChangeOrder: PropTypes.func.isRequired,
  record: PropTypes.shape({}),
  onDeleteClick: PropTypes.func.isRequired,
  liveSummaryValues: PropTypes.array.isRequired,
  isScheduleOfValueLocked: PropTypes.bool.isRequired,
  templateId: PropTypes.string,
  newItemNumbers: PropTypes.shape({
    baseContract: PropTypes.number.isRequired,
    subContract: PropTypes.number.isRequired,
    subContractCOs: PropTypes.number.isRequired,
    changeOrder: PropTypes.number.isRequired,
    nonHoldback: PropTypes.number.isRequired,
  }),
  subContractCOs: PropTypes.shape({}).isRequired,
  files: PropTypes.array.isRequired,
  onPreviewClick: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  addFile: PropTypes.func.isRequired,
  setFiles: PropTypes.func.isRequired,
  selectedFile: PropTypes.shape({}),
  clearSelectedFile: PropTypes.func.isRequired,
  updateFile: PropTypes.func.isRequired,
  onSetData: PropTypes.func.isRequired,
  isProgress: PropTypes.bool,
};

ProjectScheduleOfValuesSubContract.defaultProps = {
  visible: false,
  record: null,
  templateId: null,
  selectedFile: {},
  newItemNumbers: {},
  isProgress: false,
};

export default ProjectScheduleOfValuesSubContract;
