import axios from 'axios';

import { request, archive } from '../../helpers/requests';

import { parseAndUploadFiles, loadFromSigned } from '../../files/fileHelpers';
import { constructFormPayloadForAPI } from '../../forms/ResponderHelpers';

// Need to create a new axios instance,
// because our default attaches a Bearer Token to all requests
const axiosFileInstance = axios.create();

const uploadImages = async (id, image) => {
  if (!image) return false;
  const {
    data: { picture } = {},
  } = await axios.post('/equipment/picture', { id, type: image.type });

  await axiosFileInstance.put(picture, image, {
    headers: {
      'Content-Type': image.type,
    },
  });
  return true;
};

export const formatPayloadCustomData = async (customData) => {
  if (customData) {
    // If it is already an array the data is already parsed
    // Same as if it's in v2 format
    if ((Array.isArray(customData) && customData.length) || customData.version === 'v2') {
      return {
        customData,
        files: [],
      };
    }

    // Otherwise construct the payload and upload the new files
    if (!Array.isArray(customData)) {
      const {
        data: {
          sections,
        } = {},
        files,
      } = await constructFormPayloadForAPI({
        form: {
          collected: {}, responses: customData,
        },
      });

      return {
        customData: sections,
        files,
      };
    }
  }

  return {
    customData: [],
    files: [],
  };
};

const EquipmentService = {
  create: (payload) => request({
    call: async () => {
      const realPayload = { ...payload };
      const { files = [], image, customData } = payload;
      const {
        customData: formattedCustomData,
        files: formattedFiles,
      } = await formatPayloadCustomData(customData);
      realPayload.customData = formattedCustomData;
      const parsedFiles = await parseAndUploadFiles(files);
      realPayload.files = parsedFiles.concat(formattedFiles);
      delete realPayload.image;
      realPayload.hasImage = !!image;
      const res = await axios.post('/equipment', realPayload);
      if (!image || !res.data) return res;
      const {
        data = {},
      } = res;
      const newData = { ...data };
      newData.hasImage = await uploadImages(data.id, image);
      if (newData.hasImage) newData.image = image;
      return { data: newData };
    },
    errMsg: 'Could not create equipment',
  }),
  get: () => request({
    call: async () => axios.get('/equipment'),
    errMsg: 'Could not get equipment',
    hideSuccessToast: true,
  }),
  archive: (id, active) => archive({
    id, active, type: 'equipment', dontStrip: true,
  }),
  deleteEquipment: (id) => request({
    call: axios.delete(`/equipment/${id}`),
    errMsg: 'Could not delete equipment',
  }),
  update: (id, payload) => request({
    call: async () => {
      const realPayload = { ...payload };
      const { addedFiles = [], image, customData } = payload;
      const {
        customData: formattedCustomData,
        files: formattedFiles,
      } = await formatPayloadCustomData(customData);
      realPayload.customData = formattedCustomData;
      realPayload.hasImage = await uploadImages(id, image);
      delete realPayload.image;
      const parsedFiles = await parseAndUploadFiles(addedFiles);
      realPayload.addedFiles = parsedFiles.concat(formattedFiles);
      delete realPayload.files;
      return axios.put(`/equipment/${id}`, realPayload);
    },
    errMsg: 'Could not update equipment',
  }),
  getHistory: (id) => request({
    call: axios.get(`/equipment/${id}/history`),
    errMsg: 'Could not get equipment history',
    hideSuccessToast: true,
  }),
  checkIn: (id, payload) => request({
    call: axios.put(`/equipment/${id}/checkin`, payload),
    errMsg: 'Could not check in equipment',
  }),
  checkOut: (id, payload) => request({
    call: axios.put(`/equipment/${id}/checkout`, payload),
    errMsg: 'Could not check out equipment',
    hideSuccessToast: true,
  }),
  getFiles: (id) => request({
    call: axios.get(`/equipment/${id}/files`, { params: { getSignedUrls: false } }),
    errMsg: 'Could not get equipment files',
    hideSuccessToast: true,
  }),
  getNotes: (id) => request({
    call: axios.get(`/equipment/${id}/notes`),
    errMsg: 'Could not get equipment notes',
    hideSuccessToast: true,
  }),
  addNote: (id, note) => request({
    call: axios.post(`/equipment/${id}/note`, { note }),
    errMsg: 'Could not get equipment note',
  }),
  getStatuses: () => request({
    call: axios.get('/equipment/statuses'),
    errMsg: 'Could not get equipment statuses',
    hideSuccessToast: true,
  }),
  getEquipmentTypes: () => request({
    call: axios.get('/customTypes/equipment'),
    errMsg: 'Failed to get equipment types',
    hideSuccessToast: true,
  }),
  getEquipmentCustomData: (id) => request({
    call: axios.get(`/equipment/${id}/data`, { params: { getSignedUrls: false } }),
    errMsg: 'Failed to get equipment custom data',
    hideSuccessToast: true,
  }),
  getEquipmentCustomDataMap: (params) => request({
    call: axios.get('/equipment/data', { params }),
    errMsg: 'Failed to get equipment custom data',
    hideSuccessToast: true,
  }),
};

export default EquipmentService;
