import React from 'react';
import { Upload, Button, message } from 'antd';
import { InboxOutlined, CloseOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import colors from '../../constants/Colors';

import Analytics from '../../helpers/Analytics';
import { validFileTypes, validateFileHeader } from '../../files/fileHelpers';

const { Dragger } = Upload;

const defaultValidator = (file) => {
  const fileType = file.type?.toLowerCase();
  if (validFileTypes.has(fileType)) {
    return true;
  }
  const fileTypeMsg = fileType ? `'${fileType}' files` : 'this file type';
  message.error(`Ontraccr doesn't support ${fileTypeMsg}`);
  Analytics.track('Files/UploadUnsupportedFileType', { fileType, size: file.size });
  return false;
};
const custom = ({
  addFile,
  customValidator = defaultValidator,
  shouldAddMetadata = false,
}) => async (args) => {
  const { file } = args;
  setImmediate(() => args.onSuccess());
  const fileIsGood = await validateFileHeader(file);
  if (!fileIsGood) {
    message.error(`Ontraccr detected an issue with the file: ${file.name}`);
    return;
  }
  if (customValidator(file)) {
    const newFile = new File([file], file.name, { type: file.type });
    if (shouldAddMetadata) newFile.timestamp = DateTime.local().toMillis();
    addFile(newFile);
    message.success('File uploaded');
    Analytics.track('Files/UploadFromComputer', { fileType: newFile.type, size: newFile.size });
  }
};

export default function FileUpload({
  onUploadEnd,
  addFile,
  customValidator,
  style = {},
}) {
  const company = useSelector((state) => state.settings.company);
  return (
    <div className="live-feed-file-upload" style={style}>
      <Dragger
        name="fileupload"
        multiple
        customRequest={custom({
          addFile,
          customValidator,
          shouldAddMetadata: company?.settings?.autoAddMetadataToPhotos,
        })}
        showUploadList={false}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{ color: colors.ONTRACCR_RED }} />
        </p>
        <p className="ant-upload-text">Click or drag a file here to upload</p>
      </Dragger>
      {onUploadEnd && (
      <Button
        className="live-feed-file-upload-button"
        icon={
          <CloseOutlined />
        }
        onClick={(e) => {
          e.stopPropagation();
          onUploadEnd();
        }}
      />
      )}
    </div>
  );
}

FileUpload.propTypes = {
  onUploadEnd: PropTypes.func,
  addFile: PropTypes.func.isRequired,
  customValidator: PropTypes.func,
  style: PropTypes.shape({}),
};

FileUpload.defaultProps = {
  onUploadEnd: null,
  customValidator: defaultValidator,
  style: {},
};
