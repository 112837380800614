import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Table } from 'antd';
import { DeleteOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';

import BorderlessButton from '../../../common/buttons/BorderlessButton';

import TitleRow from './TitleRow';

import SimpleTextInputModal from '../../../common/modals/SimpleTextInputModal';
import SignatureModal from '../../SignatureModal';
import { getIdMap, uuid } from '../../../helpers/helpers';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';

import FieldTriggerFlag from './FieldTriggerFlag';
import DisplayText from '../../../common/text/DisplayText';
import { generateId } from '../../formHelpers';

export default ({
  configProps = {},
  previewProps = {},
  id,
  responses,
  setResponses,
  responding = false,
  sections,
  isExternalForm,
  projectId,
  templateId,
  divisions,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  name,
  isDisplay,
  showCondensedView,
}) => {
  const [selectedUser,setSelectedUser] = useState();
  const [oldUsers, setOldUsers] = useState(new Set());
  const [showTextModal, setShowTextModal] = useState(false);

  const {
    optional,
    title = 'Title goes here',
    linkedField,
    fieldTrigger,
  } = configProps;
  const {
    values: displayValues,
  } = previewProps;
  const {
    [id]:{
      values: responseValues,
    } = {},
    [linkedField]: {
      values: linkedValues,
    } = {},
  } = responses ?? {};
  const realValues = responding ? responseValues : displayValues;

  const hideSigModal = useCallback(() => setSelectedUser(),[]);
  const openSigModal = useCallback((record) => () => {
    setSelectedUser(record);
  },[]);

  const openTextModal = useCallback(() => setShowTextModal(true),[]);
  const closeTextModal = useCallback(() => setShowTextModal(false),[]);

  const onAddCustom = useCallback((newSigner) => {
    if (responding && newSigner) {
      setResponses((oldResponses = {}) => ({
        ...oldResponses,
        [id]: {
          values: (realValues ?? []).concat([{
            id: generateId(),
            name: newSigner,
            isCustom: true,
          }])
        }
      }));
    }
    setShowTextModal(false)
  },[id, realValues, responding]);

  const onDelete = useCallback((recordId) => () => {
    const {
      [id]:{
        values: oldResponses = [],
      } = {},
    } = responses;
    const newResponses = oldResponses.filter((res) => res.id !== recordId);
    setResponses({
      ...responses,
      [id]: { values: newResponses },
    });
  },[id, responses]);

  const onClear = useCallback((recordId) => () => {
    const {
      [id]:{
        values: oldResponses = [],
      } = {},
    } = responses;
    const newResponses = oldResponses.map((res) => {
      if (res.id !== recordId) return res;
      const newRes = { ...res };
      delete newRes.sig;
      return newRes;
    });
    setResponses({
      ...responses,
      [id]: { values: newResponses },
    });
  },[id, responses]);

  const onSubmit = useCallback((signatureFile) => {
    if (!selectedUser) return;
    const {
      [id]:{
        values: oldResponses = [],
      } = {},
    } = responses;
    const newResponses = oldResponses.map((res) => {
      if (res.id !== selectedUser.id) return res;
      return {
        ...res,
        sig: signatureFile,
        autoSaveId: uuid(),
      };
    });
    setResponses({
      ...responses,
      [id]: { values: newResponses },
    });
    setSelectedUser();
  },[id, responses, selectedUser]);

  const columns = useMemo(() => {
    const cols = [{
      title: 'User',
      dataIndex: 'name'
    }, {
      title: 'Signature',
      dataIndex: 'sig',
      render:(sig, record) =>  {
        if (!sig) {
          return responding ? (
            <BorderlessButton
              title='Click to sign'
              iconNode={<FormOutlined />}
              style={{ textAlign: 'left', height: 50 }}
              onClick={openSigModal(record)}
            />
          ) : null;
        }
        return (
          <img
            className="form-multi-sig-image"
            src={sig instanceof File ? URL.createObjectURL(sig) : sig.url}
            alt={`${record.name}'s signature`}
            onClick={openSigModal(record)}
          />
        );
      },
    }];
    if (responding) {
      cols.push({
        title: '',
        dataIndex: 'clear',
        width: 100,
        render:(_, record) => (
          <BorderlessButton
            title='Clear'
            onClick={onClear(record.id)}
          />
        ),
      });
      cols.push({
        title: '',
        dataIndex: 'delete',
        width: 100,
        render:(_, record) => (
          record.isCustom
          ? <BorderlessButton
            iconNode={<DeleteOutlined style={{ color: 'red'}}/>}
            onClick={onDelete(record.id)}
          />
          : null
        ),
      });
    }
    return cols;
  },[openSigModal, onClear, onDelete, responding]);

  useEffect(() => {
    /*
      If users in linked field are updated we need to update response
    */
    const safeResponseValues = responseValues ?? [];
    const responseMap = getIdMap(safeResponseValues);
    const safeValues = linkedValues ?? [];
    const newUsers = new Set(safeValues.map((user) => user.id));
    const addedUsers = safeValues.filter((user) => !oldUsers.has(user.id) && !(user.id in responseMap));
    const deletedUsers  = new Set(Array.from(oldUsers).filter((userId) => !newUsers.has(userId)));
    if (addedUsers.length === 0 && deletedUsers.size === 0) return;

    const fullValues = safeResponseValues
      .filter((val) => val.isCustom || !deletedUsers.has(val.id))
      .concat(
        addedUsers.map((user) => {
          const { id: userId } = user;
          const { [userId]: userResponse = {} } = responseMap;
          return {
            ...user,
            ...userResponse,
          };
        })
      );

    setOldUsers(newUsers);
    setResponses({
      ...responses,
      [id]: {
        values: fullValues,
      },
    });
  },[id, linkedValues, responses, responseValues, oldUsers])

  return (
    <div style={{ minWidth: 800, width: '100%' }}>
      <TitleRow
        title={title}
        optional={optional}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                isExternalForm={isExternalForm}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                id={id}
                fieldTriggerMap={fieldTriggerMap}
                setFieldTriggerMap={setFieldTriggerMap}
                name={name}
              />
            ) : null
        }
      />
      { !isDisplay && (
        <OnTraccrButton
          title='Add Signer'
          onClick={openTextModal}
          style={{ marginTop: 15, marginBottom: 10 }}
          icon={<PlusOutlined/>}
        />
      )}
      { !showCondensedView || realValues?.length ? (
        <Row style={{ marginTop: showCondensedView ? 0 : 15, width: '100%' }}>
          <Table
            style={{ width: '100%' }}
            dataSource={realValues ?? []}
            columns={columns}
            size='small'
            pagination={false}
          />
        </Row>
      ) : (
        <DisplayText title="No Signatures" style={{ marginBottom: 0 }} />
      )}
      <SignatureModal
        visible={selectedUser}
        onClose={hideSigModal}
        onSubmit={onSubmit}
        initialData={selectedUser ? selectedUser.sig : null}
        isMultiSignature
      />
      <SimpleTextInputModal
        visible={showTextModal}
        onClose={closeTextModal}
        onSave={onAddCustom}
        title='Add Signer'
      />
    </div>
  );
}